import {
    defaultDirection,
    defaultLocale,
    defaultColor,
    localeOptions,
    themeColorStorageKey,
    themeRadiusStorageKey,
} from '../constants/defaultValues';
import parse from 'date-fns/parseISO';
import format from 'date-fns/format';
import {NotificationManager} from '../components/common/react-notifications';

export const mapOrder = (array: any, order: any, key: any) => {
    array.sort(function (a: any, b: any) {
        const A = a[key];
        const B = b[key];
        if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
            return 1;
        }
        return -1;
    });
    return array;
};

export const getDateWithFormat = () => {
    const today = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1; // January is 0!

    const yyyy = today.getFullYear();
    if (dd < 10) {
        dd = `0${dd}`;
    }
    if (mm < 10) {
        mm = `0${mm}`;
    }
    return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
    const now = new Date();
    return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
    let direction = defaultDirection;

    try {
        if (localStorage.getItem('direction')) {
            const localValue = localStorage.getItem('direction');
            if (localValue === 'rtl' || localValue === 'ltr') {
                direction = localValue;
            }
        }
    } catch (error) {
        direction = defaultDirection;
    }
    return {
        direction,
        isRtl: direction === 'rtl',
    };
};
export const setDirection = localValue => {
    let direction = 'ltr';
    if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
    }
    try {
        localStorage.setItem('direction', direction);
    } catch (error) {}
};

export const getCurrentColor = () => {
    let currentColor: any = defaultColor;
    try {
        if (localStorage.getItem(themeColorStorageKey)) {
            currentColor = localStorage.getItem(themeColorStorageKey);
        }
    } catch (error) {
        currentColor = defaultColor;
    }
    return currentColor;
};

export const setCurrentColor = color => {
    try {
        localStorage.setItem(themeColorStorageKey, color);
    } catch (error) {}
};

export const getCurrentRadius = () => {
    let currentRadius: any = 'rounded';
    try {
        if (localStorage.getItem(themeRadiusStorageKey)) {
            currentRadius = localStorage.getItem(
                themeRadiusStorageKey,
            );
        }
    } catch (error) {
        currentRadius = 'rounded';
    }
    return currentRadius;
};
export const setCurrentRadius = radius => {
    try {
        localStorage.setItem(themeRadiusStorageKey, radius);
    } catch (error) {}
};

export const getCurrentLanguage = () => {
    let language: any = defaultLocale;
    try {
        language =
            localStorage.getItem('currentLanguage') &&
            localeOptions.filter(
                x => x.id === localStorage.getItem('currentLanguage'),
            ).length > 0
                ? localStorage.getItem('currentLanguage')
                : defaultLocale;
    } catch (error) {
        language = defaultLocale;
    }
    return language;
};
export const setCurrentLanguage = (locale: any) => {
    try {
        localStorage.setItem('currentLanguage', locale);
    } catch (error) {}
};

// export const getCurrentUser = () => {
//   let user: any = null;
//   try {
//     user =
//       localStorage.getItem('aristiun_current_user') != null
//         ? JSON.parse(localStorage.getItem('aristiun_current_user') as any)
//         : null;
//   } catch (error) {
//     user = null;
//   }
//   return user;
// };

// export const setCurrentUser = (user) => {
//   try {
//     if (user) {
//       localStorage.setItem('aristiun_current_user', JSON.stringify(user));
//     } else {
//       localStorage.removeItem('aristiun_current_user');
//     }
//   } catch (error) {
//   }
// };

export const getHeaders = (token: string) => ({
    headers: {
        Authorization: `Token ${token}`,
    },
});

export const getFormatDateWithMonthAndYear = myDate => {
    let iso = parse(myDate);
    var result = format(iso, 'MMMM RRRR').toString();
    return result;
};

export const getFormattedDate = (date, formatStr) => {
    const iso = parse(date);
    return format(date, formatStr).toString();
}

export const getAttachedFileName = file => {
    if (file) {
        let name = file.includes('media/');
        if (name) {
            let index = file.indexOf('media/');
            let finalFile = file.substr(index + 6);
            return finalFile;
        }
    }
};

export const processErrorMessages = (error, actionString) => {
    if (error && Object.keys(error).length > 0) {
        Object.keys(error).forEach(key => {
            error[key].forEach(error =>
                {
                      if(key === 'non_field_error') {
                          NotificationManager.warning(
                            error,
                            `Error occurred while ${actionString}`,
                            3000,
                            null,
                            null,
                            ''
                          );
                      } else {
                          NotificationManager.warning(
                            `Incorrect field '${key}': ${error}`,
                            `Error occurred while ${actionString}`,
                            3000,
                            null,
                            null,
                            '',
                          )
                      }
                }
            );
        });
    }
}
