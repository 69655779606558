import { UserRole } from '../helpers/authHelper';
import GreenDot from '../assets/icons/greenDot.svg';
import RedDot from '../assets/icons/redDot.svg';
import DarkBlue from '../assets/icons/darkBlue.svg';
import LightBlue from '../assets/icons/lightBlue.svg';
import BlueCircle from '../assets/icons/blueCircle.svg';
import RedCircle from '../assets/icons/redCircle.svg';
import GreenBlue from '../assets/icons/greenCircle.svg';
import YellowBlue from '../assets/icons/yellowCircle.svg';
import Yellow from '../assets/small_icons/yellow.svg';
import Orange from '../assets/small_icons/orange.svg';
import LightPurple from '../assets/small_icons/lightPurple.svg';
import LightOrange from '../assets/small_icons/lightOrange.svg';

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-sub-hidden';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
    { id: 'en', name: 'English - LTR', direction: 'ltr' },
    { id: 'es', name: 'Español', direction: 'ltr' },
    { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const adminRoot = '/app';
export const errorRoot = '/error';
export const managerRoot = '/app/assessments';
export const searchPath = `${adminRoot}/#`;

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.blueolympic';
export const isDarkSwitchActive = false;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
    'bluenavy',
    'blueyale',
    'blueolympic',
    'greenmoss',
    'greenlime',
    'purplemonster',
    'orangecarrot',
    'redruby',
    'yellowgranola',
    'greysteel',
];

export const thirdPartyRiskManagement = 'Third Party Risk/Issue/Deficiency Management (TPRM)';

export const assessmentTypeOptions = [
    {
        value: 'Consulting Assessment',
        label: 'Consulting Assessment',
    },
    { value: 'Internal Assessment', label: 'Internal Assessment' },
    {
        value: 'Security Maturity Assessment',
        label: 'Security Maturity Assessment',
    },
    { value: 'Security Compliance Assessment', label: 'Security Compliance Assessment' },
    { value: thirdPartyRiskManagement, label: thirdPartyRiskManagement },
];

export const feedbackTypeOptions = [
    { value: 'passing', label: 'Passing Feedback' },
    { value: 'maturity', label: 'Maturity Feedback' },
];

export const assetTypeOptions = [
    { value: 'website', label: 'Website' },
    { value: 'server', label: 'Server' },
    { value: 'network_device', label: 'Network Device' },
    { value: 'third_party', label: 'Third Party' },
    { value: 'team', label: 'Team' },
    { value: 'business_unit', label: 'Business' },
    { value: 'department', label: 'Department' },
];

export const assetTypeDevices = [
    { value: 'Firewall', label: 'Firewall' },
    { value: 'Switch', label: 'Switch' },
    { value: 'Router', label: 'Router' },
    { value: 'IDS/IPS', label: 'IDS/IPS' },
];

export const approvalStatuses = [
    { value: 'approved', label: 'Approved' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'approved_with_comment', label: 'Approved with comments' },
];

export const tierRatings = [
    { value: 'low', label: 'Low' },
    { value: 'medium', label: 'Medium' },
    { value: 'high', label: 'High' },
];

export const osTypes = [
    { value: 'Windows', label: 'Windows' },
    { value: 'Linux', label: 'Linux' },
    { value: 'MacOS', label: 'MacOS' },
    { value: 'Windows 10', label: 'Windows 10' },
    { value: 'Ubuntu 20.04', label: 'Ubuntu 20.04' },
];

export const protocols = [
    { value: 'HTTPS', label: 'HTTPS' },
    { value: 'HTTP', label: 'HTTP' },
];

export const environments = [
    { value: 'Production', label: 'Production' },
    { value: 'Testing', label: 'Testing' },
    { value: 'Development', label: 'Development' },
    { value: 'Acceptance', label: 'Acceptance' },
];

export const asset_state = [
    { value: 'Planned', label: 'Planned' },
    { value: 'In Use', label: 'In Use' },
    {
        value: 'Marked for Decommissioning',
        label: 'Marked for Decommissioning',
    },
];

export const templateStateOptions = [
    { value: 'draft', label: 'Draft' },
    { value: 'under_review', label: 'Under Review' },
    { value: 'final', label: 'Final' },
];

export const applicablePeriodList = [
    { value: 'days', label: 'Days' },
    { value: 'weeks', label: 'Weeks' },
    { value: 'months', label: 'Months' },
    { value: 'years', label: 'Years' },
];

export const controlObjectiveControlList = [
    { value: 1, label: 'Control Objective Dummy' },
];

export const keyControlsList = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
];

export const controlStateColors = [
    { value: 'attested', label: 'Attested' },
    { value: 'compliant', label: 'Compliant' },
    { value: 'non_compliant', label: 'Non Compliant' },
];

export const controlsReference = [
    { value: 'soc', label: 'SOC' },
    { value: 'nist', label: 'NIST' },
    { value: 'it_general_controls', label: 'IT General Controls' },
    {
        value: 'something_else_i_choose',
        label: 'Something Else I Choose',
    },
    { value: 'sox', label: 'SOX' },
];

export const riskFlagOptions = [
    { value: 'low', label: 'Low' },
    { value: 'medium', label: 'Medium' },
    { value: 'high', label: 'High' },
    { value: 'critical', label: 'Critical' },
];

export const riskLikeliHoodOptions = [
    { value: 'low', label: 'Low' },
    { value: 'medium', label: 'Medium' },
    { value: 'high', label: 'High' },
];

export const evidenceStateOptions = [
    { value: 'approved', label: 'Approved' },
    { value: 'rejected', label: 'Rejected' },
];

export const threatLevelOptions = [
    {
        label: 'Low',
        value: 'low',
        icon: LightPurple,
    },
    { value: 'medium', label: 'Medium', icon: Yellow },
    { value: 'high', label: 'High', icon: LightOrange },
    { value: 'critical', label: 'Critical', icon: Orange },
];

export const threatTypeOptions = [
    { value: 'library_threat', label: 'Library Threat' },
    { value: 'registry_threat', label: 'Register Threat' },
];

export const riskTypes = {
    library_risk: "Library Risk",
    registry_risk: "Registry Risk",
    issue: "Issue",
    deficiency: "Deficiency",
}

export const riskTypesOptions = Object.entries(riskTypes).map(([key, value]) => ({
    value: key,
    label: value,
    name: 'risk_type',
}));

export const threatStateOptions = [
    { value: 'no_action', label: 'No Action' },
    { value: 'attach', label: 'Attach' },
    { value: 'detach', label: 'De-Attach' },
];

export const controlFilterOptions = [
    { value: 'all_controls', label: 'All Controls' },
    { value: 'compliant_controls', label: 'Compliant Controls' },
    {
        value: 'non_compliant_controls',
        label: 'Non Compliant Controls',
    },
    {
        value: 'partially_compliant',
        label: 'Partially Compliant Controls',
    },
    // { value: 'attested_controls', label: 'Attested Controls' },
    // { value: 'all_key_controls', label: 'All Key Controls' },
    // {
    //     value: 'compliant_key_controls',
    //     label: 'Compliant Key Controls',
    // },
    // {
    //     value: 'non_compliant_key_controls',
    //     label: 'Non Compliant Key Controls',
    // },
    // {
    //     value: 'attested_key_controls',
    //     label: 'Attested Key Controls',
    // },
];

export const controlDaysOptions = [
    { value: 7, label: 7 },
    { value: 30, label: 30 },
    { value: 180, label: 180 },
];

export const controlKeyOptions = [
    // { value: '', label: 'All' },
    { value: 'key', label: 'Key' },
    { value: 'non_key', label: 'Non key' },
];

export const controlKeyOptionsWithIcons = [
    {
        label: 'Key',
        value: 'key',
        icon: DarkBlue,
    },
    {
        label: 'Non Key',
        value: 'non_key',
        icon: LightBlue,
    },
];

export const applicablePeriodNo = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
];

export const controlStatusOptionsWithIcons = [
    {
        label: 'Under Review',
        value: 'under_review',
        icon: BlueCircle,
    },
    {
        label: 'Non Compliant',
        value: 'non_compliant',
        icon: RedCircle,
    },
    {
        label: 'Compliant',
        value: 'compliant',
        icon: GreenBlue,
    },
    {
        label: 'Partially Compliant',
        value: 'partially_compliant',
        icon: YellowBlue,
    },
];

export const riskTypeOptionsWithIcons = [
    {
        key: 'Low',
        value: 'low',
    },
    {
        key: 'Medium',
        value: 'medium',
    },
    {
        key: 'High',
        value: 'high',
    },
    {
        key: 'Critical',
        value: 'critical',
    },
    {
        key: 'Cancel',
        value: '',
    },
];

export const liklihoodOptionsWithIcons = [
    {
        key: 'Low',
        value: 'low',
    },
    {
        key: 'Medium',
        value: 'medium',
    },
    {
        key: 'High',
        value: 'high',
    },
    {
        key: 'Cancel',
        value: '',
    },
];

export const activityStatuses = {
    defined: "Defined",
    in_progress: "In Progress",
    under_review: "Under Review",
    reviewed: "Reviewed",
    closed: "Closed",
}
