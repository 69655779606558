import {
    LOAD_ACTIVITIES,
    LOAD_ACTIVITIES_ERROR,
    LOAD_ACTIVITIES_SUCCESS,
    ADD_ACTIVITY,
    ADD_ACTIVITY_ERROR,
    ADD_ACTIVITY_SUCCESS,
    LOAD_ACTIVITY,
    LOAD_ACTIVITY_ERROR,
    LOAD_ACTIVITY_SUCCESS,
    UPDATE_ACTIVITY,
    UPDATE_ACTIVITY_ERROR,
    UPDATE_ACTIVITY_SUCCESS,
    DELETE_ACTIVITY,
    DELETE_ACTIVITY_ERROR,
    DELETE_ACTIVITY_SUCCESS,
    RESET_ACTIVITY_MESSAGES,
    GET_RESOURCE_LIST_FOR_RISK_AND_THREATS,
    GET_RESOURCE_LIST_FOR_RISK_AND_THREATS_ERROR,
    GET_RESOURCE_LIST_FOR_RISK_AND_THREATS_SUCCESS,
    SELECTED_ACTIVITIES_CHANGE,
    NOTIFY_ACTIVITY,
    NOTIFY_ACTIVITY_ERROR,
    NOTIFY_ACTIVITY_SUCCESS,
    FILTER_ACTIVITIES,
    FILTER_ACTIVITIES_SUCCESS,
    FILTER_ACTIVITIES_ERROR,
    RESPOND_ACTIVITY,
    RESPOND_ACTIVITY_SUCCESS,
    RESPOND_ACTIVITY_ERROR,
    REVIEW_ACTIVITY,
    REVIEW_ACTIVITY_SUCCESS,
    REVIEW_ACTIVITY_ERROR,
    LOAD_ACTIVITIES_FILTERS,
    LOAD_ACTIVITIES_FILTERS_ERROR,
    LOAD_ACTIVITIES_FILTERS_SUCCESS,
} from '../types';

const INIT_STATE = {
    error: '',
    activityData: {},
    activitiesList: [],
    adding: false,
    success: false,
    loading: false,
    addError: '',
    deleteActivityError: '',
    deletedActivitySuccess: '',
    updateActivityError: '',
    updateActivitySuccess: false,
    resourcesActivityList: [],
    selected: [],
    notifyError: '',
    notifySuccess: '',
    respondSuccess: '',
    respondError: '',
    reviewSuccess: '',
    reviewError: '',
    activitiesFilters: [],
    activitiesFiltersError: false,
    activitiesFiltersSuccess: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                activitiesList: [],
                error: '',
                loading: true,
                selected: [],
            };
        case LOAD_ACTIVITIES_ERROR:
            return {
                ...state,
                activitiesList: [],
                loading: false,
                error: action.payload.message,
            };
        case LOAD_ACTIVITIES_SUCCESS:
            return {
                ...state,
                activitiesList: action.payload,
                loading: false,
                error: ''
            };
        case ADD_ACTIVITY:
            return {
                ...state,
                adding: true,
                addError: {},
                success: false,
            };
        case ADD_ACTIVITY_ERROR:
            return {
                ...state,
                adding: false,
                success: false,
                addError: action.payload.message,
            };
        case ADD_ACTIVITY_SUCCESS:
            return {
                ...state,
                adding: false,
                success: true,
                addError: '',
                activitiesList: action.payload,
            };
        case LOAD_ACTIVITY:
            return {
                ...state,
                error: '',
                loading: true,
                activityData: {},
            };
        case LOAD_ACTIVITY_ERROR:
            return {
                ...state,
                loading: false,
                activityData: {},
                error: action.payload,
            };
        case LOAD_ACTIVITY_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                activityData: action.payload,
            };
        case DELETE_ACTIVITY:
            return {
                ...state,
                loading: true,
                deleteActivityError: '',
                deletedActivitySuccess: '',
            };
        case DELETE_ACTIVITY_ERROR:
            return {
                ...state,
                loading: false,
                deletedActivitySuccess: '',
                deleteActivityError: action.payload,
            };
        case DELETE_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteActivityError: '',
                deletedActivitySuccess: action.payload,
            };
        case UPDATE_ACTIVITY:
            return {
                ...state,
                loading: true,
                updateActivityError: '',
                updateActivitySuccess: false,
            };
        case UPDATE_ACTIVITY_ERROR:
            return {
                ...state,
                loading: false,
                updateActivitySuccess: false,
                updateActivityError: action.payload.message,
            };
        case UPDATE_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
                updateActivityError: '',
                updateActivitySuccess: true,
                activitiesList: action.payload,
            };
        case RESET_ACTIVITY_MESSAGES:
            return {
                ...state,
                addError: '',
                adding: false,
                success: false,
                loading: false,
                updateActivityError: '',
                deleteActivityError: '',
                updateActivitySuccess: false,
                deletedActivitySuccess: '',
                respondError: '',
                respondSuccess: '',
                reviewError: '',
                reviewSuccess: '',
            };
        case GET_RESOURCE_LIST_FOR_RISK_AND_THREATS:
            return {
                ...state,
                error: '',
                loading: true,
                resourcesActivityList: [],
            };
        case GET_RESOURCE_LIST_FOR_RISK_AND_THREATS_ERROR:
            return {
                ...state,
                loading: false,
                resourcesActivityList: [],
                error: action.payload.message,
            };
        case GET_RESOURCE_LIST_FOR_RISK_AND_THREATS_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                resourcesActivityList: action.payload,
            };
        case SELECTED_ACTIVITIES_CHANGE:
            return {
                ...state,
                selected: action.payload,
            };
        case NOTIFY_ACTIVITY:
            return {
                ...state,
                notifyError: '',
                notifySuccess: '',
                loading: true,
            };
        case NOTIFY_ACTIVITY_ERROR:
            return {
                ...state,
                loading: false,
                notifySuccess: '',
                notifyError: action.payload,
            };
        case NOTIFY_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
                notifyError: '',
                notifySuccess: action.payload,
            };
        case FILTER_ACTIVITIES:
            return {
                ...state,
                error: '',
                loading: true,
                activitiesList: [],
            };
        case FILTER_ACTIVITIES_ERROR:
            return {
                ...state,
                activitiesList: [],
                loading: false,
                error: action.payload,
            };
        case FILTER_ACTIVITIES_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                activitiesList: action.payload,
            };
        case RESPOND_ACTIVITY:
            return {
                ...state,
                respondError: '',
                respondSuccess: '',
                loading: true,
            };
        case RESPOND_ACTIVITY_ERROR:
            return {
                ...state,
                loading: false,
                respondError: action.payload,
                respondSuccess: '',
            };
        case RESPOND_ACTIVITY_SUCCESS:
            return {
                ...state,
                error: '',
                respondError: false,
                respondSuccess: action.payload,
            };
        case REVIEW_ACTIVITY:
            return {
                ...state,
                reviewError: '',
                reviewSuccess: '',
                loading: true,
            };
        case REVIEW_ACTIVITY_ERROR:
            return {
                ...state,
                loading: false,
                reviewError: action.payload,
                reviewSuccess: '',
            };
        case REVIEW_ACTIVITY_SUCCESS:
            return {
                ...state,
                error: '',
                reviewError: false,
                reviewSuccess: action.payload,
            };
        case LOAD_ACTIVITIES_FILTERS:
            return {
                ...state,
                activitiesFiltersError: false,
                activitiesFiltersSuccess: false,
                activitiesFilters: [],
            };
        case LOAD_ACTIVITIES_FILTERS_ERROR:
            return {
                ...state,
                activitiesFiltersError: action.payload,
                activitiesFiltersSuccess: false,
                activitiesFilters: [],
            };
        case LOAD_ACTIVITIES_FILTERS_SUCCESS:
            return {
                ...state,
                activitiesFiltersError: false,
                activitiesFiltersSuccess: true,
                activitiesFilters: action.payload,
            };
        default:
            return state;
    }
};
