import {
    VALIDATE_ASSESSMENT_LINK,
    VALIDATE_ASSESSMENT_LINK_SUCCESS,
    VALIDATE_ASSESSMENT_LINK_ERROR,
    VALIDATE_ASSESSMENT_REPORT_LINK,
    VALIDATE_ASSESSMENT_REPORT_LINK_ERROR,
    VALIDATE_ASSESSMENT_REPORT_LINK_SUCCESS,
    SUBMIT_ASSESSMENT,
    SUBMIT_ASSESSMENT_SUCCESS,
    SUBMIT_ASSESSMENT_ERROR,
    GENERAL_REPORT,
    GENERAL_REPORT_SUCCESS,
    GENERAL_REPORT_ERROR,
    EXECUTIVE_REPORT,
    EXECUTIVE_REPORT_SUCCESS,
    EXECUTIVE_REPORT_ERROR,
    GET_ATTACHMENTS,
    GET_ATTACHMENTS_SUCCESS,
    GET_ATTACHMENTS_ERROR,
    SUBMIT_REVIEWER_ASSESSMENT,
    SUBMIT_REVIEWER_ASSESSMENT_SUCCESS,
    SUBMIT_REVIEWER_ASSESSMENT_ERROR,
    VALIDATE_REVIEWER_ASSESSMENT_LINK,
    VALIDATE_REVIEWER_ASSESSMENT_LINK_SUCCESS,
    VALIDATE_REVIEWER_ASSESSMENT_LINK_ERROR,
    GET_CSV_RESPONSE,
    GET_CSV_RESPONSE_SUCCESS,
    GET_CSV_RESPONSE_ERROR,
    LOAD_STANDARD,
    LOAD_STANDARD_SUCCESS,
    LOAD_STANDARD_ERROR,
    ADD_REVIEWER,
    ADD_REVIEWER_ERROR,
    ADD_REVIEWER_SUCCESS,
    ADD_PUBLIC_INVITE_LINK,
    ADD_PUBLIC_INVITE_LINK_ERROR,
    ADD_PUBLIC_INVITE_LINK_SUCCESS,
    SUBMIT_PUBLIC_USER,
    SUBMIT_PUBLIC_USER_ERROR,
    SUBMIT_PUBLIC_USER_SUCCESS,
    VALIDATE_PUBLIC_ASSESSMENT_LINK,
    VALIDATE_PUBLIC_ASSESSMENT_LINK_ERROR,
    VALIDATE_PUBLIC_ASSESSMENT_LINK_SUCCESS,
    RESET_PUBLIC_ASSESSMENT_MESSAGES,
    LOAD_SHAREABLE_ASSESSMENT,
    LOAD_SHAREABLE_ASSESSMENT_ERROR,
    LOAD_SHAREABLE_ASSESSMENT_SUCCESS,
    REASSIGN_USER,
    REASSIGN_USER_ERROR,
    REASSIGN_USER_SUCCESS,
    LOAD_RESOURCE_DETAILS,
    LOAD_RESOURCE_DETAILS_ERROR,
    LOAD_RESOURCE_DETAILS_SUCCESS,
    COMPARE_ASSESSMENTS_LIST,
    COMPARE_ASSESSMENTS_LIST_ERROR,
    COMPARE_ASSESSMENTS_LIST_SUCCESS,
    COMPARE_ASSESSMENTS,
    COMPARE_ASSESSMENTS_ERROR,
    COMPARE_ASSESSMENTS_SUCCESS,
    VALIDATE_COMPARISON_ASSESSMENT_LINK,
    VALIDATE_COMPARISON_ASSESSMENT_LINK_ERROR,
    VALIDATE_COMPARISON_ASSESSMENT_LINK_SUCCESS,
    COMPARISON_GENERAL_REPORT,
    COMPARISON_GENERAL_REPORT_ERROR,
    COMPARISON_GENERAL_REPORT_SUCCESS,
    VALIDATE_MULTIPLE_COMPARISON,
    VALIDATE_MULTIPLE_COMPARISON_ERROR,
    VALIDATE_MULTIPLE_COMPARISON_SUCCESS,
    DOWNLOAD_GENERAL_REPORT_FOR_MULTIPLE_RESOURCES,
    DOWNLOAD_GENERAL_REPORT_FOR_MULTIPLE_RESOURCES_ERROR,
    DOWNLOAD_GENERAL_REPORT_FOR_MULTIPLE_RESOURCES_SUCCESS,
    VALIDATE_TIMELINE_REPORT_COMPARISON,
    VALIDATE_TIMELINE_REPORT_COMPARISON_ERROR,
    VALIDATE_TIMELINE_REPORT_COMPARISON_SUCCESS,
    DOWNLOAD_EXECUTIVE_REPORT_FOR_MULTIPLE_RESOURCES,
    DOWNLOAD_EXECUTIVE_REPORT_FOR_MULTIPLE_RESOURCES_ERROR,
    DOWNLOAD_EXECUTIVE_REPORT_FOR_MULTIPLE_RESOURCES_SUCCESS,
    GET_MAIN_WEB_REPORT,
    GET_MAIN_WEB_REPORT_ERROR,
    GET_MAIN_WEB_REPORT_SUCCESS,
    SINGLE_PAGE_GENERAL_REPORT,
    SINGLE_PAGE_GENERAL_REPORT_ERROR,
    SINGLE_PAGE_GENERAL_REPORT_SUCCESS,
    SWITCH_REPORTS,
    SWITCH_REPORTS_ERROR,
    SWITCH_REPORTS_SUCCESS,
    UPDATE_REPORT_CONTENT_FROM_WEB,
    UPDATE_REPORT_CONTENT_FROM_WEB_ERROR,
    UPDATE_REPORT_CONTENT_FROM_WEB_SUCCESS,
} from '../types';

const INIT_STATE = {
    loading: true,
    loadingForm: false,
    error: '',
    success: false,
    assessment: {},
    report: {},
    downloadExecutive: false,
    downloadGeneral: false,
    downloadAllAttachments: false,
    errorStatusCode: '',
    downloadCSVResponse: false,
    standardData: {},
    reviewer: {},
    addingReviewerSuccess: false,
    addingReviewerError: '',
    publicInvitationLink: '',
    publicInvitationError: '',
    publicInvitationSuccess: false,
    publicUserAssessment: '',
    publicUserAssessmentError: '',
    publicUserAssessmentSuccess: false,
    publicAssessmentStatus: '',
    publicAssessmentErrorStatus: '',
    assessmentData: {},
    reassignUserError: '',
    reassignUserSuccess: false,
    reassignUserData: {},
    resourceData: {},
    compareList: [],
    comparisonSuccess: {},
    newAssessmentss: [],
    downloadComparisonGeneral: false,
    mulAssessments: [],
    downloadMulComparisonGeneral: false,
    timeLineReport: [],
    downloadMulComparisonExecutive: false,
    webReport: {},
    reportErrorCode: null,
    reportSuccessCode: null,
    addingReviewer: false,
    assessmentCompleted: false,
    reviewerID: null,
    switchReportError: '',
    switchReportSuccess: '',
    reportContentDataError: '',
    reportContentDataSuccess: '',
    compareError: '',
};

export default (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case VALIDATE_ASSESSMENT_LINK:
            return {
                ...state,
                assessment: {},
                loading: true,
            };
        case VALIDATE_ASSESSMENT_LINK_ERROR:
            return {
                ...state,
                loading: false,
                errorStatusCode: action.payload?.code,
            };
        case VALIDATE_ASSESSMENT_LINK_SUCCESS:
            return {
                ...state,
                loading: false,
                assessment: action.payload,
            };
        case VALIDATE_ASSESSMENT_REPORT_LINK:
            return {
                ...state,
                report: {},
                loading: true,
            };
        case VALIDATE_ASSESSMENT_REPORT_LINK_ERROR:
            return {
                ...state,
                loading: false,
                errorStatusCode: action.payload?.code,
                reportErrorCode: action.payload?.code,
            };
        case VALIDATE_ASSESSMENT_REPORT_LINK_SUCCESS:
            return {
                ...state,
                loading: false,
                report: action.payload.data,
                reportSuccessCode: action.payload.status,
            };
        case SUBMIT_ASSESSMENT:
            return {
                ...state,
                loadingForm: true,
                error: '',
                success: false,
            };
        case SUBMIT_ASSESSMENT_SUCCESS: {
            const basePart = {
                progress: action.payload.status?.progress,
                completedSections: action.payload.status?.completed_sections,
                answers: action.payload.status?.answers,
                questions: action.payload.status?.questions,
                sectionResponses: action.payload.status?.sections,
            }

            if (action.payload.partial) {
                return {
                    ...state,
                    ...basePart,
                    loadingForm: false,
                };
            } else {
                return {
                    ...state,
                    ...basePart,
                    loadingForm: false,
                    success: true,
                    assessmentCompleted: action.payload.status.completed,
                };
            }
        }
        case SUBMIT_REVIEWER_ASSESSMENT_SUCCESS: {
            const basePart = {
                progress: action.payload.status?.progress,
                completedSections: action.payload.status?.completed_sections,
                answers: action.payload.status?.answers,
                questions: action.payload.status?.questions,
                sectionResponses: action.payload.status?.sections,
            }

            if (action.payload.partial) {
                return {
                    ...state,
                    ...basePart,
                    loadingForm: false,
                };
            } else {
                return {
                    ...state,
                    ...basePart,
                    loadingForm: false,
                    success: true,
                    assessmentCompleted: action.payload.reviewCompleted,
                };
            }
        }

        case SUBMIT_ASSESSMENT_ERROR:
            return {
                ...state,
                loadingForm: false,
                error: action.payload,
            };
        case GENERAL_REPORT:
            return {
                ...state,
                downloadGeneral: true,
            };
        case GENERAL_REPORT_SUCCESS:
            return {
                ...state,
                downloadGeneral: false,
            };
        case GENERAL_REPORT_ERROR:
            return {
                ...state,
                downloadGeneral: false,
            };
        case EXECUTIVE_REPORT:
            return {
                ...state,
                downloadExecutive: true,
            };
        case EXECUTIVE_REPORT_SUCCESS:
            return {
                ...state,
                downloadExecutive: false,
            };
        case EXECUTIVE_REPORT_ERROR:
            return {
                ...state,
                downloadExecutive: false,
            };
        case GET_ATTACHMENTS:
            return {
                ...state,
                downloadAllAttachments: true,
            };
        case GET_ATTACHMENTS_SUCCESS:
            return {
                ...state,
                downloadAllAttachments: false,
            };
        case GET_ATTACHMENTS_ERROR:
            return {
                ...state,
                downloadAllAttachments: false,
            };
        case SUBMIT_REVIEWER_ASSESSMENT:
            return {
                ...state,
                loadingForm: true,
                error: '',
                success: false,
            };
        case SUBMIT_REVIEWER_ASSESSMENT_ERROR:
            return {
                ...state,
                loadingForm: false,
                error: action.payload,
            };
        case VALIDATE_REVIEWER_ASSESSMENT_LINK:
            return {
                ...state,
                assessment: {},
                loading: true,
            };
        case VALIDATE_REVIEWER_ASSESSMENT_LINK_SUCCESS:
            return {
                ...state,
                loading: false,
                assessment: action.payload,
            };
        case VALIDATE_REVIEWER_ASSESSMENT_LINK_ERROR:
            return {
                ...state,
                loading: false,
                errorStatusCode: action.payload.code,
            };
        case GET_CSV_RESPONSE:
            return {
                ...state,
                downloadCSVResponse: true,
            };
        case GET_CSV_RESPONSE_SUCCESS:
            return {
                ...state,
                downloadCSVResponse: false,
            };
        case GET_CSV_RESPONSE_ERROR:
            return {
                ...state,
                downloadCSVResponse: false,
            };
        case LOAD_STANDARD:
            return {
                ...state,
                loading: true,
                standardData: {},
                error: '',
            };
        case LOAD_STANDARD_SUCCESS:
            return {
                ...state,
                loading: false,
                standardData: action.payload,
            };
        case LOAD_STANDARD_ERROR:
            return {
                ...state,
                loading: false,
                standardData: {},
                error: action.payload,
            };
        case ADD_REVIEWER:
            return {
                ...state,
                addingReviewer: true,
                reviewer: {},
                addingReviewerSuccess: false,
                addingReviewerError: '',
            };
        case ADD_REVIEWER_SUCCESS:
            return {
                ...state,
                addingReviewer: false,
                reviewer: action.payload.review,
                addingReviewerSuccess: true,
                addingReviewerError: '',
                report: action.payload.assessment,
                reviewerID: action.payload.reviewer,
            };
        case ADD_REVIEWER_ERROR:
            return {
                ...state,
                addingReviewer: false,
                reviewer: {},
                addingReviewerSuccess: false,
                addingReviewerError: action.payload.message,
            };
        case ADD_PUBLIC_INVITE_LINK:
            return {
                ...state,
                publicInvitationLink: '',
                publicInvitationError: '',
                publicInvitationSuccess: false,
            };
        case ADD_PUBLIC_INVITE_LINK_ERROR:
            return {
                ...state,
                publicInvitationLink: '',
                publicInvitationSuccess: false,
                publicInvitationError: action.payload,
            };
        case ADD_PUBLIC_INVITE_LINK_SUCCESS:
            return {
                ...state,
                publicInvitationError: '',
                publicInvitationSuccess: true,
                publicInvitationLink: action.payload,
            };
        case SUBMIT_PUBLIC_USER:
            return {
                ...state,
                publicUserAssessment: '',
                publicUserAssessmentError: '',
                publicUserAssessmentSuccess: false,
            };
        case SUBMIT_PUBLIC_USER_ERROR:
            return {
                ...state,
                publicUserAssessment: '',
                publicUserAssessmentSuccess: false,
                publicUserAssessmentError: action.payload,
            };
        case SUBMIT_PUBLIC_USER_SUCCESS:
            return {
                ...state,
                publicUserAssessmentError: '',
                publicUserAssessmentSuccess: true,
                publicUserAssessment: action.payload,
            };
        case VALIDATE_PUBLIC_ASSESSMENT_LINK:
            return {
                ...state,
                publicAssessmentStatus: '',
                loading: true,
            };
        case VALIDATE_PUBLIC_ASSESSMENT_LINK_ERROR:
            return {
                ...state,
                loading: false,
                publicAssessmentErrorStatus: action.payload?.code,
            };
        case VALIDATE_PUBLIC_ASSESSMENT_LINK_SUCCESS:
            return {
                ...state,
                loading: false,
                publicAssessmentStatus: action.payload,
            };
        case RESET_PUBLIC_ASSESSMENT_MESSAGES:
            return {
                ...state,
                publicUserAssessment: '',
                publicUserAssessmentError: '',
                publicUserAssessmentSuccess: '',
                comparisonSuccess: {},
                publicInvitationSuccess: false,
            };
        case LOAD_SHAREABLE_ASSESSMENT:
            return {
                ...state,
                loading: true,
                assessmentData: {},
                error: '',
            };
        case LOAD_SHAREABLE_ASSESSMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                assessmentData: action.payload,
            };
        case LOAD_SHAREABLE_ASSESSMENT_ERROR:
            return {
                ...state,
                loading: false,
                assessmentData: {},
                error: action.payload,
            };
        case REASSIGN_USER:
            return {
                ...state,
                loading: true,
                reassignUserData: {},
                reassignUserError: '',
                reassignUserSuccess: false,
            };
        case REASSIGN_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                reassignUserError: '',
                reassignUserSuccess: true,
                reassignUserData: action.payload,
            };
        case REASSIGN_USER_ERROR:
            return {
                ...state,
                loading: false,
                reassignUserData: {},
                reassignUserSuccess: false,
                reassignUserError: action.payload,
            };
        case LOAD_RESOURCE_DETAILS:
            return {
                ...state,
                error: '',
                success: false,
                loading: true,
                resourceData: {},
            };
        case LOAD_RESOURCE_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                resourceData: {},
                error: action.payload,
            };
        case LOAD_RESOURCE_DETAILS_SUCCESS:
            return {
                ...state,
                error: '',
                success: true,
                loading: false,
                resourceData: action.payload.body,
            };
        case COMPARE_ASSESSMENTS_LIST:
            return {
                ...state,
                error: '',
                loading: true,
                compareList: [],
            };
        case COMPARE_ASSESSMENTS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                compareList: [],
                error: action.payload.message,
            };
        case COMPARE_ASSESSMENTS_LIST_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                compareList: action.payload,
            };
        case COMPARE_ASSESSMENTS:
            return {
                ...state,
                success: false,
                compareError: '',
            };
        case COMPARE_ASSESSMENTS_ERROR:
            return {
                ...state,
                success: false,
                compareError: action.payload.message,
            };
        case COMPARE_ASSESSMENTS_SUCCESS:
            return {
                ...state,
                success: true,
                compareError: '',
                comparisonSuccess: action.payload,
            };
        case VALIDATE_COMPARISON_ASSESSMENT_LINK:
            return {
                ...state,
                newAssessmentss: [],
                loading: true,
            };
        case VALIDATE_COMPARISON_ASSESSMENT_LINK_ERROR:
            return {
                ...state,
                loading: false,
                newAssessmentss: [],
                errorStatusCode: action.payload?.code,
            };
        case VALIDATE_COMPARISON_ASSESSMENT_LINK_SUCCESS:
            return {
                ...state,
                loading: false,
                errorStatusCode: '',
                newAssessmentss: action.payload.assessments,
            };
        case COMPARISON_GENERAL_REPORT:
            return {
                ...state,
                downloadComparisonGeneral: true,
            };
        case COMPARISON_GENERAL_REPORT_ERROR:
            return {
                ...state,
                downloadComparisonGeneral: false,
            };
        case COMPARISON_GENERAL_REPORT_SUCCESS:
            return {
                ...state,
                downloadComparisonGeneral: false,
            };
        case VALIDATE_MULTIPLE_COMPARISON:
            return {
                ...state,
                loading: true,
                mulAssessments: [],
            };
        case VALIDATE_MULTIPLE_COMPARISON_ERROR:
            return {
                ...state,
                loading: false,
                mulAssessments: [],
                errorStatusCode: action.payload?.code,
            };
        case VALIDATE_MULTIPLE_COMPARISON_SUCCESS:
            return {
                ...state,
                loading: false,
                errorStatusCode: '',
                mulAssessments: action.payload,
            };
        case DOWNLOAD_GENERAL_REPORT_FOR_MULTIPLE_RESOURCES:
            return {
                ...state,
                downloadMulComparisonGeneral: true,
            };
        case DOWNLOAD_GENERAL_REPORT_FOR_MULTIPLE_RESOURCES_ERROR:
            return {
                ...state,
                downloadMulComparisonGeneral: false,
            };
        case DOWNLOAD_GENERAL_REPORT_FOR_MULTIPLE_RESOURCES_SUCCESS:
            return {
                ...state,
                downloadMulComparisonGeneral: false,
            };
        case VALIDATE_TIMELINE_REPORT_COMPARISON:
            return {
                ...state,
                loading: true,
                timeLineReport: [],
            };
        case VALIDATE_TIMELINE_REPORT_COMPARISON_ERROR:
            return {
                ...state,
                loading: false,
                timeLineReport: [],
                errorStatusCode: action.payload?.code,
            };
        case VALIDATE_TIMELINE_REPORT_COMPARISON_SUCCESS:
            return {
                ...state,
                loading: false,
                errorStatusCode: '',
                timeLineReport: action.payload.assessments,
            };
        case DOWNLOAD_EXECUTIVE_REPORT_FOR_MULTIPLE_RESOURCES:
            return {
                ...state,
                downloadMulComparisonExecutive: true,
            };
        case DOWNLOAD_EXECUTIVE_REPORT_FOR_MULTIPLE_RESOURCES_ERROR:
            return {
                ...state,
                downloadMulComparisonExecutive: false,
            };
        case DOWNLOAD_EXECUTIVE_REPORT_FOR_MULTIPLE_RESOURCES_SUCCESS:
            return {
                ...state,
                downloadMulComparisonExecutive: false,
            };
        case GET_MAIN_WEB_REPORT:
            return {
                ...state,
                error: '',
                loading: true,
                webReport: {},
            };
        case GET_MAIN_WEB_REPORT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_MAIN_WEB_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                webReport: action.payload,
            };
        case SINGLE_PAGE_GENERAL_REPORT:
            return {
                ...state,
                downloadGeneral: true,
            };
        case SINGLE_PAGE_GENERAL_REPORT_SUCCESS:
            return {
                ...state,
                downloadGeneral: false,
            };
        case SINGLE_PAGE_GENERAL_REPORT_ERROR:
            return {
                ...state,
                downloadGeneral: false,
            };
        case SWITCH_REPORTS:
            return {
                ...state,
                loading: true,
                switchReportSuccess: '',
            };
        case SWITCH_REPORTS_ERROR:
            return {
                ...state,
                loading: false,
                switchReportError: action.payload,
            };
        case SWITCH_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                switchReportSuccess: action.payload,
            };
        case UPDATE_REPORT_CONTENT_FROM_WEB:
            return {
                ...state,
                loading: true,
                reportContentDataError: '',
                reportContentDataSuccess: '',
            };
        case UPDATE_REPORT_CONTENT_FROM_WEB_ERROR:
            return {
                ...state,
                loading: false,
                reportContentDataSuccess: '',
                reportContentDataError: action.payload,
            };
        case UPDATE_REPORT_CONTENT_FROM_WEB_SUCCESS:
            return {
                ...state,
                loading: false,
                reportContentDataError: '',
                reportContentDataSuccess: action.payload,
            };
        default:
            return state;
    }
};
