import {
    LOAD_DASHBOARD_DATA,
    LOAD_DASHBOARD_DATA_ERROR,
    LOAD_DASHBOARD_DATA_SUCCESS,
    GET_DASHBOARD_POLICY_DATA,
    GET_DASHBOARD_POLICY_DATA_ERROR,
    GET_DASHBOARD_POLICY_DATA_SUCCESS,
    GET_DASHBOARD_CONTROL_DATA,
    GET_DASHBOARD_CONTROL_DATA_ERROR,
    GET_DASHBOARD_CONTROL_DATA_SUCCESS,
    GET_DASHBOARD_RISK_DATA,
    GET_DASHBOARD_RISK_DATA_ERROR,
    GET_DASHBOARD_RISK_DATA_SUCCESS,
    GET_DASHBOARD_THREAT_DATA,
    GET_DASHBOARD_THREAT_DATA_ERROR,
    GET_DASHBOARD_THREAT_DATA_SUCCESS,
} from '../types';
const INIT_STATE = {
    loading: true,
    data: {},
    policies_count: '',
    controlsData: {},
    risksData: {},
    threatData: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_DASHBOARD_DATA:
            return {
                ...state,
                loading: true,
            };
        case LOAD_DASHBOARD_DATA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case LOAD_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case GET_DASHBOARD_POLICY_DATA:
            return {
                ...state,
                loading: true,
            };
        case GET_DASHBOARD_POLICY_DATA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_DASHBOARD_POLICY_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                policies_count: action.payload,
            };
        case GET_DASHBOARD_CONTROL_DATA:
            return {
                ...state,
                loading: true,
            };
        case GET_DASHBOARD_CONTROL_DATA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_DASHBOARD_CONTROL_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                controlsData: action.payload,
            };
        case GET_DASHBOARD_RISK_DATA:
            return {
                ...state,
                loading: true,
            };
        case GET_DASHBOARD_RISK_DATA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_DASHBOARD_RISK_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                risksData: action.payload,
            };
        case GET_DASHBOARD_THREAT_DATA:
            return {
                ...state,
                loading: true,
            };
        case GET_DASHBOARD_THREAT_DATA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_DASHBOARD_THREAT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                threatData: action.payload,
            };
        default:
            return state;
    }
};
