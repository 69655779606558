import axios from 'axios';
import {
    LOAD_ASSET,
    LOAD_ASSET_ERROR,
    LOAD_ASSET_SUCCESS,
    EDIT_ASSET,
    EDIT_ASSET_SUCCESS,
    EDIT_ASSET_ERROR,
    ADD_ASSET,
    ADD_ASSET_SUCCESS,
    ADD_ASSET_ERROR,
    LIST_ASSET_ERROR,
    LIST_ASSET_SUCCESS,
    LIST_ASSET,
    DETAILS_COMPLETED_ASSET,
    DETAILS_COMPLETED_ASSET_SUCCESS,
    DETAILS_COMPLETED_ASSET_ERROR,
    SELECTED_ASSET_CHANGE,
    DELETE_SELECTED_ASSET,
    DELETE_SELECTED_ASSET_ERROR,
    DELETE_SELECTED_ASSET_SUCCESS,
    LIST_PARENT_ASSET,
    LIST_PARENT_ASSET_SUCCESS,
    LIST_PARENT_ASSET_ERROR,
    GET_RESOURCE_HISTORY,
    GET_RESOURCE_HISTORY_ERROR,
    GET_RESOURCE_HISTORY_SUCCESS,
    UPLOAD_EVIDENCE,
    UPLOAD_EVIDENCE_ERROR,
    UPLOAD_EVIDENCE_SUCCESS,
    GET_RISK_HISTORY,
    GET_RISK_HISTORY_ERROR,
    GET_RISK_HISTORY_SUCCESS,
    GET_THREAT_HISTORY,
    GET_THREAT_HISTORY_ERROR,
    GET_THREAT_HISTORY_SUCCESS,
    LIST_LIBRARY_CONTROLS,
    LIST_LIBRARY_CONTROLS_ERROR,
    LIST_LIBRARY_CONTROLS_SUCCESS,
    SELECTED_CONTROLS_LIST,
    ADD_CONTROLS_TO_RESOURCES,
    ADD_CONTROLS_TO_RESOURCES_ERROR,
    ADD_CONTROLS_TO_RESOURCES_SUCCESS,
    RESET_RESOURCES_STATES,
    DELETE_RESOURCE_CONTROL,
    DELETE_RESOURCE_CONTROL_ERROR,
    DELETE_RESOURCE_CONTROL_SUCCESS,
    UPLOAD_EVIDENCES_FILES,
    UPLOAD_EVIDENCES_FILES_ERROR,
    UPLOAD_EVIDENCES_FILES_SUCCESS,
    GET_REVIEWS_LIST,
    GET_REVIEWS_LIST_ERROR,
    GET_REVIEWS_LIST_SUCCESS,
    SUBMIT_REVIEW,
    SUBMIT_REVIEW_ERROR,
    SUBMIT_REVIEW_SUCCESS,
    SUBMIT_EDIT_REVIEW,
    SUBMIT_EDIT_REVIEW_ERROR,
    SUBMIT_EDIT_REVIEW_SUCCESS,
    MARK_REVIEW_AS_DONE,
    MARK_REVIEW_AS_DONE_ERROR,
    MARK_REVIEW_AS_DONE_SUCCESS,
    ALL_EVIDENCE_ATTACHMENTS,
    ALL_EVIDENCE_ATTACHMENTS_ERROR,
    ALL_EVIDENCE_ATTACHMENTS_SUCCESS,
    DOWNLOAD_ATTACHED_EVIDENCE,
    DOWNLOAD_ATTACHED_EVIDENCE_ERROR,
    DOWNLOAD_ATTACHED_EVIDENCE_SUCCESS,
    GET_HISTORY_LOGS,
    GET_HISTORY_LOGS_ERROR,
    GET_HISTORY_LOGS_SUCCESS,
    RESET_REVIEW_MESSAGES,
    GENERATE_CONTROL_REPORT,
    GENERATE_CONTROL_REPORT_ERROR,
    GENERATE_CONTROL_REPORT_SUCCESS,
    RESET_CONTROL_REPORT_MESSAGES,
    SET_GO_TO_BACK,
    GET_REVIEW_HISTORY,
    GET_REVIEW_HISTORY_ERROR,
    GET_REVIEW_HISTORY_SUCCESS,
    LAST_ACTION_PERFORMED,
    STORE_ASSET_ID,
    GET_CONTROL_FRAMEWORK,
    GET_CONTROL_FRAMEWORK_ERROR,
    GET_CONTROL_FRAMEWORK_SUCCESS,
    SELECTED_STD_CONTROLS_LIST,
    ADD_STANDARD_CONTROL_FOR_RESOURCE,
    ADD_STANDARD_CONTROL_FOR_RESOURCE_ERROR,
    ADD_STANDARD_CONTROL_FOR_RESOURCE_SUCCESS,
    LIST_RESOURCES,
    LIST_RESOURCES_ERROR,
    LIST_RESOURCES_SUCCESS,
    UPLOADED_DATA,
    SET_UPLOAD_FILE,
    RETRY_UPLOAD_FILE,
    SET_UPLOAD_PROGRESS,
    SUCCESS_UPLOAD_FILE,
    FAILURE_UPLOAD_FILE,
    UPLOADEDFILES_SUCCESS,
    SUBMIT_REVIEW_CLEAR,
    UPLOADEDFILES_PROGRESS,
    RESETON_SUCCESS,
    DELETEFROM_UPLOADED,
    GETRESPONSEFROM_UPLOAD,
    DELETEFROM_UPRESPONSE,
    SET_ALL_REVIEWS_FILTERS,
    SET_ALL_REVIEWS_FILTERS_MODEL, LIST_ASSET_OPTIONS_SUCCESS,
} from '../types';
import { STATUS_UPLOAD } from './Constants';
import { modifyFiles } from './UploadFile.utils';
import { setAllReviewsFiltersModel } from './actions';

const INIT_STATE = {
    list: [],
    completedList: [],
    pendingList: [],
    loading: false,
    error: '',
    selected: [],
    publishing: false,
    publishSuccess: false,
    publishError: '',
    invitationListError: {},
    invitationSuccess: false,
    deleteSuccess: '',
    deleteError: '',
    openAsset: {},
    selectCompleted: [],
    adding: false,
    success: false,
    openCompletedAsset: {},
    editing: false,
    invitationList: [],
    inviting: false,
    publishedAssetId: 0,
    parentList: [],
    resourceHistory: [],
    evidenceUploadError: '',
    evidenceUploadSuccess: '',
    riskHistory: [],
    threatHistory: [],
    errorAsset: '',
    libraryControlsList: [],
    selectedCtrlList: [],
    addingCtrlForResource: false,
    deleteResCtrlError: '',
    uploadError: '',
    uploadSuccess: {},
    reviewLists: [],
    reviewListsLoading: false,
    reviewSuccess: {},
    reviewError: '',
    reviewEditSuccess: {},
    reviewEditError: '',
    reviewCompletedSuccess: {},
    reviewCompletedError: '',
    evidenceLists: [],
    evidenceLoading: false,
    downloadEvidence: null,
    downloadEvidenceLoading: false,
    logsLoading: false,
    historyLogs: [],
    reviewSubmitLoading: false,
    reviewEditLoading: false,
    reviewCompletedLoading: false,
    uploadLoading: false,
    createCtrlReportLoading: false,
    ctrlReport: {},
    tabNo: '3',
    reviewLogs: [],
    actionPerformed: '',
    storedAssetId: '',
    ctrlFrameworks: [],
    selectedStdControls: [],
    addingStdCtrlForResource: false,
    reportError: '',
    addedCtrlForRes: false,
    addedStdCtrlForRes: false,
    resourcesList: [],
    fileProgress: {},
    data: [],
    newFilesUploaded: [],
    allReviewsFilters: null,
    allReviewsFiltersModel: null,
    progress: 0,
    eviResponse: [],
    updateAsset: false,
    updateAssetError: false,
    updateAssetSuccess: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SELECTED_ASSET_CHANGE:
            return {
                ...state,
                selected: action.payload,
            };

        case LIST_ASSET:
            return {
                ...state,
                loading: true,
                error: '',
                list: [],
                selected: [],
                deleteSuccess: '',
            };

        case LIST_ASSET_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.payload,
                error: '',
            };

        case LIST_ASSET_OPTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                listOptions: action.payload,
                error: '',
            };

        case LIST_ASSET_ERROR:
            return {
                ...state,
                loading: false,
                list: [],
                error: action.payload.message,
            };

        case SET_ALL_REVIEWS_FILTERS:
            return {
                ...state,
                allReviewsFilters: action.payload,
            };

        case SET_ALL_REVIEWS_FILTERS_MODEL:
            return {
                ...state,
                allReviewsFiltersModel: action.payload,
            };

        case ADD_ASSET:
            return {
                ...state,
                publishing: true,
                publishError: '',
                publishSuccess: false,
                publishedAssessttypeId: 0,
            };

        case ADD_ASSET_SUCCESS:
            return {
                ...state,
                publishing: false,
                publishSuccess: true,
                publishedAssessttypeId: action.payload,
            };

        case ADD_ASSET_ERROR:
            return {
                ...state,
                publishSuccess: false,
                publishing: false,
                publishError: action.payload.message,
            };

        case EDIT_ASSET:
            return {
                ...state,
                updateAsset: true,
                updateAssetError: false,
                updateAssetSuccess: false,
            };

        case EDIT_ASSET_ERROR:
            return {
                ...state,
                updateAssetSuccess: false,
                updateAsset: false,
                updateAssetError: action.payload.message,
            };

        case EDIT_ASSET_SUCCESS:
            return {
                ...state,
                updateAsset: false,
                updateAssetSuccess: true,
                // list: action.payload,
                updateAssetError: false,
            };

        case LOAD_ASSET:
            return {
                ...state,
                loading: true,
                openAsset: {},
                error: '',
            };

        case LOAD_ASSET_SUCCESS:
            return {
                ...state,
                loading: false,
                openAsset: action.payload,
            };

        case LOAD_ASSET_ERROR:
            return {
                ...state,
                loading: false,
                errorAsset: action.payload,
            };

        case DETAILS_COMPLETED_ASSET:
            return {
                ...state,
                editing: true,
                editError: {},
                success: false,
            };

        case DETAILS_COMPLETED_ASSET_SUCCESS:
            return {
                ...state,
                editing: false,
                success: true,
                editError: {},
                openCompletedAssessment: action.payload,
            };

        case DETAILS_COMPLETED_ASSET_ERROR:
            return {
                ...state,
                editing: false,
                editError: action.payload,
            };

        case DELETE_SELECTED_ASSET:
            return {
                ...state,
                deleting: true,
                deleteSuccess: false,
            };

        case DELETE_SELECTED_ASSET_ERROR:
            return {
                ...state,
                deleting: false,
                deleteSuccess: false,
            };

        case DELETE_SELECTED_ASSET_SUCCESS:
            return {
                ...state,
                deleting: false,
                deleteSuccess: true,
                list: [...action.payload],
            };

        case LIST_PARENT_ASSET:
            return {
                ...state,
                loading: true,
                error: '',
                parentList: [],
                deleteSuccess: '',
            };

        case LIST_PARENT_ASSET_SUCCESS:
            return {
                ...state,
                loading: false,
                parentList: action.payload,
                error: '',
            };

        case LIST_PARENT_ASSET_ERROR:
            return {
                ...state,
                loading: false,
                parentList: [],
                error: action.payload.message,
            };
        case GET_RESOURCE_HISTORY:
            return {
                ...state,
                error: '',
                loading: true,
                resourceHistory: [],
            };
        case GET_RESOURCE_HISTORY_ERROR:
            return {
                ...state,
                loading: false,
                resourceHistory: [],
                error: action.payload.message,
            };
        case GET_RESOURCE_HISTORY_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                resourceHistory: action.payload,
            };
        case UPLOAD_EVIDENCE:
            return {
                ...state,
                loading: true,
                evidenceUploadError: '',
                evidenceUploadSuccess: '',
            };
        case UPLOAD_EVIDENCE_ERROR:
            return {
                ...state,
                loading: false,
                evidenceUploadSuccess: '',
                evidenceUploadError: action.payload.message,
            };
        case UPLOAD_EVIDENCE_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                evidenceUploadError: '',
                evidenceUploadSuccess: action.payload,
            };

        case GET_RISK_HISTORY:
            return {
                ...state,
                error: '',
                loading: true,
                riskHistory: [],
            };

        case GET_RISK_HISTORY_ERROR:
            return {
                ...state,
                error: '',
                loading: false,
                riskHistory: [],
            };

        case GET_RISK_HISTORY_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                riskHistory: action.payload,
            };

        case GET_THREAT_HISTORY:
            return {
                ...state,
                error: '',
                loading: true,
                threatHistory: [],
            };

        case GET_THREAT_HISTORY_ERROR:
            return {
                ...state,
                error: '',
                loading: false,
                threatHistory: [],
            };

        case GET_THREAT_HISTORY_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                threatHistory: action.payload,
            };
        case LIST_LIBRARY_CONTROLS:
            return {
                ...state,
                error: '',
                loading: true,
                libraryControlsList: [],
            };
        case LIST_LIBRARY_CONTROLS_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                libraryControlsList: action.payload,
            };
        case LIST_LIBRARY_CONTROLS_ERROR:
            return {
                ...state,
                loading: false,
                libraryControlsList: [],
                error: action.payload.message,
            };
        case SELECTED_CONTROLS_LIST:
            return {
                ...state,
                selectedCtrlList: action.payload,
            };
        case ADD_CONTROLS_TO_RESOURCES:
            return {
                ...state,
                error: '',
                loading: true,
                addingCtrlForResource: false,
            };
        case ADD_CONTROLS_TO_RESOURCES_ERROR:
            return {
                ...state,
                loading: false,
                addingCtrlForResource: false,
                error: action.payload.message,
            };
        case ADD_CONTROLS_TO_RESOURCES_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                addedCtrlForRes: true,
                addingCtrlForResource: true,
            };
        case RESET_RESOURCES_STATES:
            return {
                ...state,
                error: '',
                success: false,
                loading: false,
                selectedCtrlList: [],
                deleteResCtrlError: '',
                libraryControlsList: [],
                reviewCompletedSuccess: {},
                addingCtrlForResource: false,
                addingStdCtrlForResource: false,
                updateAsset: false,
                updateAssetSuccess: false,
                updateAssetError: false,
            };
        case DELETE_RESOURCE_CONTROL:
            return {
                ...state,
                loading: true,
                success: false,
                deleteResCtrlError: '',
            };
        case DELETE_RESOURCE_CONTROL_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                deleteResCtrlError: action.payload,
            };
        case DELETE_RESOURCE_CONTROL_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                deleteResCtrlError: '',
            };
        case UPLOAD_EVIDENCES_FILES:
            return {
                ...state,
                uploadError: '',
                uploadSuccess: {},
                uploadLoading: true,
            };
        case UPLOAD_EVIDENCES_FILES_ERROR:
            return {
                ...state,
                uploadSuccess: {},
                uploadLoading: false,
                uploadError: action.payload.message,
            };
        case UPLOAD_EVIDENCES_FILES_SUCCESS:
            return {
                ...state,
                error: '',
                uploadError: '',
                uploadLoading: false,
                uploadSuccess: action.payload,
            };
        case GET_REVIEWS_LIST:
            return {
                ...state,
                error: '',
                reviewLists: [],
                reviewListsLoading: true,
            };
        case GET_REVIEWS_LIST_SUCCESS:
            return {
                ...state,
                error: '',
                reviewListsLoading: false,
                reviewLists: action.payload,
            };
        case GET_REVIEWS_LIST_ERROR:
            return {
                ...state,
                reviewLists: [],
                reviewListsLoading: false,
                error: action.payload.message,
            };
        case SUBMIT_REVIEW:
            return {
                ...state,
                reviewSuccess: {},
                reviewError: '',
                reviewSubmitLoading: true,
            };
        case SUBMIT_REVIEW_ERROR:
            return {
                ...state,
                reviewSuccess: {},
                reviewSubmitLoading: false,
                reviewError: action.payload,
            };
        case SUBMIT_REVIEW_SUCCESS:
            return {
                ...state,
                reviewError: '',
                reviewSubmitLoading: false,
                reviewSuccess: action.payload,
            };
        case SUBMIT_EDIT_REVIEW:
            return {
                ...state,
                reviewEditError: '',
                reviewEditSuccess: {},
                reviewEditLoading: true,
            };
        case SUBMIT_EDIT_REVIEW_ERROR:
            return {
                ...state,
                reviewEditSuccess: {},
                reviewEditLoading: false,
                reviewEditError: action.payload,
            };
        case SUBMIT_EDIT_REVIEW_SUCCESS:
            return {
                ...state,
                reviewEditError: '',
                reviewEditLoading: false,
                reviewEditSuccess: action.payload,
            };
        case MARK_REVIEW_AS_DONE:
            return {
                ...state,
                reviewCompletedSuccess: {},
                reviewCompletedError: '',
                reviewCompletedLoading: true,
            };
        case MARK_REVIEW_AS_DONE_ERROR:
            return {
                ...state,
                reviewCompletedSuccess: {},
                reviewCompletedLoading: false,
                reviewCompletedError: action.payload,
            };
        case MARK_REVIEW_AS_DONE_SUCCESS:
            return {
                ...state,
                reviewCompletedError: '',
                reviewCompletedLoading: false,
                reviewCompletedSuccess: action.payload,
            };
        case ALL_EVIDENCE_ATTACHMENTS:
            return {
                ...state,
                error: '',
                evidenceLists: [],
                evidenceLoading: true,
            };
        case ALL_EVIDENCE_ATTACHMENTS_ERROR:
            return {
                ...state,
                evidenceLists: [],
                evidenceLoading: false,
                error: action.payload.message,
            };
        case ALL_EVIDENCE_ATTACHMENTS_SUCCESS:
            return {
                ...state,
                error: '',
                evidenceLoading: false,
                evidenceLists: action.payload,
            };
        case DOWNLOAD_ATTACHED_EVIDENCE:
            return {
                ...state,
                downloadEvidence: null,
                downloadEvidenceLoading: true,
            };
        case DOWNLOAD_ATTACHED_EVIDENCE_ERROR:
            return {
                ...state,
                downloadEvidence: false,
                downloadEvidenceLoading: false,
            };
        case DOWNLOAD_ATTACHED_EVIDENCE_SUCCESS:
            return {
                ...state,
                downloadEvidence: action.payload,
                downloadEvidenceLoading: false,
            };
        case GET_HISTORY_LOGS:
            return {
                ...state,
                error: '',
                historyLogs: [],
                logsLoading: true,
            };
        case GET_HISTORY_LOGS_ERROR:
            return {
                ...state,
                historyLogs: [],
                logsLoading: false,
                error: action.payload.message,
            };
        case GET_HISTORY_LOGS_SUCCESS:
            return {
                ...state,
                error: '',
                logsLoading: false,
                historyLogs: action.payload,
            };
        case RESET_REVIEW_MESSAGES:
            return {
                ...state,
                reviewError: '',
                reviewSuccess: {},
                reviewSubmitLoading: false,
                reviewEditError: '',
                reviewEditSuccess: {},
                reviewEditLoading: false,
            };
        case GENERATE_CONTROL_REPORT:
            return {
                ...state,
                reportError: '',
                ctrlReport: {},
                createCtrlReportLoading: true,
            };
        case GENERATE_CONTROL_REPORT_ERROR:
            return {
                ...state,
                ctrlReport: {},
                reportError: action.payload,
                createCtrlReportLoading: false,
            };
        case GENERATE_CONTROL_REPORT_SUCCESS:
            return {
                ...state,
                reportError: '',
                ctrlReport: action.payload,
                createCtrlReportLoading: false,
            };
        case RESET_CONTROL_REPORT_MESSAGES:
            return {
                ...state,
                error: '',
                ctrlReport: {},
                createCtrlReportLoading: false,
            };
        case SET_GO_TO_BACK:
            return {
                ...state,
                tabNo: action.payload,
            };
        case GET_REVIEW_HISTORY:
            return {
                ...state,
                error: '',
                logsLoading: true,
                reviewLogs: [],
            };
        case GET_REVIEW_HISTORY_ERROR:
            return {
                ...state,
                error: '',
                logsLoading: false,
                reviewLogs: [],
            };
        case GET_REVIEW_HISTORY_SUCCESS:
            return {
                ...state,
                error: '',
                logsLoading: false,
                reviewLogs: action.payload,
            };
        case LAST_ACTION_PERFORMED:
            return {
                ...state,
                actionPerformed: action.payload,
            };
        case STORE_ASSET_ID:
            return {
                ...state,
                storedAssetId: action.payload,
            };
        case GET_CONTROL_FRAMEWORK:
            return {
                ...state,
                error: '',
                loading: true,
                ctrlFrameworks: [],
            };
        case GET_CONTROL_FRAMEWORK_ERROR:
            return {
                ...state,
                error: '',
                loading: false,
                ctrlFrameworks: [],
            };
        case GET_CONTROL_FRAMEWORK_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                ctrlFrameworks: action.payload,
            };
        case SELECTED_STD_CONTROLS_LIST:
            return {
                ...state,
                selectedStdControls: action.payload,
            };
        case ADD_STANDARD_CONTROL_FOR_RESOURCE:
            return {
                ...state,
                error: '',
                loading: true,
                addingStdCtrlForResource: false,
            };
        case ADD_STANDARD_CONTROL_FOR_RESOURCE_ERROR:
            return {
                ...state,
                loading: false,
                addingStdCtrlForResource: false,
                error: action.payload.message,
            };
        case ADD_STANDARD_CONTROL_FOR_RESOURCE_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                addedStdCtrlForRes: true,
                addingStdCtrlForResource: true,
            };
        case LIST_RESOURCES:
            return {
                ...state,
                error: '',
                loading: true,
                resourcesList: [],
            };
        case LIST_RESOURCES_ERROR:
            return {
                ...state,
                loading: false,
                resourcesList: [],
                error: action.payload.message,
            };
        case LIST_RESOURCES_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                resourcesList: action.payload,
            };
        case SET_UPLOAD_FILE:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    ...modifyFiles(
                        state.fileProgress,
                        action.payload,
                    ),
                },
            };
        case SET_UPLOAD_PROGRESS:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.payload.id]: {
                        ...state.fileProgress[action.payload.id],
                        progress: action.payload.progress,
                    },
                },
            };
        case SUCCESS_UPLOAD_FILE:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.payload]: {
                        ...state.fileProgress[action.payload],
                        status: STATUS_UPLOAD.success,
                    },
                },
            };
        case FAILURE_UPLOAD_FILE:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.payload]: {
                        ...state.fileProgress[action.payload],
                        status: STATUS_UPLOAD.failed,
                        progress: 0,
                    },
                },
            };
        case UPLOADED_DATA:
            return {
                ...state,
                data: [
                    ...state.data,
                    {
                        id: action.payload.id,
                        url: action.payload.url,
                    },
                ],
            };
        case RETRY_UPLOAD_FILE:
            const CancelToken = axios.CancelToken;
            const cancelSource = CancelToken.source();
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.payload]: {
                        ...state.fileProgress[action.payload],
                        status: STATUS_UPLOAD.uploading,
                        progress: 0,
                        cancelSource,
                    },
                },
            };
        case UPLOADEDFILES_SUCCESS:
            return {
                ...state,
                newFilesUploaded: action.payload,
            };
        case SUBMIT_REVIEW_CLEAR:
            return {
                ...state,
                newFilesUploaded: [],
            };
        case UPLOADEDFILES_PROGRESS:
            return {
                ...state,
                progress: action.payload,
            };
        case RESETON_SUCCESS:
            return {
                ...state,
                fileProgress: {},
            };
        case DELETEFROM_UPLOADED:
            let newItems = { ...state.fileProgress };
            delete newItems[action.payload];
            return {
                ...state,
                fileProgress: newItems,
            };
        case GETRESPONSEFROM_UPLOAD:
            return {
                ...state,
                eviResponse: action.payload,
            };
        case DELETEFROM_UPRESPONSE:
            let getfiles = state.eviResponse;
            let filterFiles = getfiles.filter((e: any) => {
                let url = e.evidence_file;
                if (url.indexOf('_') === -1) {
                    let name = url
                        .substring(url.lastIndexOf('/') + 1)
                        .replace(/[^a-zA-Z0-9.]/g, '');
                    return name !== action.payload;
                } else {
                    let name = url.substring(
                        url.lastIndexOf('/') + 1,
                        url.lastIndexOf('_'),
                    );
                    name = name.replace(/[^a-zA-Z0-9.]/g, '');
                    let type = url.substring(url.lastIndexOf('.'));
                    let fileName = name + type;
                    return fileName !== action.payload;
                }
            });
            const getID = filterFiles.map((item: any) => item.id);
            return {
                ...state,
                eviResponse: filterFiles,
                newFilesUploaded: getID,
            };
        default:
            return state;
    }
};
