import {
    LOAD_TEMPLATES,
    LOAD_TEMPLATES_ERROR,
    LOAD_TEMPLATES_SUCCESS,
    LOAD_TEMPLATE,
    LOAD_TEMPLATE_ERROR,
    LOAD_TEMPLATE_SUCCESS,
    ADD_TEMPLATE,
    ADD_TEMPLATE_ERROR,
    ADD_TEMPLATE_SUCCESS,
    SELECTED_TEMPLATES_CHANGE,
    DELETE_SELECTED_TEMPLATES,
    DELETE_SELECTED_TEMPLATES_ERROR,
    DELETE_SELECTED_TEMPLATES_SUCCESS,
    EDIT_TEMPLATE,
    EDIT_TEMPLATE_SUCCESS,
    EDIT_TEMPLATE_ERROR,
    SAVE_TEMPLATE,
    SAVE_TEMPLATE_SUCCESS,
    SAVE_TEMPLATE_ERROR,
    LOAD_GENERAL_TEMPLATES,
    LOAD_GENERAL_TEMPLATES_ERROR,
    LOAD_GENERAL_TEMPLATES_SUCCESS,
    LOAD_EXECUTIVE_TEMPLATES,
    LOAD_EXECUTIVE_TEMPLATES_ERROR,
    LOAD_EXECUTIVE_TEMPLATES_SUCCESS,
    DUPLICATE_TEMPLATE,
    DUPLICATE_TEMPLATE_SUCCESS,
    DUPLICATE_TEMPLATE_ERROR,
    LOAD_OLD_TEMPLATE_SECTIONS,
    LOAD_OLD_TEMPLATE_SECTION_SUCCESS,
    LOAD_OLD_TEMPLATE_SECTION_ERROR,
    RESET_TEMPLATE_ERROR_VALUES,
    RESET_TEMPLATE_SAVE_VALUES,
    RESET_ADD_TEMPLATE_VALUES,
    RESET_ADD_TEMPLATE_ERROR_VALUES,
    RESET_DELETE_TEMPLATE_ERROR_VALUES,
    RESET_DELETE_TEMPLATE_SUCCESS_VALUES,
    RESET_DUPLICATE_TEMPLATE_ERROR_VALUES,
    RESET_DUPLICATE_TEMPLATE_SUCCESS_VALUES,
    CHANGE_SELECTED_TEMPLATES_STATE,
    CHANGE_SELECTED_TEMPLATES_STATE_SUCCESS,
    CHANGE_SELECTED_TEMPLATES_STATE_ERROR,
    LOAD_STANDARDS_LIST,
    LOAD_STANDARDS_LIST_SUCCESS,
    LOAD_STANDARDS_LIST_ERROR,
    LIST_RISKS,
    LIST_RISKS_ERROR,
    LIST_RISKS_SUCCESS,
    LIST_THREATS_TEMP,
    LIST_THREATS_ERROR_TEMP,
    LIST_THREATS_SUCCESS_TEMP,
    GET_BASIC_TEMPLATE,
    GET_BASIC_TEMPLATE_ERROR,
    GET_BASIC_TEMPLATE_SUCCESS,
    EDIT_BASIC_TEMPLATE,
    EDIT_BASIC_TEMPLATE_ERROR,
    EDIT_BASIC_TEMPLATE_SUCCESS,
    LOAD_SECTION_DETAILS,
    LOAD_SECTION_DETAILS_ERROR,
    LOAD_SECTION_DETAILS_SUCCESS,
    ADD_SINGLE_SECTION,
    ADD_SINGLE_SECTION_ERROR,
    ADD_SINGLE_SECTION_SUCCESS,
    EDIT_SINGLE_SECTION,
    EDIT_SINGLE_SECTION_ERROR,
    EDIT_SINGLE_SECTION_SUCCESS,
    SAVE_NEW_TEMPLATE,
    SAVE_NEW_TEMPLATE_ERROR,
    SAVE_NEW_TEMPLATE_SUCCESS,
    GET_TEMP_DATA_FOR_REORDERING,
    GET_TEMP_DATA_FOR_REORDERING_ERROR,
    GET_TEMP_DATA_FOR_REORDERING_SUCCESS,
    REORDER_TEMPLATE_DATA,
    REORDER_TEMPLATE_DATA_ERROR,
    REORDER_TEMPLATE_DATA_SUCCESS,
    DELETE_SINGLE_SECTION,
    DELETE_SINGLE_SECTION_ERROR,
    DELETE_SINGLE_SECTION_SUCCESS,
    RESET_REORDER_MESSAGES,
    RESET_EDIT_AND_ADD_SECTION_MESSAGES,
    GET_BASIC_TEMPLATE_ERROR_WHILE_ADDING,
    GET_BASIC_TEMPLATE_SUCCESS_WHILE_ADDING,
    DOWNLOAD_TEMPLATE_FILE,
    DOWNLOAD_TEMPLATE_FILE_ERROR,
    DOWNLOAD_TEMPLATE_FILE_SUCCESS,
    UPDATE_TEMP_DETAILS,
    UPDATE_TEMP_DETAILS_ERROR,
    UPDATE_TEMP_DETAILS_SUCCESS,
    IMPORT_SECTION_FROM_OTHER_TEMPLATE,
    IMPORT_SECTION_FROM_OTHER_TEMPLATE_ERROR,
    IMPORT_SECTION_FROM_OTHER_TEMPLATE_SUCCESS, LOAD_TEMPLATE_VERSIONS_SUCCESS, LOAD_TEMPLATE_VERSIONS_SUCCESS_LOADING,
} from '../types';

const INIT_STATE = {
    list: [],
    loading: false,
    error: '',
    selected: [],
    templateVersions: [],
    adding: false,
    success: false,
    addError: {},
    deleteSuccess: '',
    deleteError: '',
    openTemplate: {},
    editing: false,
    saving: false,
    saveSuccess: false,
    saveError: {},
    executive_templates: [],
    loading_executive_templates: false,
    general_templates: [],
    loading_general_templates: false,
    duplicating: false,
    duplicateError: {},
    sectionNamesList: [],
    templateVersionsLoading: false,
    sectionsLoading: false,
    changeStateSuccess: '',
    changeStateError: '',
    standardList: [],
    risksList: [],
    threatsList: [],
    tempData: {},
    sectionData: {},
    addSectionError: '',
    sectionAdding: false,
    addSectionSuccess: false,
    editSectionError: '',
    sectionEditing: false,
    editSectionSuccess: false,
    reorderError: '',
    reorderLoading: false,
    tempDataForReordering: {},
    reorderDataError: '',
    reorderDataLoading: false,
    reorderDataSuccess: false,
    deleteSection: false,
    deleteSectionError: '',
    deleteSectionSuccess: '',
    downloadSampleTemplateFile: false,
    updatingTemp: false,
    updatingTempError: {},
    updatingTempsuccess: false,
    recentlyAddedSection: {},
    importSecSuccess: {},
    importSecError: '',
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_TEMPLATES:
            return {
                ...state,
                loading: true,
                error: '',
                selected: [],
                deleteSuccess: '',
            };
        case LOAD_TEMPLATES_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.payload,
                error: '',
            };
        case LOAD_TEMPLATES_ERROR:
            return {
                ...state,
                loading: false,
                list: [],
                error: action.payload.message,
            };
        case ADD_TEMPLATE:
            return {
                ...state,
                adding: true,
                addError: {},
                success: false,
            };
        case ADD_TEMPLATE_SUCCESS:
            return {
                ...state,
                adding: false,
                success: true,
                addError: {},
                list: action.payload,
            };
        case ADD_TEMPLATE_ERROR:
            return {
                ...state,
                adding: false,
                success: false,
                addError: action.payload.message,
            };
        case EDIT_TEMPLATE:
            return {
                ...state,
                editing: true,
                editError: {},
                success: false,
            };
        case EDIT_TEMPLATE_SUCCESS:
            return {
                ...state,
                editing: false,
                success: true,
                editError: {},
                openTemplate: action.payload,
            };
        case EDIT_TEMPLATE_ERROR:
            return {
                ...state,
                editing: false,
                editError: action.payload,
            };
        case SAVE_TEMPLATE:
            return {
                ...state,
                saving: true,
                saveError: {},
                saveSuccess: false,
            };
        case SAVE_TEMPLATE_SUCCESS:
            return {
                ...state,
                saving: false,
                saveSuccess: true,
                openTemplate: action.payload,
            };
        case SAVE_TEMPLATE_ERROR:
            return {
                ...state,
                saving: false,
                saveSuccess: false,
                saveError: action.payload,
            };
        case SELECTED_TEMPLATES_CHANGE:
            return {
                ...state,
                selected: action.payload,
            };
        case DELETE_SELECTED_TEMPLATES:
            return {
                ...state,
                loading: true,
                deleteSuccess: '',
                deleteError: '',
            };
        case DELETE_SELECTED_TEMPLATES_ERROR:
            return {
                ...state,
                deleteError: action.payload,
            };
        case DELETE_SELECTED_TEMPLATES_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteSuccess: action.payload,
            };
        case LOAD_TEMPLATE:
            return {
                ...state,
                loading: true,
                openTemplate: {},
                error: '',
            };
        case LOAD_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                openTemplate: action.payload,
            };
        case LOAD_TEMPLATE_VERSIONS_SUCCESS:
            return {
                ...state,
                templateVersions: action.payload,
            };
        case LOAD_TEMPLATE_VERSIONS_SUCCESS_LOADING:
            return {
                ...state,
                templateVersionsLoading: action.payload,
            };
        case LOAD_TEMPLATE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case LOAD_GENERAL_TEMPLATES:
            return {
                ...state,
                loading_general_templates: true,
            };
        case LOAD_GENERAL_TEMPLATES_SUCCESS:
            return {
                ...state,
                loading_general_templates: false,
                general_templates: action.payload.results,
            };
        case LOAD_GENERAL_TEMPLATES_ERROR:
            return {
                ...state,
                loading_general_templates: false,
            };
        case LOAD_EXECUTIVE_TEMPLATES:
            return {
                ...state,
                loading_executive_templates: true,
            };
        case LOAD_EXECUTIVE_TEMPLATES_SUCCESS:
            return {
                ...state,
                loading_executive_templates: false,
                executive_templates: action.payload,
            };
        case LOAD_EXECUTIVE_TEMPLATES_ERROR:
            return {
                ...state,
                loading_executive_templates: false,
            };
        case DUPLICATE_TEMPLATE:
            return {
                ...state,
                duplicating: true,
                duplicateError: {},
                success: false,
            };
        case DUPLICATE_TEMPLATE_SUCCESS:
            return {
                ...state,
                duplicating: false,
                success: true,
                duplicateError: {},
                list: action.payload,
            };
        case DUPLICATE_TEMPLATE_ERROR:
            return {
                ...state,
                duplicating: false,
                success: false,
                duplicateError: action.payload.message,
            };
        case LOAD_OLD_TEMPLATE_SECTIONS:
            return {
                ...state,
                sectionsLoading: true,
                error: '',
            };
        case LOAD_OLD_TEMPLATE_SECTION_SUCCESS:
            return {
                ...state,
                sectionsLoading: false,
                sectionNamesList: action.payload,
                error: '',
            };
        case LOAD_OLD_TEMPLATE_SECTION_ERROR:
            return {
                ...state,
                sectionsLoading: false,
                sectionNamesList: [],
                error: action.payload.message,
            };
        case RESET_TEMPLATE_ERROR_VALUES:
            return {
                ...state,
                error: '',
            };
        case RESET_TEMPLATE_SAVE_VALUES:
            return {
                ...state,
                saveError: {},
                editError: {},
                success: false,
                reorderError: '',
                saveSuccess: false,
                addSectionError: '',
                editSectionError: '',
                deleteSectionError: '',
                addSectionSuccess: false,
                deleteSectionSuccess: '',
                editSectionSuccess: false,
            };
        case RESET_ADD_TEMPLATE_VALUES:
            return {
                ...state,
                success: false,
            };
        case RESET_ADD_TEMPLATE_ERROR_VALUES:
            return {
                ...state,
                addError: {},
            };
        case RESET_DELETE_TEMPLATE_ERROR_VALUES:
            return {
                ...state,
                deleteError: '',
            };
        case RESET_DELETE_TEMPLATE_SUCCESS_VALUES:
            return {
                ...state,
                deleteSuccess: '',
            };
        case RESET_DUPLICATE_TEMPLATE_ERROR_VALUES:
            return {
                ...state,
                duplicateError: {},
            };
        case RESET_DUPLICATE_TEMPLATE_SUCCESS_VALUES:
            return {
                ...state,
                success: false,
            };
        case CHANGE_SELECTED_TEMPLATES_STATE:
            return {
                ...state,
                loading: true,
                changeStateSuccess: '',
                changeStateError: '',
            };
        case CHANGE_SELECTED_TEMPLATES_STATE_SUCCESS:
            return {
                ...state,
                loading: false,
                changeStateSuccess: action.payload,
            };
        case CHANGE_SELECTED_TEMPLATES_STATE_ERROR:
            return {
                ...state,
                changeStateError: action.payload,
            };
        case LOAD_STANDARDS_LIST:
            return {
                ...state,
                error: '',
                standardList: [],
            };
        case LOAD_STANDARDS_LIST_SUCCESS:
            return {
                ...state,
                standardList: action.payload,
                error: '',
            };
        case LOAD_STANDARDS_LIST_ERROR:
            return {
                ...state,
                standardList: [],
                error: action.payload.message,
            };
        case LIST_RISKS:
            return {
                ...state,
                error: '',
                risksList: [],
            };
        case LIST_RISKS_ERROR:
            return {
                ...state,
                risksList: [],
                error: action.payload.message,
            };
        case LIST_RISKS_SUCCESS:
            return {
                ...state,
                error: '',
                risksList: action.payload,
            };
        case LIST_THREATS_TEMP:
            return {
                ...state,
                error: '',
                threatsList: [],
            };
        case LIST_THREATS_ERROR_TEMP:
            return {
                ...state,
                threatsList: [],
                error: action.payload.message,
            };
        case LIST_THREATS_SUCCESS_TEMP:
            return {
                ...state,
                error: '',
                threatsList: action.payload,
            };
        case GET_BASIC_TEMPLATE:
            return {
                ...state,
                error: '',
                loading: true,
                tempData: {},
            };
        case GET_BASIC_TEMPLATE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_BASIC_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                tempData: action.payload,
            };
        case EDIT_BASIC_TEMPLATE:
            return {
                ...state,
                editing: true,
                editError: {},
                success: false,
            };
        case EDIT_BASIC_TEMPLATE_ERROR:
            return {
                ...state,
                editing: false,
                editError: action.payload,
            };
        case EDIT_BASIC_TEMPLATE_SUCCESS:
            return {
                ...state,
                editing: false,
                success: true,
                editError: {},
                tempData: action.payload,
            };
        case LOAD_SECTION_DETAILS:
            return {
                ...state,
                error: '',
                sectionData: {},
                sectionLoading: true,
            };
        case LOAD_SECTION_DETAILS_ERROR:
            return {
                ...state,
                sectionLoading: false,
                error: action.payload,
            };
        case LOAD_SECTION_DETAILS_SUCCESS:
            return {
                ...state,
                sectionLoading: false,
                sectionData: action.payload,
            };
        case ADD_SINGLE_SECTION:
            return {
                ...state,
                addSectionError: '',
                sectionAdding: true,
                addSectionSuccess: false,
            };
        case ADD_SINGLE_SECTION_ERROR:
            return {
                ...state,
                sectionAdding: false,
                addSectionSuccess: false,
                addSectionError: action.payload.message,
            };
        case ADD_SINGLE_SECTION_SUCCESS:
            return {
                ...state,
                addSectionError: '',
                sectionAdding: false,
                addSectionSuccess: true,
                recentlyAddedSection: action.payload,
            };
        case EDIT_SINGLE_SECTION:
            return {
                ...state,
                editSectionError: '',
                sectionEditing: true,
                editSectionSuccess: false,
            };
        case EDIT_SINGLE_SECTION_ERROR:
            return {
                ...state,
                sectionEditing: false,
                editSectionSuccess: false,
                editSectionError: action.payload.message,
            };
        case EDIT_SINGLE_SECTION_SUCCESS:
            return {
                ...state,
                editSectionError: '',
                sectionEditing: false,
                editSectionSuccess: true,
            };
        case SAVE_NEW_TEMPLATE:
            return {
                ...state,
                saving: true,
                saveError: {},
                saveSuccess: false,
            };
        case SAVE_NEW_TEMPLATE_SUCCESS:
            return {
                ...state,
                saving: false,
                saveSuccess: true,
                openTemplate: action.payload,
            };
        case SAVE_NEW_TEMPLATE_ERROR:
            return {
                ...state,
                saving: false,
                saveSuccess: false,
                saveError: action.payload,
            };
        case GET_TEMP_DATA_FOR_REORDERING:
            return {
                ...state,
                reorderError: '',
                reorderLoading: true,
                tempDataForReordering: {},
            };
        case GET_TEMP_DATA_FOR_REORDERING_ERROR:
            return {
                ...state,
                reorderLoading: false,
                reorderError: action.payload,
            };
        case GET_TEMP_DATA_FOR_REORDERING_SUCCESS:
            return {
                ...state,
                reorderLoading: false,
                tempDataForReordering: action.payload,
            };
        case REORDER_TEMPLATE_DATA:
            return {
                ...state,
                reorderDataError: '',
                reorderDataLoading: true,
                reorderDataSuccess: false,
            };
        case REORDER_TEMPLATE_DATA_ERROR:
            return {
                ...state,
                reorderDataLoading: false,
                reorderDataSuccess: false,
                reorderDataError: action.payload.message,
            };
        case REORDER_TEMPLATE_DATA_SUCCESS:
            return {
                ...state,
                reorderDataError: '',
                reorderDataLoading: false,
                reorderDataSuccess: true,
            };
        case DELETE_SINGLE_SECTION:
            return {
                ...state,
                deleteSection: true,
                deleteSectionError: '',
                deleteSectionSuccess: '',
            };
        case DELETE_SINGLE_SECTION_ERROR:
            return {
                ...state,
                deleteSection: false,
                deleteSectionError: action.payload,
                deleteSectionSuccess: '',
            };
        case DELETE_SINGLE_SECTION_SUCCESS:
            return {
                ...state,
                deleteSection: false,
                deleteSectionError: '',
                deleteSectionSuccess: action.payload,
            };
        case RESET_REORDER_MESSAGES:
            return {
                ...state,
                reorderDataError: '',
                reorderDataLoading: false,
                reorderDataSuccess: false,
            };
        case RESET_EDIT_AND_ADD_SECTION_MESSAGES:
            return {
                ...state,
                editing: false,
                editError: {},
                success: false,
                addSectionError: '',
                sectionAdding: false,
                addSectionSuccess: false,
                editSectionError: '',
                sectionEditing: false,
                editSectionSuccess: false,
                saving: false,
                saveError: {},
                saveSuccess: false,
            };
        case GET_BASIC_TEMPLATE_ERROR_WHILE_ADDING:
            return {
                ...state,
                error: action.payload,
            };
        case GET_BASIC_TEMPLATE_SUCCESS_WHILE_ADDING:
            return {
                ...state,
                tempData: action.payload,
            };
        case DOWNLOAD_TEMPLATE_FILE:
            return {
                ...state,
                downloadSampleTemplateFile: true,
            };
        case DOWNLOAD_TEMPLATE_FILE_ERROR:
            return {
                ...state,
                downloadSampleTemplateFile: false,
            };
        case DOWNLOAD_TEMPLATE_FILE_SUCCESS:
            return {
                ...state,
                downloadSampleTemplateFile: false,
            };
        case UPDATE_TEMP_DETAILS:
            return {
                ...state,
                updatingTemp: true,
                updatingTempError: {},
                updatingTempsuccess: false,
            };
        case UPDATE_TEMP_DETAILS_ERROR:
            return {
                ...state,
                updatingTemp: false,
                updatingTempError: action.payload,
            };
        case UPDATE_TEMP_DETAILS_SUCCESS:
            return {
                ...state,
                updatingTemp: false,
                updatingTempsuccess: true,
                updatingTempError: {},
                tempData: action.payload,
            };
        case IMPORT_SECTION_FROM_OTHER_TEMPLATE:
            return {
                ...state,
                // loading: true,
                importSecError: '',
                importSecSuccess: {},
            };
        case IMPORT_SECTION_FROM_OTHER_TEMPLATE_ERROR:
            return {
                ...state,
                importSecSuccess: {},
                importSecError: action.payload.message,
            };
        case IMPORT_SECTION_FROM_OTHER_TEMPLATE_SUCCESS:
            return {
                ...state,
                importSecError: '',
                importSecSuccess: action.payload,
            };
        default:
            return state;
    }
};
