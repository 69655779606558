import {
  createStore,
  applyMiddleware,
  // compose
} from 'redux';
// import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
// import persistedReducer from './reducers';
import { persistStore, persistReducer } from "redux-persist";
import reducers from './reducers';
import storage from 'redux-persist/lib/storage';
// import sagas from './sagas';

// const sagaMiddleware = createSagaMiddleware();

// const middlewares = [sagaMiddleware];
const middlewares = [thunk];

const persistConfig = {
  key: 'ASSYST_aristiun_root',
  storage: storage,
  whitelist: ["auth"]
}

const persistedReducer = persistReducer(persistConfig, reducers);

// export default function configureStore(initialState = {}) {
//   const store = createStore(
//     reducers,
//     initialState,
//     compose(applyMiddleware(...middlewares))
//   );

//   // sagaMiddleware.run(sagas, {});

//   if ((module as any).hot) {
//     (module as any).hot.accept('./reducers', () => {
//       const nextRootReducer = require('./reducers');
//       store.replaceReducer(nextRootReducer);
//     });
//   }

//   return store;
// }

export default () => {
  const store = createStore(persistedReducer, applyMiddleware(...middlewares));
  const persistor = persistStore(store);
  return {
    store,
    persistor
  };
};
