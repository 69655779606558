import { CHANGE_LOCALE, SET_SETTINGS } from '../types';
import { getCurrentLanguage } from '../../helpers/Utils';

const INIT_STATE = {
  locale: getCurrentLanguage(),
  settings: null
};

export default (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload };
    case SET_SETTINGS:
      return { ...state, settings: action.payload };

    default:
      return state;
  }
};
