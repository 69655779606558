import { combineReducers } from 'redux';
import app from './app/reducer';
import auth from './auth/reducer';
import menu from './menu/reducer';
import templates from './templates/reducer';
import settings from './settings/reducer';
import users from './users/reducer';
import assessments from './assessment/reducer';
import asset from './asset/reducer';
import publicAssessments from './publicAssessment/reducer';
import dashboard from './dashboard/reducer';
import standards from './standards/reducer';
import controls from './controls/reducer';
import risks from './risks/reducer';
import comments from './comments/reducer';
import policies from './policies/reducer';
import threats from './threats/reducer';
import activities from './activities/reducer';
// import storage from 'redux-persist/lib/storage';
// import { persistReducer } from 'redux-persist';
//
// const usersPersistConfig = {
//   key: 'users',
//   storage,
//   blacklist: ['error']
// }

const reducers = combineReducers({
    app,
    auth,
    menu,
    settings,
    templates,
    users, //: persistReducer(usersPersistConfig, users),
    assessments,
    asset,
    comments,
    publicAssessments,
    dashboard,
    standards,
    controls,
    risks,
    policies,
    threats,
    activities,
});

export default reducers;
