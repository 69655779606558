import {
    LOAD_ASSESSMENT,
    LOAD_ASSESSMENT_ERROR,
    LOAD_ASSESSMENT_SUCCESS,
    ADD_ASSESSMENT,
    ADD_ASSESSMENT_SUCCESS,
    ADD_ASSESSMENT_ERROR,
    LIST_ASSESSMENT_ERROR,
    LIST_ASSESSMENT_SUCCESS,
    LIST_ASSESSMENT,
    EDIT_INVITATION_SUCCESS,
    LIST_INVITATION,
    LIST_INVITATION_SUCCESS,
    LIST_INVITATION_ERROR,
    ADD_INVITATION_LIST,
    ADD_INVITATION_SUCCESS,
    ADD_INVITATION_PEOPLE_ERROR,
    LIST_USER_COMPLETED_ASSESSMENT,
    USER_COMPLETED_ASSESSMENT_SUCCESS,
    USER_COMPLETED_ASSESSMENT_ERROR,
    LIST_USER_PENDING_ASSESSMENT,
    USER_PENDING_ASSESSMENT_SUCCESS,
    USER_PENDING_ASSESSMENT_ERROR,
    DETAILS_COMPLETED_ASSESSMENTS,
    DETAILS_COMPLETED_SUCCESS,
    DETAILS_COMPLETED_ERROR,
    SELECTED_ASSESSMENT_CHANGE,
    RESET_INVITATION_VALUES,
    RESET_ERROR_VALUES,
    RESET_USER_INVITATION_ERROR_VALUES,
    RESET_USER_INVITATION_SUCCESS_VALUES,
    RESET_ASSESSMENT_NOT_PUBLISHED_VALUES,
    RESET_ASSESSMENT_PUBLISHED_SUCCESS_VALUES,
    LIST_USER_PENDING_REVIEW,
    USER_PENDING_REVIEW_SUCCESS,
    USER_PENDING_REVIEW_ERROR,
    LIST_USER_COMPLETED_REVIEWS,
    USER_COMPLETED_REVIEW_SUCCESS,
    USER_COMPLETED_REVIEW_ERROR,
    GET_COMPARISON_REPORTS_LIST,
    GET_COMPARISON_REPORTS_LIST_ERROR,
    GET_COMPARISON_REPORTS_LIST_SUCCESS,
    SELECTED_USER_ASSESSMENTS_FOR_COMPARISON,
    LOAD_ALL_RESPONSES,
    LOAD_ALL_RESPONSES_ERROR,
    LOAD_ALL_RESPONSES_SUCCESS,
    COPY_USER_INVITATION,
    COPY_USER_INVITATION_ERROR,
    COPY_USER_INVITATION_SUCCESS,
    DELETE_INVITE_RESPONSE,
    DELETE_INVITE_RESPONSE_ERROR,
    DELETE_INVITE_RESPONSE_SUCCESS,
    REASSIGN_RESOURCE,
    REASSIGN_RESOURCE_ERROR,
    REASSIGN_RESOURCE_SUCCESS,
    UPDATE_APPLICABLE_DATE,
    UPDATE_APPLICABLE_DATE_ERROR,
    UPDATE_APPLICABLE_DATE_SUCCESS,
    LOAD_INVITED_USER_ASSESSMENT_SECTION,
    LOAD_INVITED_USER_ASSESSMENT_SECTION_ERROR,
    LOAD_INVITED_USER_ASSESSMENT_SECTION_SUCCESS,
    UPDATE_SECTION_DATA,
    UPDATE_SECTION_DATA_ERROR,
    UPDATE_SECTION_DATA_SUCCESS,
    LOAD_SECTIONS_OF_ASSESSMENT,
    LOAD_SECTIONS_OF_ASSESSMENT_ERROR,
    LOAD_SECTIONS_OF_ASSESSMENT_SUCCESS,
    ADD_INVITATION_PEOPLE_SEC_ERROR,
    UPDATE_REPORT_CONTENTS,
    UPDATE_REPORT_CONTENTS_ERROR,
    UPDATE_REPORT_CONTENTS_SUCCESS,
    ASSESSMENT_CONFIGURATION,
    ASSESSMENT_CONFIGURATION_ERROR,
    ASSESSMENT_CONFIGURATION_SUCCESS,
    LIST_All_REVIEWS,
    ALL_REVIEW_SUCCESS,
    ALL_REVIEW_ERROR,
    SET_ASSESSMENTS_FOR_IMPORT,
    ASSESSMENT_IMPORT_ERROR,
    ASSESSMENT_TOGGLE_LOCK,
    ASSESSMENT_TOGGLE_LOCK_ERROR,
    ASSESSMENT_TOGGLE_LOCK_SUCCESS,
} from '../types';

const INIT_STATE = {
    list: [],
    completedList: [],
    assessmentsForImport: [],
    pendingList: [],
    loading: false,
    assessmentImportError: '',
    error: '',
    selected: [],
    publishing: false,
    publishSuccess: false,
    publishError: {},
    invitationListError: {},
    invitationSuccess: false,
    deleteSuccess: '',
    deleteError: '',
    openAssessment: {},
    selectCompleted: [],
    adding: false,
    success: false,
    openCompletedAssessment: {},
    editing: false,
    invitationList: [],
    inviting: false,
    publishedAssessmentId: 0,
    pendingReviewList: [],
    completedReviewList: [],
    comparisonReports: [],
    selectedUserAssessments: [],
    allResponses: [],
    copyUserInviteError: '',
    copyUserInviteLoading: false,
    copyUserInviteSuccess: false,
    deleteInviteSuccess: null,
    reAssignLoading: false,
    updateApplicableDateError: '',
    updateApplicableDateLoading: false,
    updateApplicableDateSuccess: {},
    loadingSectionData: false,
    invitedSectionDataError: '',
    invitedSectionDataSuccess: {},
    updating: false,
    updatingError: '',
    updatingSuccess: {},
    sectionsList: [],
    allReviewsList: [],
    listSectionError: '',
    listSectionLoading: false,
    invitationListSecError: '',
    updateReportContentLoading: false,
    updateReportContentError: '',
    updateReportContentSuccess: {},
    reassignError: '',
    reassignSuccess: false,
    assessmentConfigureLoading: false,
    assessmentConfigureError: '',
    assessmentConfigureData: {},
    assessmentToggleLockError: '',
    assessmentToggleLockSuccess: '',
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SELECTED_ASSESSMENT_CHANGE:
            return {
                ...state,
                selected: action.payload,
            };
        case LIST_ASSESSMENT:
            return {
                ...state,
                loading: true,
                error: '',
                list: [],
                selected: [],
                deleteSuccess: '',
            };
        case LIST_ASSESSMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.payload,
                error: '',
            };
        case LIST_ASSESSMENT_ERROR:
            return {
                ...state,
                loading: false,
                list: [],
                error: action.payload.message,
            };
        case ADD_ASSESSMENT:
            return {
                ...state,
                publishing: true,
                publishError: {},
                publishSuccess: false,
                publishedAssessmentId: 0,
            };
        case ADD_INVITATION_LIST:
            return {
                ...state,
                inviting: true,
                invitationListError: {},
                invitationSuccess: false,
            };
        case ADD_INVITATION_SUCCESS:
            return {
                ...state,
                inviting: false,
                invitationSuccess: true,
                invitationList: [
                    ...action.payload,
                    ...state.invitationList,
                ],
            };
        case EDIT_INVITATION_SUCCESS: {
            const newList = state.invitationList.map((invite: any) => {
                if(invite.id !== action.payload.id) {
                    return invite
                }

                return ({
                    ...invite,
                    ...action.payload
                });
            })

            return {
                ...state,
                inviting: false,
                invitationSuccess: true,
                invitationList: newList,
            };
        }
        case ADD_INVITATION_PEOPLE_ERROR:
            return {
                ...state,
                inviting: false,
                invitationSuccess: false,
                invitationListError: action.payload.message,
            };
        case ADD_INVITATION_PEOPLE_SEC_ERROR:
            return {
                ...state,
                inviting: false,
                invitationSuccess: false,
                invitationListSecError: action.payload,
            };
        case ADD_ASSESSMENT_SUCCESS:
            return {
                ...state,
                publishing: false,
                publishSuccess: true,
                publishedAssessmentId: action.payload,
            };
        case ADD_ASSESSMENT_ERROR:
            return {
                ...state,
                publishSuccess: false,
                publishing: false,
                publishError: action.payload.message,
            };
        case LOAD_ASSESSMENT:
            return {
                ...state,
                loading: true,
                openAssessment: {},
                error: '',
            };
        case LOAD_ASSESSMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                openAssessment: action.payload,
            };
        case LOAD_ASSESSMENT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case LIST_INVITATION:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case LIST_USER_COMPLETED_ASSESSMENT:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case USER_COMPLETED_ASSESSMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                completedList: action.payload,
                error: '',
            };
        case USER_COMPLETED_ASSESSMENT_ERROR:
            return {
                ...state,
                loading: false,
                completedList: [],
                error: action.payload.message,
            };
        case LIST_USER_PENDING_ASSESSMENT:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case LIST_INVITATION_SUCCESS:
            return {
                ...state,
                loading: false,
                invitationList: action.payload,
                error: '',
            };
        case SET_ASSESSMENTS_FOR_IMPORT:
            return {
                ...state,
                assessmentsForImport: action.payload,
            };
        case LIST_INVITATION_ERROR:
            return {
                ...state,
                loading: false,
                invitationList: [],
                error: action.payload.message,
            };
        case ASSESSMENT_IMPORT_ERROR:
            return {
                ...state,
                assessmentImportError: action.payload.message,
            };
        case USER_PENDING_ASSESSMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                pendingList: action.payload,
                error: '',
            };
        case USER_PENDING_ASSESSMENT_ERROR:
            return {
                ...state,
                loading: false,
                pendingList: [],
                error: action.payload.message,
            };
        case DETAILS_COMPLETED_ASSESSMENTS:
            return {
                ...state,
                editing: true,
                editError: {},
                success: false,
            };
        case DETAILS_COMPLETED_SUCCESS:
            return {
                ...state,
                editing: false,
                success: true,
                editError: {},
                openCompletedAssessment: action.payload,
            };
        case DETAILS_COMPLETED_ERROR:
            return {
                ...state,
                editing: false,
                editError: action.payload,
            };
        case RESET_INVITATION_VALUES:
            return {
                ...state,
                error: '',
                inviting: false,
                invitationSuccess: false,
                invitationListError: '',
                copyUserInviteLoading: false,
                copyUserInviteSuccess: false,
                copyUserInviteError: '',
                deleteInviteSuccess: null,
                reassignError: '',
                reAssignLoading: false,
                reassignSuccess: false,
                updateApplicableDateError: '',
                updateApplicableDateLoading: false,
                updateApplicableDateSuccess: {},
                updating: false,
                updatingError: '',
                updatingSuccess: {},
                invitationListSecError: '',
                loading: false,
                updateReportContentLoading: false,
                updateReportContentError: '',
                updateReportContentSuccess: {},
                assessmentConfigureLoading: false,
                assessmentConfigureError: '',
                assessmentConfigureData: {},
            };
        case RESET_ERROR_VALUES:
            return {
                ...state,
                error: '',
            };
        case RESET_ASSESSMENT_NOT_PUBLISHED_VALUES:
            return {
                ...state,
                publishError: {},
            };
        case RESET_ASSESSMENT_PUBLISHED_SUCCESS_VALUES:
            return {
                ...state,
                publishSuccess: false,
            };
        case LIST_All_REVIEWS:
        case LIST_USER_PENDING_REVIEW:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case USER_PENDING_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                pendingReviewList: action.payload,
                error: '',
            };
        case ALL_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                allReviewsList: action.payload,
                error: '',
            };
        case ALL_REVIEW_ERROR:
            return {
                ...state,
                loading: false,
                completedReviewList: [],
                error: action.payload.message,
            };
        case USER_PENDING_REVIEW_ERROR:
            return {
                ...state,
                loading: false,
                pendingReviewList: [],
                error: action.payload.message,
            };
        case LIST_USER_COMPLETED_REVIEWS:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case USER_COMPLETED_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                completedReviewList: action.payload,
                error: '',
            };
        case USER_COMPLETED_REVIEW_ERROR:
            return {
                ...state,
                loading: false,
                completedReviewList: [],
                error: action.payload.message,
            };
        case GET_COMPARISON_REPORTS_LIST:
            return {
                ...state,
                error: '',
                loading: true,
                comparisonReports: [],
            };
        case GET_COMPARISON_REPORTS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                comparisonReports: [],
                error: action.payload.message,
            };
        case GET_COMPARISON_REPORTS_LIST_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                comparisonReports: action.payload,
            };
        /* SELECTED USER ASSESSMENTS FOR COMPARISON STARTS HERE*/
        case SELECTED_USER_ASSESSMENTS_FOR_COMPARISON:
            return {
                ...state,
                selectedUserAssessments: action.payload,
            };
        /* SELECTED USER ASSESSMENTS FOR COMPARISON ENDS HERE*/
        case LOAD_ALL_RESPONSES:
            return {
                ...state,
                error: '',
                loading: true,
                allResponses: [],
            };
        case LOAD_ALL_RESPONSES_ERROR:
            return {
                ...state,
                loading: false,
                allResponses: [],
                error: action.payload.message,
            };
        case LOAD_ALL_RESPONSES_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                allResponses: action.payload,
            };
        case COPY_USER_INVITATION:
            return {
                ...state,
                copyUserInviteLoading: true,
                copyUserInviteSuccess: false,
                copyUserInviteError: '',
            };
        case COPY_USER_INVITATION_ERROR:
            return {
                ...state,
                invitationList: [],
                copyUserInviteLoading: false,
                copyUserInviteSuccess: false,
                copyUserInviteError: action.payload.message,
            };
        case COPY_USER_INVITATION_SUCCESS:
            return {
                ...state,
                copyUserInviteError: '',
                copyUserInviteLoading: false,
                copyUserInviteSuccess: true,
                invitationList: [
                    action.payload,
                    ...state.invitationList,
                ],
            };
        case DELETE_INVITE_RESPONSE:
            return {
                ...state,
                error: '',
                loading: true,
                deleteInviteSuccess: null,
            };
        case DELETE_INVITE_RESPONSE_ERROR:
            return {
                ...state,
                loading: false,
                deleteInviteSuccess: null,
                error: action.payload,
            };
        case DELETE_INVITE_RESPONSE_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                deleteInviteSuccess: action.payload,
            };
        case REASSIGN_RESOURCE:
            return {
                ...state,
                reassignError: '',
                reAssignLoading: true,
                reassignSuccess: false,
            };
        case REASSIGN_RESOURCE_ERROR:
            return {
                ...state,
                reAssignLoading: false,
                reassignSuccess: false,
                reassignError: action.payload,
            };
        case REASSIGN_RESOURCE_SUCCESS:
            return {
                ...state,
                reassignError: '',
                reAssignLoading: false,
                reassignSuccess: true,
                invitationList: action.payload,
            };
        case UPDATE_APPLICABLE_DATE:
            return {
                ...state,
                updateApplicableDateError: '',
                updateApplicableDateSuccess: {},
                updateApplicableDateLoading: true,
            };
        case UPDATE_APPLICABLE_DATE_ERROR:
            return {
                ...state,
                updateApplicableDateSuccess: {},
                updateApplicableDateLoading: false,
                updateApplicableDateError: action.payload,
            };
        case UPDATE_APPLICABLE_DATE_SUCCESS:
            return {
                ...state,
                updateApplicableDateError: '',
                updateApplicableDateLoading: false,
                updateApplicableDateSuccess: action.payload,
                invitationList: action.payload,
            };
        case LOAD_INVITED_USER_ASSESSMENT_SECTION:
            return {
                ...state,
                loadingSectionData: true,
                invitedSectionDataError: '',
                invitedSectionDataSuccess: {},
            };
        case LOAD_INVITED_USER_ASSESSMENT_SECTION_ERROR:
            return {
                ...state,
                loadingSectionData: false,
                invitedSectionDataError: action.payload,
            };
        case LOAD_INVITED_USER_ASSESSMENT_SECTION_SUCCESS:
            return {
                ...state,
                loadingSectionData: false,
                invitedSectionDataSuccess: action.payload,
            };
        case UPDATE_SECTION_DATA:
            return {
                ...state,
                updating: true,
                updatingError: '',
                updatingSuccess: {},
            };
        case UPDATE_SECTION_DATA_ERROR:
            return {
                ...state,
                updating: {},
                updatingError: action.payload,
            };
        case UPDATE_SECTION_DATA_SUCCESS:
            return {
                ...state,
                updating: false,
                updatingError: '',
                updatingSuccess: action.payload,
            };
        case LOAD_SECTIONS_OF_ASSESSMENT:
            return {
                ...state,
                sectionsList: [],
                listSectionError: '',
                listSectionLoading: true,
            };
        case LOAD_SECTIONS_OF_ASSESSMENT_ERROR:
            return {
                ...state,
                sectionsList: [],
                listSectionLoading: false,
                listSectionError: action.payload.message,
            };
        case LOAD_SECTIONS_OF_ASSESSMENT_SUCCESS:
            return {
                ...state,
                listSectionError: '',
                listSectionLoading: false,
                sectionsList: action.payload,
            };
        case UPDATE_REPORT_CONTENTS:
            return {
                ...state,
                updateReportContentError: '',
                updateReportContentSuccess: {},
                updateReportContentLoading: true,
            };
        case UPDATE_REPORT_CONTENTS_ERROR:
            return {
                ...state,
                updateReportContentSuccess: {},
                updateReportContentLoading: false,
                updateReportContentError: action.payload.message,
            };
        case UPDATE_REPORT_CONTENTS_SUCCESS:
            return {
                ...state,
                updateReportContentLoading: false,
                updateReportContentError: '',
                updateReportContentSuccess: action.payload,
            };
        case ASSESSMENT_CONFIGURATION:
            return {
                ...state,
                assessmentConfigureError: '',
                assessmentConfigureData: {},
                assessmentConfigureLoading: true,
            };
        case ASSESSMENT_CONFIGURATION_ERROR:
            return {
                ...state,
                assessmentConfigureData: {},
                assessmentConfigureLoading: false,
                assessmentConfigureError: action.payload.message,
            };
        case ASSESSMENT_CONFIGURATION_SUCCESS:
            return {
                ...state,
                assessmentConfigureError: '',
                assessmentConfigureLoading: false,
                assessmentConfigureData: action.payload,
            };
        case ASSESSMENT_TOGGLE_LOCK:
            return {
                ...state,
                assessmentToggleLockError: '',
                assessmentToggleLockSuccess: '',
                loading: true,
            };
        case ASSESSMENT_TOGGLE_LOCK_ERROR:
            return {
                ...state,
                loading: false,
                assessmentToggleLockSuccess: '',
                assessmentToggleLockError: action.payload,
            };
        case ASSESSMENT_TOGGLE_LOCK_SUCCESS:
            return {
                ...state,
                loading: false,
                assessmentToggleLockError: '',
                assessmentToggleLockSuccess: action.payload,
                invitationList: action.payload,
            };
        default:
            return state;
    }
};
