import {
    LOAD_CONTROLS,
    LOAD_CONTROLS_ERROR,
    LOAD_CONTROLS_SUCCESS,
    ADD_CONTROL,
    ADD_CONTROL_ERROR,
    ADD_CONTROL_SUCCESS,
    SELECTED_CONTROLS_CHANGE,
    DELETE_SELECTED_CONTROLS,
    DELETE_SELECTED_CONTROLS_ERROR,
    DELETE_SELECTED_CONTROLS_SUCCESS,
    LOAD_CONTROL,
    LOAD_CONTROL_ERROR,
    LOAD_CONTROL_SUCCESS,
    UPDATE_CONTROL,
    UPDATE_CONTROL_SUCCESS,
    UPDATE_CONTROL_ERROR,
    LOAD_CONTROL_OBJECTIVE,
    LOAD_CONTROL_OBJECTIVE_ERROR,
    LOAD_CONTROL_OBJECTIVE_SUCCESS,
    SELECTED_CONTROL_OBJECTIVE_CHANGE,
    ADD_CONTROL_OBJECTIVE,
    ADD_CONTROL_OBJECTIVE_ERROR,
    ADD_CONTROL_OBJECTIVE_SUCCESS,
    LOAD_SINGLE_CONTROL_OBJECTIVE,
    LOAD_SINGLE_CONTROL_OBJECTIVE_ERROR,
    LOAD_SINGLE_CONTROL_OBJECTIVE_SUCCESS,
    DELETE_SELECTED_CONTROL_OBJECTIVES,
    DELETE_SELECTED_CONTROL_OBJECTIVES_ERROR,
    DELETE_SELECTED_CONTROL_OBJECTIVES_SUCCESS,
    UPDATE_CONTROL_OBJECTIVE,
    UPDATE_CONTROL_OBJECTIVE_ERROR,
    UPDATE_CONTROL_OBJECTIVE_SUCCESS,
    ADD_CONTROL_APPROVE,
    ADD_CONTROL_APPROVE_ERROR,
    ADD_CONTROL_APPROVE_SUCCESS,
    RESET_APPROVE_MESSAGES,
    RESET_CONTROL_MESSAGES,
    RESET_CONTROL_OBJ_MESSAGES,
    LIST_CONTROL_REPORT,
    LIST_CONTROL_REPORT_ERROR,
    LIST_CONTROL_REPORT_SUCCESS,
    DELETE_CONTROL_REPORT,
    DELETE_CONTROL_REPORT_ERROR,
    DELETE_CONTROL_REPORT_SUCCESS,
    RESET_DELETE_CONTROL_REPORT_MESSAGE,
    DOWNLOAD_CONTROL_REPORT,
    DOWNLOAD_CONTROL_REPORT_ERROR,
    DOWNLOAD_CONTROL_REPORT_SUCCESS,
    LOAD_CONTROL_REPORT,
    LOAD_CONTROL_REPORT_ERROR,
    LOAD_CONTROL_REPORT_SUCCESS,
    UPDATE_RES_CONTROL_SUCCESS,
} from '../types';

const INIT_STATE = {
    list: [],
    loading: false,
    error: '',
    selected: [],
    adding: false,
    success: false,
    addError: {},
    deletedControlSuccess: '',
    deleteError: '',
    controlData: {},
    updating: false,
    updateError: false,
    updateSuccess: false,
    controlObjectiveList: [],
    selectedControlObjective: [],
    updateControlObjSuccess: false,
    updateControlObjError: false,
    controlApproved: '',
    controlApproveSuccess: false,
    controlApproveError: {},
    controlReportsList: [],
    deleteReportError: '',
    deleteReportSuccess: '',
    downloadControlReportSuccess: false,
    webCtrlReport: {},
    controlInfo: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_CONTROLS:
            return {
                ...state,
                loading: true,
                list: [],
                error: '',
            };
        case LOAD_CONTROLS_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.payload,
                error: '',
            };
        case LOAD_CONTROLS_ERROR:
            return {
                ...state,
                loading: false,
                list: [],
                error: action.payload.message,
            };
        case SELECTED_CONTROLS_CHANGE:
            return {
                ...state,
                selected: action.payload,
            };
        case DELETE_SELECTED_CONTROLS:
            return {
                ...state,
                loading: true,
                deletedControlSuccess: '',
                deleteError: '',
            };
        case DELETE_SELECTED_CONTROLS_ERROR:
            return {
                ...state,
                deleteError: action.payload,
            };
        case DELETE_SELECTED_CONTROLS_SUCCESS:
            return {
                ...state,
                loading: false,
                deletedControlSuccess: action.payload,
            };
        case ADD_CONTROL:
            return {
                ...state,
                adding: true,
                addError: {},
                success: false,
            };
        case ADD_CONTROL_SUCCESS:
            return {
                ...state,
                adding: false,
                success: true,
                addError: {},
                controlInfo: action.payload,
            };
        case ADD_CONTROL_ERROR:
            return {
                ...state,
                adding: false,
                success: false,
                addError: action.payload.message,
            };
        case LOAD_CONTROL:
            return {
                ...state,
                loading: true,
                controlData: {},
                error: '',
            };

        case LOAD_CONTROL_SUCCESS:
            return {
                ...state,
                loading: false,
                controlData: action.payload,
                error: '',
            };

        case LOAD_CONTROL_ERROR:
            return {
                ...state,
                loading: false,
                controlData: {},
                error: action.payload,
            };
        case UPDATE_CONTROL:
            return {
                ...state,
                updating: true,
                updateError: false,
                updateSuccess: false,
            };
        case UPDATE_CONTROL_SUCCESS:
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                updateError: false,
                list: action.payload,
            };
        case UPDATE_CONTROL_ERROR:
            return {
                ...state,
                updating: false,
                updateSuccess: false,
                updateError: action.payload.message,
            };
        case UPDATE_RES_CONTROL_SUCCESS:
            return {
                ...state,
                controlData: action.payload,
            };
        case LOAD_CONTROL_OBJECTIVE:
            return {
                ...state,
                loading: true,
                controlObjectiveList: [],
                error: '',
            };
        case LOAD_CONTROL_OBJECTIVE_ERROR:
            return {
                ...state,
                loading: false,
                controlObjectiveList: [],
                error: action.payload.message,
            };
        case LOAD_CONTROL_OBJECTIVE_SUCCESS:
            return {
                ...state,
                loading: false,
                controlObjectiveList: action.payload,
                error: '',
            };
        case SELECTED_CONTROL_OBJECTIVE_CHANGE:
            return {
                ...state,
                selectedControlObjective: action.payload,
            };
        case ADD_CONTROL_OBJECTIVE:
            return {
                ...state,
                adding: true,
                addError: {},
                success: false,
            };
        case ADD_CONTROL_OBJECTIVE_SUCCESS:
            return {
                ...state,
                adding: false,
                success: true,
                addError: {},
                controlObjectiveList: action.payload,
            };
        case ADD_CONTROL_OBJECTIVE_ERROR:
            return {
                ...state,
                adding: false,
                success: false,
                addError: action.payload.message,
            };
        case LOAD_SINGLE_CONTROL_OBJECTIVE:
            return {
                ...state,
                loading: true,
                controlObjectiveData: {},
                error: '',
            };

        case LOAD_SINGLE_CONTROL_OBJECTIVE_SUCCESS:
            return {
                ...state,
                loading: false,
                controlObjectiveData: action.payload,
                error: '',
            };

        case LOAD_SINGLE_CONTROL_OBJECTIVE_ERROR:
            return {
                ...state,
                loading: false,
                controlObjectiveData: {},
                error: action.payload,
            };
        case DELETE_SELECTED_CONTROL_OBJECTIVES:
            return {
                ...state,
                loading: true,
                deletedControlSuccess: '',
                deleteError: '',
            };
        case DELETE_SELECTED_CONTROL_OBJECTIVES_ERROR:
            return {
                ...state,
                deleteError: action.payload,
            };
        case DELETE_SELECTED_CONTROL_OBJECTIVES_SUCCESS:
            return {
                ...state,
                loading: false,
                deletedControlSuccess: action.payload,
            };
        case UPDATE_CONTROL_OBJECTIVE:
            return {
                ...state,
                updating: true,
                updateError: false,
                updateSuccess: false,
            };
        case UPDATE_CONTROL_OBJECTIVE_SUCCESS:
            return {
                ...state,
                updating: false,
                updateControlObjSuccess: true,
                updateControlObjError: false,
                controlObjectiveList: action.payload,
            };
        case UPDATE_CONTROL_OBJECTIVE_ERROR:
            return {
                ...state,
                updating: false,
                updateControlObjSuccess: false,
                updateControlObjError: action.payload.message,
            };
        case ADD_CONTROL_APPROVE:
            return {
                ...state,
                adding: true,
                controlApproveError: {},
                controlApproveSuccess: false,
            };
        case ADD_CONTROL_APPROVE_SUCCESS:
            return {
                ...state,
                adding: false,
                controlApproveError: {},
                controlApproveSuccess: true,
                controlApproved: action.payload,
            };
        case ADD_CONTROL_APPROVE_ERROR:
            return {
                ...state,
                adding: false,
                controlApproved: '',
                controlApproveSuccess: false,
                controlApproveError: action.payload.message,
            };
        case RESET_APPROVE_MESSAGES:
            return {
                ...state,
                adding: false,
                controlApproved: '',
                controlApproveSuccess: false,
                controlApproveError: '',
            };
        case RESET_CONTROL_MESSAGES:
            return {
                ...state,
                selected: [],
                loading: false,
                deleteError: '',
                deletedControlSuccess: '',
            };
        case RESET_CONTROL_OBJ_MESSAGES:
            return {
                ...state,
                selected: [],
                loading: false,
                deletedControlSuccess: '',
                updateControlObjSuccess: false,
                updateControlObjError: false,
            };
        case LIST_CONTROL_REPORT:
            return {
                ...state,
                error: '',
                loading: true,
                controlReportsList: [],
            };
        case LIST_CONTROL_REPORT_ERROR:
            return {
                ...state,
                loading: false,
                controlReportsList: [],
                error: action.payload.message,
            };
        case LIST_CONTROL_REPORT_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                controlReportsList: action.payload,
            };
        case DELETE_CONTROL_REPORT:
            return {
                ...state,
                loading: true,
                deleteReportError: '',
                deletedControlSuccess: '',
            };
        case DELETE_CONTROL_REPORT_ERROR:
            return {
                ...state,
                loading: false,
                deleteReportError: action.payload,
            };
        case DELETE_CONTROL_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteReportSuccess: action.payload,
            };
        case RESET_DELETE_CONTROL_REPORT_MESSAGE:
            return {
                ...state,
                loading: false,
                deleteReportError: '',
                deleteReportSuccess: '',
                deletedControlSuccess: '',
            };
        case DOWNLOAD_CONTROL_REPORT:
            return {
                ...state,
                downloadControlReportSuccess: true,
            };
        case DOWNLOAD_CONTROL_REPORT_ERROR:
            return {
                ...state,
                downloadControlReportSuccess: false,
            };
        case DOWNLOAD_CONTROL_REPORT_SUCCESS:
            return {
                ...state,
                downloadControlReportSuccess: false,
            };
        case LOAD_CONTROL_REPORT:
            return {
                ...state,
                error: '',
                loading: true,
                webCtrlReport: {},
            };
        case LOAD_CONTROL_REPORT_ERROR:
            return {
                ...state,
                loading: false,
                webCtrlReport: {},
                error: action.payload,
            };
        case LOAD_CONTROL_REPORT_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                webCtrlReport: action.payload,
            };
        default:
            return state;
    }
};
