import {
    LIST_THREATS,
    LIST_THREATS_ERROR,
    LIST_THREATS_SUCCESS,
    ADD_THREAT,
    ADD_THREAT_ERROR,
    ADD_THREAT_SUCCESS,
    RESET_THREAT_MESSAGES,
    LOAD_THREAT,
    LOAD_THREAT_ERROR,
    LOAD_THREAT_SUCCESS,
    DELETE_THREAT,
    DELETE_THREAT_ERROR,
    DELETE_THREAT_SUCCESS,
    UPDATE_THREAT,
    UPDATE_THREAT_ERROR,
    UPDATE_THREAT_SUCCESS,
    FILTER_THREAT,
    FILTER_THREAT_ERROR,
    FILTER_THREAT_SUCCESS,
    GET_RESOURCE_LIST_FOR_RISK_AND_THREATS,
    GET_RESOURCE_LIST_FOR_RISK_AND_THREATS_ERROR,
    GET_RESOURCE_LIST_FOR_RISK_AND_THREATS_SUCCESS,
} from '../types';

const INIT_STATE = {
    error: '',
    adding: false,
    loading: false,
    success: false,
    addError: {},
    threatData: {},
    threatsList: [],
    updateThreatError: false,
    deletedThreatSuccess: null,
    updateThreatSuccess: false,
    resourcesRiskList: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_THREATS:
            return {
                ...state,
                error: '',
                loading: true,
                threatsList: [],
            };
        case LIST_THREATS_ERROR:
            return {
                ...state,
                loading: false,
                threatsList: [],
                error: action.payload.message,
            };
        case LIST_THREATS_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                threatsList: action.payload,
            };
        case ADD_THREAT:
            return {
                ...state,
                adding: true,
                addError: {},
                success: false,
            };
        case ADD_THREAT_ERROR:
            return {
                ...state,
                adding: false,
                success: false,
                addError: action.payload.message,
            };
        case ADD_THREAT_SUCCESS:
            return {
                ...state,
                adding: false,
                success: action.payload,
                addError: {},
                threatsList: [
                    action.payload,
                    ...state.threatsList
                ],
            };
        case RESET_THREAT_MESSAGES:
            return {
                ...state,
                error: '',
                adding: false,
                loading: false,
                success: false,
                addError: false,
                threatData: {},
                threatsList: [],
                updateThreatError: false,
                deletedThreatSuccess: null,
                updateThreatSuccess: false,
            };
        case LOAD_THREAT:
            return {
                ...state,
                error: '',
                loading: true,
                threatData: {},
            };
        case LOAD_THREAT_ERROR:
            return {
                ...state,
                threatData: {},
                loading: false,
                error: action.payload,
            };
        case LOAD_THREAT_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                threatData: action.payload,
            };
        case DELETE_THREAT:
            return {
                ...state,
                error: '',
                loading: true,
                deletedThreatSuccess: '',
            };
        case DELETE_THREAT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                deletedThreatSuccess: '',
            };
        case DELETE_THREAT_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                deletedThreatSuccess: action.payload,
            };
        case UPDATE_THREAT:
            return {
                ...state,
                loading: true,
                updateThreatError: false,
                updateThreatSuccess: false,
            };
        case UPDATE_THREAT_ERROR:
            return {
                ...state,
                loading: false,
                updateThreatSuccess: false,
                updateThreatError: action.payload.message,
            };
        case UPDATE_THREAT_SUCCESS:
            return {
                ...state,
                loading: false,
                updateThreatError: false,
                updateThreatSuccess: action.payload,
                threatsList: [
                    action.payload,
                    ...state.threatsList
                ],
            };
        case FILTER_THREAT:
            return {
                ...state,
                error: '',
                loading: true,
                threatsList: [],
            };
        case FILTER_THREAT_ERROR:
            return {
                ...state,
                loading: false,
                threatsList: [],
                error: action.payload.message,
            };
        case FILTER_THREAT_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                threatsList: action.payload,
            };
        case GET_RESOURCE_LIST_FOR_RISK_AND_THREATS:
            return {
                ...state,
                error: '',
                loading: true,
                resourcesRiskList: [],
            };
        case GET_RESOURCE_LIST_FOR_RISK_AND_THREATS_ERROR:
            return {
                ...state,
                loading: false,
                resourcesRiskList: [],
                error: action.payload.message,
            };
        case GET_RESOURCE_LIST_FOR_RISK_AND_THREATS_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                resourcesRiskList: action.payload,
            };
        default:
            return state;
    }
};
