import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    SET_PASSWORD,
    SET_PASSWORD_SUCCESS,
    SET_PASSWORD_ERROR,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    VALIDATE_SET_PASSWORD_LINK,
    VALIDATE_SET_PASSWORD_LINK_ERROR,
    VALIDATE_SET_PASSWORD_LINK_SUCCESS,
    RESET_SET_PASSWORD_MESSAGES,
    REDIRECT_TO_URL_LOGIN_URL,
    REDIRECT_TO_URL_LOGIN_URL_ERROR,
    REDIRECT_TO_URL_LOGIN_URL_SUCCESS,
    RESET_REDIRECTION_DATA,
    GET_CALLBACK,
    GET_CALLBACK_ERROR,
    GET_CALLBACK_SUCCESS,
} from '../types';
import { UserRole } from '../../helpers/authHelper';
import { REHYDRATE } from 'redux-persist/lib/constants';
// import { getCurrentUser } from '../../helpers/Utils';
// import { isAuthGuardActive } from '../../constants/defaultValues';

const INIT_STATE = {
    currentUser: {},
    forgotUserMail: '',
    newPassword: '',
    resetPasswordCode: '',
    loading: false,
    error: '',
    validating: true,
    valid: false,
    activating: false,
    activationError: '',
    activationSuccess: false,
    setPasswordStatus: '',
    setPasswordStatusError: '',
    getLinkLoading: false,
    redirectUrlError: '',
    redirectUrlSuccess: '',
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true, error: '' };
        case LOGIN_USER_SUCCESS:
            const currentUser_login = action.payload;
            if (currentUser_login.user.is_staff) {
                currentUser_login.role = UserRole.Admin;
            } else if (currentUser_login.user.is_manager) {
                currentUser_login.role = UserRole.Manager;
            } else {
                currentUser_login.role = UserRole.User;
            }

            return {
                ...state,
                loading: false,
                currentUser: currentUser_login,
                error: '',
            };
        case LOGIN_USER_ERROR:
            return {
                ...state,
                loading: false,
                currentUser: {},
                error: action.payload.message,
            };
        case FORGOT_PASSWORD:
            return { ...state, loading: true, error: '' };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                forgotUserMail: action.payload,
                error: '',
            };
        case FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                forgotUserMail: '',
                error: action.payload.message,
            };
        case SET_PASSWORD:
            return {
                ...state,
                activating: true,
                activationErro: '',
                activationSuccess: false,
            };
        case SET_PASSWORD_SUCCESS:
            return {
                ...state,
                activating: false,
                activationSuccess: true,
            };
        case SET_PASSWORD_ERROR:
            return {
                ...state,
                activating: false,
                activationSuccess: false,
                activationError: action.payload,
            };
        case RESET_PASSWORD:
            return { ...state, loading: true, error: '' };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                newPassword: action.payload,
                resetPasswordCode: '',
                error: '',
            };
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                newPassword: '',
                resetPasswordCode: '',
                error: action.payload.message,
            };
        case CHANGE_PASSWORD:
            return { ...state, loading: true, error: '' };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                newPassword: action.payload,
                error: '',
            };
        case CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                newPassword: '',
                error: action.payload.message,
            };
        case REGISTER_USER:
            return { ...state, loading: true, error: '' };
        case REGISTER_USER_SUCCESS:
            const currentUser = action.payload;
            if (currentUser.user.is_staff) {
                currentUser.role = UserRole.Admin;
            } else if (currentUser.user.is_manager) {
                currentUser.role = UserRole.Manager;
            } else {
                currentUser.role = UserRole.User;
            }
            return {
                ...state,
                loading: false,
                currentUser,
                error: '',
            };
        case REGISTER_USER_ERROR:
            return {
                ...state,
                loading: false,
                currentUser: {},
                error: action.payload.message,
            };
        case LOGOUT_USER:
            return { ...state, currentUser: {}, error: '' };
        case REHYDRATE:
            let auth = {};
            if (action && action.payload && action.payload.auth) {
                auth = action.payload.auth;
            }
            return {
                ...state,
                ...auth,
                forgotUserMail: '',
                newPassword: '',
                resetPasswordCode: '',
                loading: false,
                error: '',
                validating: true,
                valid: false,
                activating: false,
                activationError: '',
                activationSuccess: false,
            };
        case VALIDATE_SET_PASSWORD_LINK:
            return {
                ...state,
                loading: true,
                setPasswordStatus: '',
                setPasswordStatusError: '',
            };
        case VALIDATE_SET_PASSWORD_LINK_ERROR:
            return {
                ...state,
                loading: false,
                setPasswordStatusError: action.payload.status,
            };
        case VALIDATE_SET_PASSWORD_LINK_SUCCESS:
            return {
                ...state,
                loading: false,
                setPasswordStatusError: '',
                setPasswordStatus: action.payload,
            };
        case RESET_SET_PASSWORD_MESSAGES:
            return {
                ...state,
                setPasswordStatus: '',
            };
        case REDIRECT_TO_URL_LOGIN_URL:
            return {
                ...state,
                getLinkLoading: true,
                redirectUrlError: '',
                redirectUrlSuccess: '',
            };
        case REDIRECT_TO_URL_LOGIN_URL_ERROR:
            return {
                ...state,
                getLinkLoading: false,
                redirectUrlSuccess: '',
                redirectUrlError: action.payload.message,
            };
        case REDIRECT_TO_URL_LOGIN_URL_SUCCESS:
            return {
                ...state,
                getLinkLoading: false,
                redirectUrlError: '',
                redirectUrlSuccess: action.payload,
            };
        case RESET_REDIRECTION_DATA:
            return {
                redirectUrlError: '',
                getLinkLoading: false,
                redirectUrlSuccess: '',
            };
        case GET_CALLBACK:
            return {
                ...state,
                error: '',
                loading: true,
            };
        case GET_CALLBACK_ERROR:
            return {
                ...state,
                loading: false,
                currentUser: {},
                error: action.payload.message,
            };
        case GET_CALLBACK_SUCCESS:
            const currentNewUser_login = action.payload;
            if (currentNewUser_login.user.is_staff) {
                currentNewUser_login.role = UserRole.Admin;
            } else if (currentNewUser_login.user.is_manager) {
                currentNewUser_login.role = UserRole.Manager;
            } else {
                currentNewUser_login.role = UserRole.User;
            }
            return {
                ...state,
                loading: false,
                currentUser: currentNewUser_login,
                error: '',
            };
        default:
            return state;
    }
};
