import {
    LIST_POLICIES,
    LIST_POLICIES_ERROR,
    LIST_POLICIES_SUCCESS,
    ADD_POLICY,
    ADD_POLICY_ERROR,
    ADD_POLICY_SUCCESS,
    LOAD_POLICY,
    LOAD_POLICY_ERROR,
    LOAD_POLICY_SUCCESS,
    DELETE_POLICY,
    DELETE_POLICY_ERROR,
    DELETE_POLICY_SUCCESS,
    UPDATE_POLICY,
    UPDATE_POLICY_ERROR,
    UPDATE_POLICY_SUCCESS,
    RESET_POLICIES_STATE,
    SELECTED_POLICIES_CONTENT,
    LIST_POLICIES_CONTENT,
    LIST_POLICIES_CONTENT_ERROR,
    LIST_POLICIES_CONTENT_SUCCESS,
    ADD_POLICY_CONTENT,
    ADD_POLICY_CONTENT_ERROR,
    ADD_POLICY_CONTENT_SUCCESS,
    LOAD_POLICY_CONTENT,
    LOAD_POLICY_CONTENT_ERROR,
    LOAD_POLICY_CONTENT_SUCCESS,
    DELETE_POLICY_CONTENT,
    DELETE_POLICY_CONTENT_ERROR,
    DELETE_POLICY_CONTENT_SUCCESS,
    UPDATE_POLICY_CONTENT,
    UPDATE_POLICY_CONTENT_ERROR,
    UPDATE_POLICY_CONTENT_SUCCESS,
} from '../types';

const INIT_STATE = {
    error: '',
    adding: false,
    success: false,
    loading: false,
    policyData: {},
    policiesList: [],
    deleteSuccess: '',
    policyContentData: {},
    policiesContentList: [],
    selectedPoliciesContent: [],
    updatePolicyContentSuccess: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_POLICIES_STATE:
            return {
                error: '',
                adding: false,
                success: false,
                loading: false,
                policyData: {},
                policiesList: [],
                deleteSuccess: '',
                policyContentData: {},
                policiesContentList: [],
                selectedPoliciesContent: [],
                updatePolicyContentSuccess: false,
            };
        case LIST_POLICIES:
            return {
                ...state,
                error: '',
                loading: true,
                policiesList: [],
            };
        case LIST_POLICIES_ERROR:
            return {
                ...state,
                loading: false,
                policiesList: [],
                error: action.payload.message,
            };
        case LIST_POLICIES_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                policiesList: action.payload,
            };
        case ADD_POLICY:
            return {
                ...state,
                error: '',
                adding: true,
                success: false,
            };
        case ADD_POLICY_ERROR:
            return {
                ...state,
                adding: false,
                success: false,
                error: action.payload.message,
            };
        case ADD_POLICY_SUCCESS:
            return {
                ...state,
                error: '',
                adding: false,
                success: action.payload,
                policiesList: [
                    action.payload,
                    ...state.policiesList
                ],
            };
        case LOAD_POLICY:
            return {
                ...state,
                error: '',
                loading: true,
                policyData: {},
            };
        case LOAD_POLICY_ERROR:
            return {
                ...state,
                loading: false,
                policyData: {},
                error: action.payload,
            };
        case LOAD_POLICY_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                policyData: action.payload,
            };
        case DELETE_POLICY:
            return {
                ...state,
                error: '',
                loading: true,
                deleteSuccess: '',
            };
        case DELETE_POLICY_ERROR:
            return {
                ...state,
                loading: false,
                deleteSuccess: '',
                error: action.payload,
            };
        case DELETE_POLICY_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                deleteSuccess: action.payload,
            };
        case UPDATE_POLICY:
            return {
                ...state,
                loading: true,
                error: '',
                updatePolicySuccess: false,
            };
        case UPDATE_POLICY_ERROR:
            return {
                ...state,
                loading: false,
                updatePolicySuccess: false,
                error: action.payload.message,
            };
        case UPDATE_POLICY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                updatePolicySuccess: action.payload,
                policiesList: [
                    action.payload,
                    ...state.policiesList
                ],
            };
        /*POLICIES CONTENT ACTIONS METHODS*/
        case LIST_POLICIES_CONTENT:
            return {
                ...state,
                error: '',
                loading: true,
                policiesContentList: [],
            };
        case LIST_POLICIES_CONTENT_ERROR:
            return {
                ...state,
                loading: false,
                policiesContentList: [],
                error: action.payload.message,
            };
        case LIST_POLICIES_CONTENT_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                policiesContentList: action.payload,
            };
        case ADD_POLICY_CONTENT:
            return {
                ...state,
                error: '',
                loading: true,
                success: false,
            };
        case ADD_POLICY_CONTENT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload.message,
            };
        case ADD_POLICY_CONTENT_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                success: true,
                policiesContentList: action.payload,
            };
        case LOAD_POLICY_CONTENT:
            return {
                ...state,
                error: '',
                loading: true,
                policyContentData: {},
            };
        case LOAD_POLICY_CONTENT_ERROR:
            return {
                ...state,
                loading: false,
                policyContentData: {},
                error: action.payload,
            };
        case LOAD_POLICY_CONTENT_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                policyContentData: action.payload,
            };
        case SELECTED_POLICIES_CONTENT:
            return {
                ...state,
                selectedPoliciesContent: action.payload,
            };
        case DELETE_POLICY_CONTENT:
            return {
                ...state,
                error: '',
                loading: true,
                deleteSuccess: '',
            };
        case DELETE_POLICY_CONTENT_ERROR:
            return {
                ...state,
                loading: false,
                deleteSuccess: '',
                error: action.payload,
            };
        case DELETE_POLICY_CONTENT_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                deleteSuccess: action.payload,
            };
        case UPDATE_POLICY_CONTENT:
            return {
                ...state,
                loading: true,
                error: '',
                updatePolicyContentSuccess: false,
            };
        case UPDATE_POLICY_CONTENT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.message,
                updatePolicyContentSuccess: false,
            };
        case UPDATE_POLICY_CONTENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                updatePolicyContentSuccess: true,
                policiesContentList: action.payload,
            };
        default:
            return state;
    }
};
