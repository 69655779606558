/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, ReactNode } from 'react';
import classnames from 'classnames';

const Notification = ({
  title = null,
  type = 'info',
  message = null,
  customClassName = '',
  timeOut = 5000,
  onClick = () => { },
  onRequestHide = () => { },
}: Props) => {
  const requestHide = () => {
    if (onRequestHide) {
      onRequestHide();
    }
  };

  useEffect(() => {
    let timer: any = null;
    if (timeOut !== 0) {
      timer = setTimeout(requestHide, timeOut);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    requestHide();
  };

  const className = classnames([
    'notification',
    `notification-${type}`,
    customClassName,
  ]);
  const titleHtml = title ? <h4 className="title">{title}</h4> : null;
  return (
    <div className={className} onClick={() => handleClick()}>
      <div className="notification-message" role="alert">
        {titleHtml}
        <div className="message">{typeof message === "string" ? message : message.message}</div>
      </div>
    </div>
  );
};

interface Props {
  type: 'info' | 'success' | 'warning' | 'error' | 'primary' | 'secondary';
  title: any;
  message: any;
  timeOut: number;
  onClick: () => void;
  onRequestHide: () => void;
  customClassName: '';
}

export default Notification;
