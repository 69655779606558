import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
// import { getCurrentUser } from './Utils';
import { isAuthGuardActive } from '../constants/defaultValues';
type Props = {
  component: any;
  currentUser: any;
  //  TODO: Handle roles
  roles?: Array<any>;
} & RouteProps;
const ProtectedRoute = (superProps: Props) => {
  const { component: Component, roles = undefined, currentUser, ...rest } = superProps;
  const setComponent = (props) => {
    if (isAuthGuardActive) {
      if (currentUser && currentUser.auth_token) {
        if (roles) {
          if (roles.includes(currentUser.role)) {
            return <Component {...props} />;
          }
          return (
            <Redirect
              to={{
                pathname: '/unauthorized',
                state: { from: props.location },
              }}
            />
          );
        }
        return <Component {...props} />;
      }
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      );
    }
    return <Component {...props} />;
  };

  return <Route {...rest} render={setComponent} />;
};
const UserRole = {
  User: 0,
  Manager: 1,
  Admin: 2,
};

export const UserRoles = [
  {label: 'Regular', value: 'is_user'},
  {label: 'Portfolio manager', value: 'is_manager'},
  {label: 'Admin', value: 'is_staff'},
];

export { ProtectedRoute, UserRole };
