import {
    SET_CONVERSATIONS
} from '../types';

const INIT_STATE = {
    conversations: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_CONVERSATIONS:
            return {
                ...state,
                conversations: action.payload,
            };

        default: return state;
    }
};
