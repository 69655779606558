/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME =
    'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES =
    'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
    'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* APP */
export const SET_IS_READY = 'SET_IS_READY';

/* COMMENTS */
export const SET_CONVERSATIONS = 'SET_CONVERSATIONS';

/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const SET_SETTINGS = 'SET_SETTINGS';

/* TEMPLATES */
export const LOAD_TEMPLATES = 'LOAD_TEMPLATES';
export const LOAD_TEMPLATES_SUCCESS = 'LOAD_TEMPLATES_SUCCESS';
export const LOAD_TEMPLATES_ERROR = 'LOAD_TEMPLATES_ERROR';
export const LOAD_TEMPLATE = 'LOAD_TEMPLATE';
export const LOAD_TEMPLATE_SUCCESS = 'LOAD_TEMPLATE_SUCCESS';
export const LOAD_TEMPLATE_VERSIONS_SUCCESS = 'LOAD_TEMPLATE_VERSIONS_SUCCESS';
export const LOAD_TEMPLATE_VERSIONS_SUCCESS_LOADING = 'LOAD_TEMPLATE_VERSIONS_SUCCESS_LOADING';
export const LOAD_TEMPLATE_ERROR = 'LOAD_TEMPLATE_ERROR';
export const ADD_TEMPLATE = 'ADD_TEMPLATE';
export const ADD_TEMPLATE_SUCCESS = 'ADD_TEMPLATE_SUCCESS';
export const ADD_TEMPLATE_ERROR = 'ADD_TEMPLATE_ERROR';
export const SAVE_TEMPLATE = 'SAVE_TEMPLATE';
export const SAVE_TEMPLATE_ERROR = 'SAVE_TEMPLATE_ERROR';
export const SAVE_TEMPLATE_SUCCESS = 'SAVE_TEMPLATE_SUCCESS';
export const SELECTED_TEMPLATES_CHANGE = 'SELECTED_TEMPLATES_CHANGE';
export const DELETE_SELECTED_TEMPLATES = 'DELETE_SELECTED_TEMPLATES';
export const DELETE_SELECTED_TEMPLATES_ERROR =
    'DELETE_SELECTED_TEMPLATES_ERROR';
export const DELETE_SELECTED_TEMPLATES_SUCCESS =
    'DELETE_SELECTED_TEMPLATES_SUCCESS';
export const CHANGE_SELECTED_TEMPLATES_STATE =
    'CHANGE_SELECTED_TEMPLATES_STATE';
export const CHANGE_SELECTED_TEMPLATES_STATE_SUCCESS =
    'CHANGE_SELECTED_TEMPLATES_STATE_SUCCESS';
export const CHANGE_SELECTED_TEMPLATES_STATE_ERROR =
    'CHANGE_SELECTED_TEMPLATES_STATE_ERROR';
export const EDIT_TEMPLATE = 'EDIT_TEMPLATES';
export const EDIT_TEMPLATE_SUCCESS = 'EDIT_TEMPLATE_SUCCESS';
export const EDIT_TEMPLATE_ERROR = 'EDIT_TEMPLATE_ERROR';
/* ASSESSMENT*/
export const LOAD_ASSESSMENT = 'LOAD_ASSESSMENT';
export const LOAD_ASSESSMENT_SUCCESS = 'LOAD_ASSESSMENT_SUCCESS';
export const LOAD_ASSESSMENT_ERROR = 'LOAD_ASSESSMENT_ERROR';
export const ADD_ASSESSMENT = 'ADD_ASSESSMENT';
export const ADD_ASSESSMENT_ERROR = 'ADD_ASSESSMENT_ERROR';
export const ADD_ASSESSMENT_SUCCESS = 'ADD_ASSESSMENT_SUCCESS';
export const EDIT_ASSESSMENT = ' EDIT_ASSESSMENT';
export const EDIT_ASSESSMENT_ERROR = 'EDIT_ASSESSMENT_ERROR';
export const EDIT_ASSESSMENT_SUCCESS = 'EDIT_ASSESSMENT_SUCCESS';
export const SELECTED_ASSESSMENT_CHANGE =
    ' SELECTED_ASSESSMENT_CHANGE';
export const LOAD_INVITATION_ERROR = 'LOAD_INVITATION_ERROR';
export const LOAD_INVITATION_SUCCESS = 'LOAD_INVITATION_SUCCESS';
export const LIST_ASSESSMENT_ERROR = 'LIST_ASSESSMENT_ERROR';
export const LIST_ASSESSMENT_SUCCESS = 'LIST_ASSESSMENT_SUCCESS';
export const LIST_INVITATION = 'LIST_INVITATION';
export const LIST_INVITATION_SUCCESS = 'LIST_INVITATION_SUCCESS';
export const LIST_INVITATION_ERROR = 'LIST_INVITATION_ERROR';
export const ASSESSMENT_IMPORT_ERROR = 'ASSESSMENT_IMPORT_ERROR';
export const SET_ASSESSMENTS_FOR_IMPORT = 'SET_ASSESSMENTS_FOR_IMPORT';
export const ADD_INVITATION_LIST = 'ADD_INVITATION_LIST';
export const ADD_INVITATION_PEOPLE_ERROR =
    'ADD_INVITATION_PEOPLE_ERROR';

export const LIST_ASSESSMENT = ' LIST_ASSESSMENT';
export const EDIT_INVITATION_SUCCESS = ' EDIT_INVITATION_SUCCESS';
export const ADD_INVITATION_SUCCESS = 'ADD_INVITATION_SUCCESS';
export const LIST_USER_COMPLETED_ASSESSMENT =
    'LIST_USER_COMPLETED_ASSESSMENT';
export const USER_COMPLETED_ASSESSMENT_SUCCESS =
    'USER_COMPLETED_ASSESSMENT_SUCCESS';
export const USER_COMPLETED_ASSESSMENT_ERROR =
    'USER_COMPLETED_ASSESSMENT_ERROR';
export const LIST_USER_PENDING_ASSESSMENT =
    'LIST_USER_PENDING_ASSESSMENT';
export const USER_PENDING_ASSESSMENT_SUCCESS =
    'USER_PENDING_ASSESSMENT_SUCCESS';
export const USER_PENDING_ASSESSMENT_ERROR =
    'USER_PENDING_ASSESSMENT_ERROR';
export const DETAILS_COMPLETED_ASSESSMENTS =
    'DETAILS_COMPLETED_ASSESSMENTS';
export const DETAILS_COMPLETED_SUCCESS = 'DETAILS_COMPLETED_SUCCESS';
export const DETAILS_COMPLETED_ERROR = 'DETAILS_COMPLETED_ERROR';
export const DELETE_SELECTED_ASSESSMENTS =
    'DELETE_SELECTED_ASSESSMENTS';
export const DELETE_SELECTED_ASSESSMENTS_ERROR =
    'DELETE_SELECTED_ASSESSMENTS_ERROR';
export const DELETE_SELECTED_ASSESSMENTS_SUCCESS =
    'DELETE_SELECTED_ASSESSMENTS_SUCCESS';

/* ASSET */
export const LOAD_ASSET = 'LOAD_ASSET';
export const LOAD_ASSET_ERROR = 'LOAD_ASSET_ERROR';
export const LOAD_ASSET_SUCCESS = 'LOAD_ASSET_SUCCESS';
export const ADD_ASSET = 'ADD_ASSET';
export const ADD_ASSET_SUCCESS = 'ADD_ASSET_SUCCESS';
export const ADD_ASSET_ERROR = 'ADD_ASSET_ERROR';
export const EDIT_ASSET = 'EDIT_ASSET';
export const EDIT_ASSET_SUCCESS = 'EDIT_ASSET_SUCCESS';
export const EDIT_ASSET_ERROR = 'EDIT_ASSET_ERROR';
export const SELECTED_ASSET_CHANGE = 'SELECTED_ASSET_CHANGE';
export const SET_ALL_REVIEWS_FILTERS = 'SET_ALL_REVIEWS_FILTERS';
export const SET_ALL_REVIEWS_FILTERS_MODEL = 'SET_ALL_REVIEWS_FILTERS_MODEL';
export const LIST_ASSET_ERROR = 'LIST_ASSET_ERROR';
export const LIST_ASSET_SUCCESS = 'LIST_ASSET_SUCCESS';
export const LIST_ASSET_OPTIONS_SUCCESS = 'LIST_ASSET_OPTIONS_SUCCESS';
export const LIST_ASSET = 'LIST_ASSET';
export const DETAILS_COMPLETED_ASSET = 'DETAILS_COMPLETED_ASSET';
export const DETAILS_COMPLETED_ASSET_SUCCESS =
    'DETAILS_COMPLETED_ASSET_SUCCESS';
export const DETAILS_COMPLETED_ASSET_ERROR =
    'DETAILS_COMPLETED_ASSET_ERROR';
export const DELETE_SELECTED_ASSET = 'DELETE_SELECTED_ASSET';
export const DELETE_SELECTED_ASSET_ERROR =
    'DELETE_SELECTED_ASSET_ERROR';
export const DELETE_SELECTED_ASSET_SUCCESS =
    'DELETE_SELECTED_ASSET_SUCCESS';
export const LIST_USER_COMPLETED_ASSET = 'LIST_USER_COMPLETED_ASSET';
export const USER_COMPLETED_ASSET_SUCCESS =
    'USER_COMPLETED_ASSET_SUCCESS';
export const USER_COMPLETED_ASSET_ERROR =
    'USER_COMPLETED_ASSET_ERROR';
export const LIST_USER_PENDING_ASSET = 'LIST_USER_PENDING_ASSET';
export const USER_PENDING_ASSET_SUCCESS =
    'USER_PENDING_ASSET_SUCCESS';
export const USER_PENDING_ASSET_ERROR = 'USER_PENDING_ASSET_ERROR';

/* USERS */
export const USERS_LOADING = 'USERS_LOADING';
export const USERS_LOADING_SUCCESS = 'USERS_LOADING_SUCCESS';
export const USERS_LOADING_ERROR = 'USERS_LOADING_ERROR';
export const INVITE = 'INVITE';
export const INVITE_SUCCESS = 'INVITE_SUCCESS';
export const INVITE_ERROR = 'INVITE_ERROR';
export const SUBMIT_ASSESSMENT = 'SUBMIT_ASSESSMENT';
export const SUBMIT_ASSESSMENT_SUCCESS = 'SUBMIT_ASSESSMENT_SUCCESS';
export const SUBMIT_ASSESSMENT_ERROR = 'SUBMIT_ASSESSMENT_ERROR';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

/* PUBLIC ASSESSMENTS */
export const VALIDATE_ASSESSMENT_LINK = 'VALIDATE_ASSESSMENT_LINK';
export const VALIDATE_ASSESSMENT_LINK_ERROR =
    'VALIDATE_ASSESSMENT_LINK_ERROR';
export const VALIDATE_ASSESSMENT_LINK_SUCCESS =
    'VALIDATE_ASSESSMENT_LINK_SUCCESS';
export const VALIDATE_ASSESSMENT_REPORT_LINK =
    'VALIDATE_ASSESSMENT_REPORT_LINK';
export const VALIDATE_ASSESSMENT_REPORT_LINK_ERROR =
    'VALIDATE_ASSESSMENT_REPORT_LINK_ERROR';
export const VALIDATE_ASSESSMENT_REPORT_LINK_SUCCESS =
    'VALIDATE_ASSESSMENT_REPORT_LINK_SUCCESS';

//FOR DOWNLOADING REPORTS
export const GENERAL_REPORT = 'GENERAL_REPORT';
export const GENERAL_REPORT_SUCCESS = 'GENERAL_REPORT_SUCCESS';
export const GENERAL_REPORT_ERROR = 'GENERAL_REPORT_ERROR';
export const EXECUTIVE_REPORT = 'EXECUTIVE_REPORT';
export const EXECUTIVE_REPORT_SUCCESS = 'EXECUTIVE_REPORT_SUCCESS';
export const EXECUTIVE_REPORT_ERROR = 'EXECUTIVE_REPORT_ERROR';

/* DASHBOARD */
export const LOAD_DASHBOARD_DATA = 'LOAD_DASHBOARD_DATA';
export const LOAD_DASHBOARD_DATA_ERROR = 'LOAD_DASHBOARD_DATA_ERROR';
export const LOAD_DASHBOARD_DATA_SUCCESS =
    'LOAD_DASHBOARD_DATA_SUCCESS';

export const INVITATIONS_LOADING = 'INVITATIONS_LOADING';
export const INVITATIONS_LOADING_SUCCESS =
    'INVITATIONS_LOADING_SUCCESS';
export const INVITATIONS_LOADING_ERROR = 'INVITATIONS_LOADING_ERROR';

export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';

export const LOAD_GENERAL_TEMPLATES = 'LOAD_GENERAL_TEMPLATES';
export const LOAD_GENERAL_TEMPLATES_ERROR =
    'LOAD_GENERAL_TEMPLATES_ERROR';
export const LOAD_GENERAL_TEMPLATES_SUCCESS =
    'LOAD_GENERAL_TEMPLATES_SUCCESS';
export const LOAD_EXECUTIVE_TEMPLATES = 'LOAD_EXECUTIVE_TEMPLATES';
export const LOAD_EXECUTIVE_TEMPLATES_ERROR =
    'LOAD_EXECUTIVE_TEMPLATES_ERROR';
export const LOAD_EXECUTIVE_TEMPLATES_SUCCESS =
    'LOAD_EXECUTIVE_TEMPLATES_SUCCESS';

export const DUPLICATE_TEMPLATE = 'DUPLICATE_TEMPLATE';
export const DUPLICATE_TEMPLATE_SUCCESS =
    'DUPLICATE_TEMPLATE_SUCCESS';
export const DUPLICATE_TEMPLATE_ERROR = 'DUPLICATE_TEMPLATE_ERROR';

export const LOAD_OLD_TEMPLATE_SECTIONS =
    'LOAD_OLD_TEMPLATE_SECTIONS';
export const LOAD_OLD_TEMPLATE_SECTION_SUCCESS =
    'LOAD_OLD_TEMPLATE_SECTION_SUCCESS';
export const LOAD_OLD_TEMPLATE_SECTION_ERROR =
    'LOAD_OLD_TEMPLATE_SECTION_ERROR';

export const RESET_INVITATION_VALUES = 'RESET_INVITATION_VALUES';
export const RESET_ERROR_VALUES = 'RESET_ERROR_VALUES';
export const RESET_TEMPLATE_ERROR_VALUES =
    'RESET_TEMPLATE_ERROR_VALUES';
export const RESET_TEMPLATE_SAVE_VALUES =
    'RESET_TEMPLATE_SAVE_VALUES';
export const RESET_USER_INVITATION_ERROR_VALUES =
    'RESET_USER_INVITATION_ERROR_VALUES';
export const RESET_USER_INVITATION_SUCCESS_VALUES =
    'RESET_USER_INVITATION_SUCCESS_VALUES';
export const RESET_ADD_TEMPLATE_VALUES = 'RESET_ADD_TEMPLATE_VALUES';
export const RESET_ADD_TEMPLATE_ERROR_VALUES =
    'RESET_ADD_TEMPLATE_ERROR_VALUES';
export const RESET_DELETE_TEMPLATE_ERROR_VALUES =
    'RESET_DELETE_TEMPLATE_ERROR_VALUES';
export const RESET_DELETE_TEMPLATE_SUCCESS_VALUES =
    'RESET_DELETE_TEMPLATE_SUCCESS_VALUES';
export const RESET_ASSESSMENT_NOT_PUBLISHED_VALUES =
    'RESET_ASSESSMENT_NOT_PUBLISHED_VALUES';
export const RESET_ASSESSMENT_PUBLISHED_SUCCESS_VALUES =
    'RESET_ASSESSMENT_PUBLISHED_SUCCESS_VALUES';
export const RESET_DUPLICATE_TEMPLATE_ERROR_VALUES =
    'RESET_DUPLICATE_TEMPLATE_ERROR_VALUES';
export const RESET_DUPLICATE_TEMPLATE_SUCCESS_VALUES =
    'RESET_DUPLICATE_TEMPLATE_SUCCESS_VALUES';

export const GET_ATTACHMENTS = 'GET_ATTACHMENTS';
export const GET_ATTACHMENTS_SUCCESS = 'GET_ATTACHMENTS_SUCCESS';
export const GET_ATTACHMENTS_ERROR = 'GET_ATTACHMENTS_ERROR';

export const LIST_USER_PENDING_REVIEW = 'LIST_USER_PENDING_REVIEW';
export const LIST_All_REVIEWS = 'LIST_All_REVIEWS';
export const ALL_REVIEW_SUCCESS =
    'ALL_REVIEW_SUCCESS';
export const ALL_REVIEW_ERROR =
    'ALL_REVIEW_ERROR';
export const USER_PENDING_REVIEW_SUCCESS =
    'USER_PENDING_REVIEW_SUCCESS';
export const USER_PENDING_REVIEW_ERROR = 'USER_PENDING_REVIEW_ERROR';

export const LIST_USER_COMPLETED_REVIEWS =
    'LIST_USER_COMPLETED_REVIEWS';
export const USER_COMPLETED_REVIEW_SUCCESS =
    'USER_COMPLETED_REVIEW_SUCCESS';
export const USER_COMPLETED_REVIEW_ERROR =
    'USER_COMPLETED_REVIEW_ERROR';

export const SUBMIT_REVIEWER_ASSESSMENT =
    'SUBMIT_REVIEWER_ASSESSMENT';
export const SUBMIT_REVIEWER_ASSESSMENT_SUCCESS =
    'SUBMIT_REVIEWER_ASSESSMENT_SUCCESS';
export const SUBMIT_REVIEWER_ASSESSMENT_ERROR =
    'SUBMIT_REVIEWER_ASSESSMENT_ERROR';

export const VALIDATE_REVIEWER_ASSESSMENT_LINK =
    'VALIDATE_REVIEWER_ASSESSMENT_LINK';
export const VALIDATE_REVIEWER_ASSESSMENT_LINK_SUCCESS =
    'VALIDATE_REVIEWER_ASSESSMENT_LINK_SUCCESS';
export const VALIDATE_REVIEWER_ASSESSMENT_LINK_ERROR =
    'VALIDATE_REVIEWER_ASSESSMENT_LINK_ERROR';
export const GET_CSV_RESPONSE = 'GET_CSV_RESPONSE';
export const GET_CSV_RESPONSE_SUCCESS = 'GET_CSV_RESPONSE_SUCCESS';
export const GET_CSV_RESPONSE_ERROR = 'GET_CSV_RESPONSE_ERROR';

export const LOAD_STANDARDS = 'LOAD_STANDARDS';
export const LOAD_STANDARDS_SUCCESS = 'LOAD_STANDARDS_SUCCESS';
export const LOAD_STANDARDS_ERROR = 'LOAD_STANDARDS_ERROR';

export const LOAD_CHILD_STANDARDS = 'LOAD_CHILD_STANDARDS';
export const LOAD_CHILD_STANDARDS_SUCCESS =
    'LOAD_CHILD_STANDARDS_SUCCESS';
export const LOAD_CHILD_STANDARDS_ERROR =
    'LOAD_CHILD_STANDARDS_ERROR';

export const LOAD_NEXT_CHILD_STANDARDS = 'LOAD_NEXT_CHILD_STANDARDS';
export const LOAD_NEXT_CHILD_STANDARDS_SUCCESS =
    'LOAD_NEXT_CHILD_STANDARDS_SUCCESS';
export const LOAD_NEXT_CHILD_STANDARDS_ERROR =
    'LOAD_NEXT_CHILD_STANDARDS_ERROR';

export const LOAD_STANDARDS_LIST = 'LOAD_STANDARDS_LIST';
export const LOAD_STANDARDS_LIST_SUCCESS =
    'LOAD_STANDARDS_LIST_SUCCESS';
export const LOAD_STANDARDS_LIST_ERROR = 'LOAD_STANDARDS_LIST_ERROR';

export const LOAD_STANDARD = 'LOAD_STANDARD';
export const LOAD_STANDARD_SUCCESS = 'LOAD_STANDARD_SUCCESS';
export const LOAD_STANDARD_ERROR = 'LOAD_STANDARD_ERROR';

export const LIST_PARENT_ASSET = 'LIST_PARENT_ASSET';
export const LIST_PARENT_ASSET_SUCCESS = 'LIST_PARENT_ASSET_SUCCESS';
export const LIST_PARENT_ASSET_ERROR = 'LIST_PARENT_ASSET_ERROR';

/* CONTROLS */
export const ADD_CONTROL = 'ADD_CONTROL';
export const ADD_CONTROL_SUCCESS = 'ADD_CONTROL_SUCCESS';
export const ADD_CONTROL_ERROR = 'ADD_CONTROL_ERROR';
export const LOAD_CONTROLS = 'LOAD_CONTROLS';
export const LOAD_CONTROLS_SUCCESS = 'LOAD_CONTROLS_SUCCESS';
export const LOAD_CONTROLS_ERROR = 'LOAD_CONTROLS_ERROR';
export const SELECTED_CONTROLS_CHANGE = 'SELECTED_CONTROLS_CHANGE';
export const DELETE_SELECTED_CONTROLS = 'DELETE_SELECTED_CONTROLS';
export const DELETE_SELECTED_CONTROLS_ERROR =
    'DELETE_SELECTED_CONTROLS_ERROR';
export const DELETE_SELECTED_CONTROLS_SUCCESS =
    'DELETE_SELECTED_CONTROLS_SUCCESS';
export const LOAD_CONTROL = 'LOAD_CONTROL';
export const LOAD_CONTROL_ERROR = 'LOAD_CONTROL_ERROR';
export const LOAD_CONTROL_SUCCESS = 'LOAD_CONTROL_SUCCESS';
export const UPDATE_CONTROL = 'UPDATE_CONTROL';
export const UPDATE_CONTROL_ERROR = 'UPDATE_CONTROL_ERROR';
export const UPDATE_CONTROL_SUCCESS = 'UPDATE_CONTROL_SUCCESS';
export const LOAD_CONTROL_OBJECTIVE = 'LOAD_CONTROL_OBJECTIVE';
export const LOAD_CONTROL_OBJECTIVE_SUCCESS =
    'LOAD_CONTROL_OBJECTIVE_SUCCESS';
export const LOAD_CONTROL_OBJECTIVE_ERROR =
    'LOAD_CONTROL_OBJECTIVE_ERROR';
export const SELECTED_CONTROL_OBJECTIVE_CHANGE =
    'SELECTED_CONTROL_OBJECTIVE_CHANGE';
export const ADD_CONTROL_OBJECTIVE = 'ADD_CONTROL_OBJECTIVE';
export const ADD_CONTROL_OBJECTIVE_ERROR =
    'ADD_CONTROL_OBJECTIVE_ERROR';
export const ADD_CONTROL_OBJECTIVE_SUCCESS =
    'ADD_CONTROL_OBJECTIVE_SUCCESS';
export const LOAD_SINGLE_CONTROL_OBJECTIVE =
    'LOAD_SINGLE_CONTROL_OBJECTIVE';
export const LOAD_SINGLE_CONTROL_OBJECTIVE_ERROR =
    'LOAD_SINGLE_CONTROL_OBJECTIVE_ERROR';
export const LOAD_SINGLE_CONTROL_OBJECTIVE_SUCCESS =
    'LOAD_SINGLE_CONTROL_OBJECTIVE_SUCCESS';
export const DELETE_SELECTED_CONTROL_OBJECTIVES =
    'DELETE_SELECTED_CONTROL_OBJECTIVES';
export const DELETE_SELECTED_CONTROL_OBJECTIVES_SUCCESS =
    'DELETE_SELECTED_CONTROL_OBJECTIVES_SUCCESS';
export const DELETE_SELECTED_CONTROL_OBJECTIVES_ERROR =
    'DELETE_SELECTED_CONTROL_OBJECTIVES_ERROR';
export const UPDATE_CONTROL_OBJECTIVE = 'UPDATE_CONTROL_OBJECTIVE';
export const UPDATE_CONTROL_OBJECTIVE_ERROR =
    'UPDATE_CONTROL_OBJECTIVE_ERROR';
export const UPDATE_CONTROL_OBJECTIVE_SUCCESS =
    'UPDATE_CONTROL_OBJECTIVE_SUCCESS';
export const ADD_CONTROL_APPROVE = 'ADD_CONTROL_APPROVE';
export const ADD_CONTROL_APPROVE_ERROR = 'ADD_CONTROL_APPROVE_ERROR';
export const ADD_CONTROL_APPROVE_SUCCESS =
    'ADD_CONTROL_APPROVE_SUCCESS';
export const RESET_APPROVE_MESSAGES = 'RESET_APPROVE_MESSAGES';
export const RESET_CONTROL_MESSAGES = 'RESET_CONTROL_MESSAGES';

export const ADD_REVIEWER = 'ADD_REVIEWER';
export const ADD_REVIEWER_SUCCESS = 'ADD_REVIEWER_SUCCESS';
export const ADD_REVIEWER_ERROR = 'ADD_REVIEWER_ERROR';
export const RESET_CONTROL_OBJ_MESSAGES =
    'RESET_CONTROL_OBJ_MESSAGES';
/* PUBLIC INVITATION */
export const ADD_PUBLIC_INVITE_LINK = 'ADD_PUBLIC_INVITE_LINK';
export const ADD_PUBLIC_INVITE_LINK_ERROR =
    'ADD_PUBLIC_INVITE_LINK_ERROR';
export const ADD_PUBLIC_INVITE_LINK_SUCCESS =
    'ADD_PUBLIC_INVITE_LINK_SUCCESS';

export const SUBMIT_PUBLIC_USER = 'SUBMIT_PUBLIC_USER';
export const SUBMIT_PUBLIC_USER_ERROR = 'SUBMIT_PUBLIC_USER_ERROR';
export const SUBMIT_PUBLIC_USER_SUCCESS =
    'SUBMIT_PUBLIC_USER_SUCCESS';

export const VALIDATE_PUBLIC_ASSESSMENT_LINK =
    'VALIDATE_PUBLIC_ASSESSMENT_LINK';
export const VALIDATE_PUBLIC_ASSESSMENT_LINK_ERROR =
    'VALIDATE_PUBLIC_ASSESSMENT_LINK_ERROR';
export const VALIDATE_PUBLIC_ASSESSMENT_LINK_SUCCESS =
    'VALIDATE_PUBLIC_ASSESSMENT_LINK_SUCCESS';
export const RESET_PUBLIC_ASSESSMENT_MESSAGES =
    'RESET_PUBLIC_ASSESSMENT_MESSAGES';
export const LOAD_SHAREABLE_ASSESSMENT = 'LOAD_SHAREABLE_ASSESSMENT';
export const LOAD_SHAREABLE_ASSESSMENT_SUCCESS =
    'LOAD_SHAREABLE_ASSESSMENT_SUCCESS';
export const LOAD_SHAREABLE_ASSESSMENT_ERROR =
    'LOAD_SHAREABLE_ASSESSMENT_ERROR';

export const VALIDATE_SET_PASSWORD_LINK =
    'VALIDATE_SET_PASSWORD_LINK';
export const VALIDATE_SET_PASSWORD_LINK_ERROR =
    'VALIDATE_SET_PASSWORD_LINK_ERROR';
export const VALIDATE_SET_PASSWORD_LINK_SUCCESS =
    'VALIDATE_SET_PASSWORD_LINK_SUCCESS';
export const RESET_SET_PASSWORD_MESSAGES =
    'RESET_SET_PASSWORD_MESSAGES';

/*RISKS*/
export const LOAD_RISKS = 'LOAD_RISKS';
export const LOAD_RISKS_ERROR = 'LOAD_RISKS_ERROR';
export const LOAD_RISKS_SUCCESS = 'LOAD_RISKS_SUCCESS';
export const ADD_RISK = 'ADD_RISK';
export const ADD_RISK_ERROR = 'ADD_RISK_ERROR';
export const ADD_RISK_SUCCESS = 'ADD_RISK_SUCCESS';
export const REST_RISK_MESSAGES = 'REST_RISK_MESSAGES';
export const LOAD_RISK = 'LOAD_RISK';
export const LOAD_RISK_ERROR = 'LOAD_RISK_ERROR';
export const LOAD_RISK_SUCCESS = 'LOAD_RISK_SUCCESS';
export const DELETE_RISK = 'DELETE_RISK';
export const DELETE_RISK_ERROR = 'DELETE_RISK_ERROR';
export const DELETE_RISK_SUCCESS = 'DELETE_RISK_SUCCESS';
export const UPDATE_RISK = 'UPDATE_RISK';
export const UPDATE_RISK_ERROR = 'UPDATE_RISK_ERROR';
export const UPDATE_RISK_SUCCESS = 'UPDATE_RISK_SUCCESS';

export const REASSIGN_USER = 'REASSIGN_USER';
export const REASSIGN_USER_ERROR = 'REASSIGN_USER_ERROR';
export const REASSIGN_USER_SUCCESS = 'REASSIGN_USER_SUCCESS';

export const GET_RESOURCE_HISTORY = 'GET_RESOURCE_HISTORY';
export const GET_RESOURCE_HISTORY_ERROR =
    'GET_RESOURCE_HISTORY_ERROR';
export const GET_RESOURCE_HISTORY_SUCCESS =
    'GET_RESOURCE_HISTORY_SUCCESS';

export const UPLOAD_EVIDENCE = 'UPLOAD_EVIDENCE';
export const UPLOAD_EVIDENCE_ERROR = 'UPLOAD_EVIDENCE_ERROR';
export const UPLOAD_EVIDENCE_SUCCESS = 'UPLOAD_EVIDENCE_SUCCESS';

/*  POLICIES */
export const RESET_POLICIES_STATE = 'RESET_POLICIES_STATE';
export const LIST_POLICIES = 'LIST_POLICIES';
export const LIST_POLICIES_ERROR = 'LIST_POLICIES_ERROR';
export const LIST_POLICIES_SUCCESS = 'LIST_POLICIES_SUCCESS';
export const ADD_POLICY = 'ADD_POLICY';
export const ADD_POLICY_ERROR = 'ADD_POLICY_ERROR';
export const ADD_POLICY_SUCCESS = 'ADD_POLICY_SUCCESS';
export const LOAD_POLICY = 'LOAD_POLICY';
export const LOAD_POLICY_ERROR = 'LOAD_POLICY_ERROR';
export const LOAD_POLICY_SUCCESS = 'LOAD_POLICY_SUCCESS';
export const DELETE_POLICY = 'DELETE_POLICY';
export const DELETE_POLICY_ERROR = 'DELETE_POLICY_ERROR';
export const DELETE_POLICY_SUCCESS = 'DELETE_POLICY_SUCCESS';
export const UPDATE_POLICY = 'UPDATE_POLICY';
export const UPDATE_POLICY_ERROR = 'UPDATE_POLICY_ERROR';
export const UPDATE_POLICY_SUCCESS = 'UPDATE_POLICY_SUCCESS';

export const SELECTED_POLICIES_CONTENT = 'SELECTED_POLICIES_CONTENT';

export const LIST_POLICIES_CONTENT = 'LIST_POLICIES_CONTENT';
export const LIST_POLICIES_CONTENT_ERROR =
    'LIST_POLICIES_CONTENT_ERROR';
export const LIST_POLICIES_CONTENT_SUCCESS =
    'LIST_POLICIES_CONTENT_SUCCESS';
export const ADD_POLICY_CONTENT = 'ADD_POLICY_CONTENT';
export const ADD_POLICY_CONTENT_ERROR = 'ADD_POLICY_CONTENT_ERROR';
export const ADD_POLICY_CONTENT_SUCCESS =
    'ADD_POLICY_CONTENT_SUCCESS';
export const LOAD_POLICY_CONTENT = 'LOAD_POLICY_CONTENT';
export const LOAD_POLICY_CONTENT_ERROR = 'LOAD_POLICY_CONTENT_ERROR';
export const LOAD_POLICY_CONTENT_SUCCESS =
    'LOAD_POLICY_CONTENT_SUCCESS';
export const DELETE_POLICY_CONTENT = 'DELETE_POLICY_CONTENT';
export const DELETE_POLICY_CONTENT_ERROR =
    'DELETE_POLICY_CONTENT_ERROR';
export const DELETE_POLICY_CONTENT_SUCCESS =
    'DELETE_POLICY_CONTENT_SUCCESS';
export const UPDATE_POLICY_CONTENT = 'UPDATE_POLICY_CONTENT';
export const UPDATE_POLICY_CONTENT_ERROR =
    'UPDATE_POLICY_CONTENT_ERROR';
export const UPDATE_POLICY_CONTENT_SUCCESS =
    'UPDATE_POLICY_CONTENT_SUCCESS';

/*THREATS*/
export const LIST_THREATS = 'LIST_THREATS';
export const LIST_THREATS_ERROR = 'LIST_THREATS_ERROR';
export const LIST_THREATS_SUCCESS = 'LIST_THREATS_SUCCESS';
export const ADD_THREAT = 'ADD_THREAT';
export const ADD_THREAT_ERROR = 'ADD_THREAT_ERROR';
export const ADD_THREAT_SUCCESS = 'ADD_THREAT_SUCCESS';
export const RESET_THREAT_MESSAGES = 'RESET_THREAT_MESSAGES';
export const LOAD_THREAT = 'LOAD_THREAT';
export const LOAD_THREAT_ERROR = 'LOAD_THREAT_ERROR';
export const LOAD_THREAT_SUCCESS = 'LOAD_THREAT_SUCCESS';
export const DELETE_THREAT = 'DELETE_THREAT';
export const DELETE_THREAT_ERROR = 'DELETE_THREAT_ERROR';
export const DELETE_THREAT_SUCCESS = 'DELETE_THREAT_SUCCESS';
export const UPDATE_THREAT = 'UPDATE_THREAT';
export const UPDATE_THREAT_ERROR = 'UPDATE_THREAT_ERROR';
export const UPDATE_THREAT_SUCCESS = 'UPDATE_THREAT_SUCCESS';

/*TEMPLATE*/
export const LIST_RISKS = 'LIST_RISKS';
export const LIST_RISKS_ERROR = 'LIST_RISKS_ERROR';
export const LIST_RISKS_SUCCESS = 'LIST_RISKS_SUCCESS';

export const LIST_THREATS_TEMP = 'LIST_THREATS_TEMP';
export const LIST_THREATS_ERROR_TEMP = 'LIST_THREATS_ERROR_TEMP';
export const LIST_THREATS_SUCCESS_TEMP = 'LIST_THREATS_SUCCESS_TEMP';

export const LOAD_RESOURCE_DETAILS = 'LOAD_RESOURCE_DETAILS';
export const LOAD_RESOURCE_DETAILS_ERROR =
    'LOAD_RESOURCE_DETAILS_ERROR';
export const LOAD_RESOURCE_DETAILS_SUCCESS =
    'LOAD_RESOURCE_DETAILS_SUCCESS';

export const FILTER_THREAT = 'FILTER_THREAT';
export const FILTER_THREAT_ERROR = 'FILTER_THREAT_ERROR';
export const FILTER_THREAT_SUCCESS = 'FILTER_THREAT_SUCCESS';

export const FILTER_RISK = 'FILTER_RISK';
export const FILTER_RISK_ERROR = 'FILTER_RISK_ERROR';
export const FILTER_RISK_SUCCESS = 'FILTER_RISK_SUCCESS';

/* GET LIST OF ASSESSMENTS WHICH ARE COMPARED */
export const COMPARE_ASSESSMENTS_LIST = 'COMPARE_ASSESSMENTS_LIST';
export const COMPARE_ASSESSMENTS_LIST_ERROR =
    'COMPARE_ASSESSMENTS_LIST_ERROR';
export const COMPARE_ASSESSMENTS_LIST_SUCCESS =
    'COMPARE_ASSESSMENTS_LIST_SUCCESS';

export const COMPARE_ASSESSMENTS = 'COMPARE_ASSESSMENTS';
export const COMPARE_ASSESSMENTS_ERROR = 'COMPARE_ASSESSMENTS_ERROR';
export const COMPARE_ASSESSMENTS_SUCCESS =
    'COMPARE_ASSESSMENTS_SUCCESS';

export const VALIDATE_COMPARISON_ASSESSMENT_LINK =
    'VALIDATE_COMPARISON_ASSESSMENT_LINK';
export const VALIDATE_COMPARISON_ASSESSMENT_LINK_ERROR =
    'VALIDATE_COMPARISON_ASSESSMENT_LINK';
export const VALIDATE_COMPARISON_ASSESSMENT_LINK_SUCCESS =
    'VALIDATE_COMPARISON_ASSESSMENT_LINK_SUCCESS';

/* DASHBOARD VALUES */
export const GET_DASHBOARD_POLICY_DATA = 'GET_DASHBOARD_POLICY_DATA';
export const GET_DASHBOARD_POLICY_DATA_ERROR =
    'GET_DASHBOARD_POLICY_DATA_ERROR';
export const GET_DASHBOARD_POLICY_DATA_SUCCESS =
    'GET_DASHBOARD_POLICY_DATA_SUCCESS';

export const GET_DASHBOARD_CONTROL_DATA =
    'GET_DASHBOARD_CONTROL_DATA';
export const GET_DASHBOARD_CONTROL_DATA_ERROR =
    'GET_DASHBOARD_CONTROL_DATA_ERROR';
export const GET_DASHBOARD_CONTROL_DATA_SUCCESS =
    'GET_DASHBOARD_CONTROL_DATA_SUCCESS';

export const COMPARISON_GENERAL_REPORT = 'COMPARISON_GENERAL_REPORT';
export const COMPARISON_GENERAL_REPORT_ERROR =
    'COMPARISON_GENERAL_REPORT_ERROR';
export const COMPARISON_GENERAL_REPORT_SUCCESS =
    'COMPARISON_GENERAL_REPORT_SUCCESS';
export const GET_DASHBOARD_RISK_DATA = 'GET_DASHBOARD_RISK_DATA';
export const GET_DASHBOARD_RISK_DATA_ERROR =
    'GET_DASHBOARD_RISK_DATA_ERROR';
export const GET_DASHBOARD_RISK_DATA_SUCCESS =
    'GET_DASHBOARD_RISK_DATA_SUCCESS';

export const GET_DASHBOARD_THREAT_DATA = 'GET_DASHBOARD_THREAT_DATA';
export const GET_DASHBOARD_THREAT_DATA_ERROR =
    'GET_DASHBOARD_THREAT_DATA_ERROR';
export const GET_DASHBOARD_THREAT_DATA_SUCCESS =
    'GET_DASHBOARD_THREAT_DATA_SUCCESS';

export const GET_RESOURCE_LIST_FOR_RISK_AND_THREATS =
    'GET_RESOURCE_LIST_FOR_RISK_AND_THREATS';
export const GET_RESOURCE_LIST_FOR_RISK_AND_THREATS_ERROR =
    'GET_RESOURCE_LIST_FOR_RISK_AND_THREATS_ERROR';
export const GET_RESOURCE_LIST_FOR_RISK_AND_THREATS_SUCCESS =
    'GET_RESOURCE_LIST_FOR_RISK_AND_THREATS_SUCCESS';

export const GET_RISK_HISTORY = 'GET_RISK_HISTORY';
export const GET_RISK_HISTORY_ERROR = 'GET_RISK_HISTORY_ERROR';
export const GET_RISK_HISTORY_SUCCESS = 'GET_RISK_HISTORY_SUCCESS';
export const GET_THREAT_HISTORY = 'GET_THREAT_HISTORY';
export const GET_THREAT_HISTORY_ERROR = 'GET_THREAT_HISTORY_ERROR';
export const GET_THREAT_HISTORY_SUCCESS =
    'GET_THREAT_HISTORY_SUCCESS';

export const GET_COMPARISON_REPORTS_LIST =
    'GET_COMPARISON_REPORTS_LIST';
export const GET_COMPARISON_REPORTS_LIST_ERROR =
    'GET_COMPARISON_REPORTS_LIST_ERROR';
export const GET_COMPARISON_REPORTS_LIST_SUCCESS =
    'GET_COMPARISON_REPORTS_LIST_SUCCESS';
export const SELECTED_USER_ASSESSMENTS_FOR_COMPARISON =
    'SELECTED_USER_ASSESSMENTS_FOR_COMPARISON';

export const VALIDATE_MULTIPLE_COMPARISON =
    'VALIDATE_MULTIPLE_COMPARISON';
export const VALIDATE_MULTIPLE_COMPARISON_ERROR =
    'VALIDATE_MULTIPLE_COMPARISON_ERROR';
export const VALIDATE_MULTIPLE_COMPARISON_SUCCESS =
    'VALIDATE_MULTIPLE_COMPARISON_SUCCESS';

export const DOWNLOAD_GENERAL_REPORT_FOR_MULTIPLE_RESOURCES =
    'DOWNLOAD_GENERAL_REPORT_FOR_MULTIPLE_RESOURCES';
export const DOWNLOAD_GENERAL_REPORT_FOR_MULTIPLE_RESOURCES_ERROR =
    'DOWNLOAD_GENERAL_REPORT_FOR_MULTIPLE_RESOURCES_ERROR';
export const DOWNLOAD_GENERAL_REPORT_FOR_MULTIPLE_RESOURCES_SUCCESS =
    'DOWNLOAD_GENERAL_REPORT_FOR_MULTIPLE_RESOURCES_SUCCESS';

export const VALIDATE_TIMELINE_REPORT_COMPARISON =
    'VALIDATE_TIMELINE_REPORT_COMPARISON';
export const VALIDATE_TIMELINE_REPORT_COMPARISON_ERROR =
    'VALIDATE_TIMELINE_REPORT_COMPARISON_ERROR';
export const VALIDATE_TIMELINE_REPORT_COMPARISON_SUCCESS =
    'VALIDATE_TIMELINE_REPORT_COMPARISON_SUCCESS';

export const DOWNLOAD_EXECUTIVE_REPORT_FOR_MULTIPLE_RESOURCES =
    'DOWNLOAD_EXECUTIVE_REPORT_FOR_MULTIPLE_RESOURCES';
export const DOWNLOAD_EXECUTIVE_REPORT_FOR_MULTIPLE_RESOURCES_ERROR =
    'DOWNLOAD_EXECUTIVE_REPORT_FOR_MULTIPLE_RESOURCES_ERROR';
export const DOWNLOAD_EXECUTIVE_REPORT_FOR_MULTIPLE_RESOURCES_SUCCESS =
    'DOWNLOAD_EXECUTIVE_REPORT_FOR_MULTIPLE_RESOURCES_SUCCESS';

export const LOAD_ALL_RESPONSES = 'LOAD_ALL_RESPONSES';
export const LOAD_ALL_RESPONSES_ERROR = 'LOAD_ALL_RESPONSES_ERROR';
export const LOAD_ALL_RESPONSES_SUCCESS =
    'LOAD_ALL_RESPONSES_SUCCESS';

export const LIST_LIBRARY_CONTROLS = 'LIST_LIBRARY_CONTROLS';
export const LIST_LIBRARY_CONTROLS_ERROR =
    'LIST_LIBRARY_CONTROLS_ERROR';
export const LIST_LIBRARY_CONTROLS_SUCCESS =
    'LIST_LIBRARY_CONTROLS_SUCCESS';

export const SELECTED_CONTROLS_LIST = 'SELECTED_CONTROLS_LIST';

export const ADD_CONTROLS_TO_RESOURCES = 'ADD_CONTROLS_TO_RESOURCES';
export const ADD_CONTROLS_TO_RESOURCES_ERROR =
    'ADD_CONTROLS_TO_RESOURCES_ERROR';
export const ADD_CONTROLS_TO_RESOURCES_SUCCESS =
    'ADD_CONTROLS_TO_RESOURCES_SUCCESS';

export const RESET_RESOURCES_STATES = 'RESET_RESOURCES_STATES';

export const DELETE_RESOURCE_CONTROL = 'DELETE_RESOURCE_CONTROL';
export const DELETE_RESOURCE_CONTROL_ERROR =
    'DELETE_RESOURCE_CONTROL_ERROR';
export const DELETE_RESOURCE_CONTROL_SUCCESS =
    'DELETE_RESOURCE_CONTROL_SUCCESS';

export const UPLOAD_EVIDENCES_FILES = 'UPLOAD_EVIDENCES_FILES';
export const UPLOAD_EVIDENCES_FILES_ERROR =
    'UPLOAD_EVIDENCES_FILES_ERROR';
export const UPLOAD_EVIDENCES_FILES_SUCCESS =
    'UPLOAD_EVIDENCES_FILES_SUCCESS';

export const GET_REVIEWS_LIST = 'GET_REVIEWS_LIST';
export const GET_REVIEWS_LIST_ERROR = 'GET_REVIEWS_LIST_ERROR';
export const GET_REVIEWS_LIST_SUCCESS = 'GET_REVIEWS_LIST_SUCCESS';

export const SUBMIT_REVIEW = 'SUBMIT_REVIEW';
export const SUBMIT_REVIEW_ERROR = 'SUBMIT_REVIEW_ERROR';
export const SUBMIT_REVIEW_SUCCESS = 'SUBMIT_REVIEW_SUCCESS';

export const SUBMIT_EDIT_REVIEW = 'SUBMIT_EDIT_REVIEW';
export const SUBMIT_EDIT_REVIEW_ERROR = 'SUBMIT_EDIT_REVIEW_ERROR';
export const SUBMIT_EDIT_REVIEW_SUCCESS =
    'SUBMIT_EDIT_REVIEW_SUCCESS';

export const MARK_REVIEW_AS_DONE = 'MARK_REVIEW_AS_DONE';
export const MARK_REVIEW_AS_DONE_ERROR = 'MARK_REVIEW_AS_DONE_ERROR';
export const MARK_REVIEW_AS_DONE_SUCCESS =
    'MARK_REVIEW_AS_DONE_SUCCESS';

export const ALL_EVIDENCE_ATTACHMENTS = 'ALL_EVIDENCE_ATTACHMENTS';
export const ALL_EVIDENCE_ATTACHMENTS_ERROR =
    'ALL_EVIDENCE_ATTACHMENTS_ERROR';
export const ALL_EVIDENCE_ATTACHMENTS_SUCCESS =
    'ALL_EVIDENCE_ATTACHMENTS_SUCCESS';

export const DOWNLOAD_ATTACHED_EVIDENCE =
    'DOWNLOAD_ATTACHED_EVIDENCE';
export const DOWNLOAD_ATTACHED_EVIDENCE_ERROR =
    'DOWNLOAD_ATTACHED_EVIDENCE_ERROR';
export const DOWNLOAD_ATTACHED_EVIDENCE_SUCCESS =
    'DOWNLOAD_ATTACHED_EVIDENCE_SUCCESS';

export const GET_HISTORY_LOGS = 'GET_HISTORY_LOGS';
export const GET_HISTORY_LOGS_ERROR = 'GET_HISTORY_LOGS_ERROR';
export const GET_HISTORY_LOGS_SUCCESS = 'GET_HISTORY_LOGS_SUCCESS';

export const REDIRECT_TO_URL_LOGIN_URL = 'REDIRECT_TO_URL_LOGIN_URL';
export const REDIRECT_TO_URL_LOGIN_URL_ERROR =
    'REDIRECT_TO_URL_LOGIN_URL_ERROR';
export const REDIRECT_TO_URL_LOGIN_URL_SUCCESS =
    'REDIRECT_TO_URL_LOGIN_URL_SUCCESS';

export const RESET_REDIRECTION_DATA = 'RESET_REDIRECTION_DATA';

export const GET_CALLBACK = 'GET_CALLBACK';
export const GET_CALLBACK_ERROR = 'GET_CALLBACK_ERROR';
export const GET_CALLBACK_SUCCESS = 'GET_CALLBACK_SUCCESS';
/*GET BASIC TEMPLATE DETAILS*/
export const GET_BASIC_TEMPLATE = 'GET_BASIC_TEMPLATE';
export const GET_BASIC_TEMPLATE_ERROR = 'GET_BASIC_TEMPLATE_ERROR';
export const GET_BASIC_TEMPLATE_SUCCESS =
    'GET_BASIC_TEMPLATE_SUCCESS';

export const EDIT_BASIC_TEMPLATE = 'EDIT_BASIC_TEMPLATE';
export const EDIT_BASIC_TEMPLATE_ERROR = 'EDIT_BASIC_TEMPLATE_ERROR';
export const EDIT_BASIC_TEMPLATE_SUCCESS =
    'EDIT_BASIC_TEMPLATE_SUCCESS';

export const LOAD_SECTION_DETAILS = 'LOAD_SECTION_DETAILS';
export const LOAD_SECTION_DETAILS_ERROR =
    'LOAD_SECTION_DETAILS_ERROR';
export const LOAD_SECTION_DETAILS_SUCCESS =
    'LOAD_SECTION_DETAILS_SUCCESS';

export const ADD_SINGLE_SECTION = 'ADD_SINGLE_SECTION';
export const ADD_SINGLE_SECTION_ERROR = 'ADD_SINGLE_SECTION_ERROR';
export const ADD_SINGLE_SECTION_SUCCESS =
    'ADD_SINGLE_SECTION_SUCCESS';

export const EDIT_SINGLE_SECTION = 'EDIT_SINGLE_SECTION';
export const EDIT_SINGLE_SECTION_ERROR = 'EDIT_SINGLE_SECTION_ERROR';
export const EDIT_SINGLE_SECTION_SUCCESS =
    'EDIT_SINGLE_SECTION_SUCCESS';

export const SAVE_NEW_TEMPLATE = 'SAVE_NEW_TEMPLATE';
export const SAVE_NEW_TEMPLATE_ERROR = 'SAVE_NEW_TEMPLATE_ERROR';
export const SAVE_NEW_TEMPLATE_SUCCESS = 'SAVE_NEW_TEMPLATE_SUCCESS';

export const GET_TEMP_DATA_FOR_REORDERING =
    'GET_TEMP_DATA_FOR_REORDERING';
export const GET_TEMP_DATA_FOR_REORDERING_ERROR =
    'GET_TEMP_DATA_FOR_REORDERING_ERROR';
export const GET_TEMP_DATA_FOR_REORDERING_SUCCESS =
    'GET_TEMP_DATA_FOR_REORDERING_SUCCESS';

export const REORDER_TEMPLATE_DATA = 'REORDER_TEMPLATE_DATA';
export const REORDER_TEMPLATE_DATA_ERROR =
    'REORDER_TEMPLATE_DATA_ERROR';
export const REORDER_TEMPLATE_DATA_SUCCESS =
    'REORDER_TEMPLATE_DATA_SUCCESS';

export const DELETE_SINGLE_SECTION = 'DELETE_SINGLE_SECTION';
export const DELETE_SINGLE_SECTION_ERROR =
    'DELETE_SINGLE_SECTION_ERROR';
export const DELETE_SINGLE_SECTION_SUCCESS =
    'DELETE_SINGLE_SECTION_SUCCESS';

export const RESET_REORDER_MESSAGES = 'RESET_REORDER_MESSAGES';
export const RESET_EDIT_AND_ADD_SECTION_MESSAGES =
    'RESET_EDIT_AND_ADD_SECTION_MESSAGES';

export const RESET_REVIEW_MESSAGES = 'RESET_REVIEW_MESSAGES';
export const GENERATE_CONTROL_REPORT = 'GENERATE_CONTROL_REPORT';
export const GENERATE_CONTROL_REPORT_ERROR =
    'GENERATE_CONTROL_REPORT_ERROR';
export const GENERATE_CONTROL_REPORT_SUCCESS =
    'GENERATE_CONTROL_REPORT_SUCCESS';

export const RESET_CONTROL_REPORT_MESSAGES =
    'RESET_CONTROL_REPORT_MESSAGES';

export const GET_MAIN_WEB_REPORT = 'GET_MAIN_WEB_REPORT';
export const GET_MAIN_WEB_REPORT_ERROR = 'GET_MAIN_WEB_REPORT_ERROR';
export const GET_MAIN_WEB_REPORT_SUCCESS =
    'GET_MAIN_WEB_REPORT_SUCCESS';

export const LIST_CONTROL_REPORT = 'LIST_CONTROL_REPORT';
export const LIST_CONTROL_REPORT_ERROR = 'LIST_CONTROL_REPORT_ERROR';
export const LIST_CONTROL_REPORT_SUCCESS =
    'LIST_CONTROL_REPORT_SUCCESS';

export const DELETE_CONTROL_REPORT = 'DELETE_CONTROL_REPORT';
export const DELETE_CONTROL_REPORT_ERROR =
    'DELETE_CONTROL_REPORT_ERROR';
export const DELETE_CONTROL_REPORT_SUCCESS =
    'DELETE_CONTROL_REPORT_SUCCESS';

export const RESET_DELETE_CONTROL_REPORT_MESSAGE =
    'RESET_DELETE_CONTROL_REPORT_MESSAGE';

export const DOWNLOAD_CONTROL_REPORT = 'DOWNLOAD_CONTROL_REPORT';
export const DOWNLOAD_CONTROL_REPORT_ERROR =
    'DOWNLOAD_CONTROL_REPORT_ERROR';
export const DOWNLOAD_CONTROL_REPORT_SUCCESS =
    'DOWNLOAD_CONTROL_REPORT_SUCCESS';

export const LOAD_CONTROL_REPORT = 'LOAD_CONTROL_REPORT';
export const LOAD_CONTROL_REPORT_ERROR = 'LOAD_CONTROL_REPORT_ERROR';
export const LOAD_CONTROL_REPORT_SUCCESS =
    'LOAD_CONTROL_REPORT_SUCCESS';

export const SET_GO_TO_BACK = 'SET_GO_TO_BACK';

export const GET_REVIEW_HISTORY = 'GET_REVIEW_HISTORY';
export const GET_REVIEW_HISTORY_ERROR = 'GET_REVIEW_HISTORY_ERROR';
export const GET_REVIEW_HISTORY_SUCCESS =
    'GET_REVIEW_HISTORY_SUCCESS';

export const LAST_ACTION_PERFORMED = 'LAST_ACTION_PERFORMED';

export const STORE_ASSET_ID = 'STORE_ASSET_ID';

export const GET_CONTROL_FRAMEWORK = 'GET_CONTROL_FRAMEWORK';
export const GET_CONTROL_FRAMEWORK_ERROR =
    'GET_CONTROL_FRAMEWORK_ERROR';
export const GET_CONTROL_FRAMEWORK_SUCCESS =
    'GET_CONTROL_FRAMEWORK_SUCCESS';

export const SELECTED_STD_CONTROLS_LIST =
    'SELECTED_STD_CONTROLS_LIST';

export const ADD_STANDARD_CONTROL_FOR_RESOURCE =
    'ADD_STANDARD_CONTROL_FOR_RESOURCE';
export const ADD_STANDARD_CONTROL_FOR_RESOURCE_ERROR =
    'ADD_STANDARD_CONTROL_FOR_RESOURCE_ERROR';
export const ADD_STANDARD_CONTROL_FOR_RESOURCE_SUCCESS =
    'ADD_STANDARD_CONTROL_FOR_RESOURCE_SUCCESS';

export const UPDATE_RES_CONTROL_SUCCESS =
    'UPDATE_RES_CONTROL_SUCCESS';

export const COPY_USER_INVITATION = 'COPY_USER_INVITATION';
export const COPY_USER_INVITATION_ERROR =
    'COPY_USER_INVITATION_ERROR';
export const COPY_USER_INVITATION_SUCCESS =
    'COPY_USER_INVITATION_SUCCESS';

export const DELETE_INVITE_RESPONSE = 'DELETE_INVITE_RESPONSE';
export const DELETE_INVITE_RESPONSE_ERROR =
    'DELETE_INVITE_RESPONSE_ERROR';
export const DELETE_INVITE_RESPONSE_SUCCESS =
    'DELETE_INVITE_RESPONSE_SUCCESS';

export const REASSIGN_RESOURCE = 'REASSIGN_RESOURCE';
export const REASSIGN_RESOURCE_ERROR = 'REASSIGN_RESOURCE_ERROR';
export const REASSIGN_RESOURCE_SUCCESS = 'REASSIGN_RESOURCE_SUCCESS';

export const LIST_RESOURCES = 'LIST_RESOURCES';
export const LIST_RESOURCES_ERROR = 'LIST_RESOURCES_ERROR';
export const LIST_RESOURCES_SUCCESS = 'LIST_RESOURCES_SUCCESS';

export const UPDATE_APPLICABLE_DATE = 'UPDATE_APPLICABLE_DATE';
export const UPDATE_APPLICABLE_DATE_ERROR =
    'UPDATE_APPLICABLE_DATE_ERROR';
export const UPDATE_APPLICABLE_DATE_SUCCESS =
    'UPDATE_APPLICABLE_DATE_SUCCESS';

export const LOAD_INVITED_USER_ASSESSMENT_SECTION =
    'LOAD_INVITED_USER_ASSESSMENT_SECTION';
export const LOAD_INVITED_USER_ASSESSMENT_SECTION_ERROR =
    'LOAD_INVITED_USER_ASSESSMENT_SECTION_ERROR';
export const LOAD_INVITED_USER_ASSESSMENT_SECTION_SUCCESS =
    'LOAD_INVITED_USER_ASSESSMENT_SECTION_SUCCESS';

export const UPDATE_SECTION_DATA = 'UPDATE_SECTION_DATA';
export const UPDATE_SECTION_DATA_ERROR = 'UPDATE_SECTION_DATA_ERROR';
export const UPDATE_SECTION_DATA_SUCCESS =
    'UPDATE_SECTION_DATA_SUCCESS';

export const GET_BASIC_TEMPLATE_ERROR_WHILE_ADDING =
    'GET_BASIC_TEMPLATE_ERROR_WHILE_ADDING';
export const GET_BASIC_TEMPLATE_SUCCESS_WHILE_ADDING =
    'GET_BASIC_TEMPLATE_SUCCESS_WHILE_ADDING';

export const DOWNLOAD_TEMPLATE_FILE = 'DOWNLOAD_TEMPLATE_FILE';
export const DOWNLOAD_TEMPLATE_FILE_ERROR =
    'DOWNLOAD_TEMPLATE_FILE_ERROR';
export const DOWNLOAD_TEMPLATE_FILE_SUCCESS =
    'DOWNLOAD_TEMPLATE_FILE_SUCCESS';

export const UPDATE_TEMP_DETAILS = 'UPDATE_TEMP_DETAILS';
export const UPDATE_TEMP_DETAILS_ERROR = 'UPDATE_TEMP_DETAILS_ERROR';
export const UPDATE_TEMP_DETAILS_SUCCESS =
    'UPDATE_TEMP_DETAILS_SUCCESS';
export const LOAD_SECTIONS_OF_ASSESSMENT =
    'LOAD_SECTIONS_OF_ASSESSMENT';
export const LOAD_SECTIONS_OF_ASSESSMENT_ERROR =
    'LOAD_SECTIONS_OF_ASSESSMENT_ERROR';
export const LOAD_SECTIONS_OF_ASSESSMENT_SUCCESS =
    'LOAD_SECTIONS_OF_ASSESSMENT_SUCCESS';
export const ADD_INVITATION_PEOPLE_SEC_ERROR =
    'ADD_INVITATION_PEOPLE_SEC_ERROR';

export const UPDATE_REPORT_CONTENTS = 'UPDATE_REPORT_CONTENTS';
export const UPDATE_REPORT_CONTENTS_ERROR =
    'UPDATE_REPORT_CONTENTS_ERROR';
export const UPDATE_REPORT_CONTENTS_SUCCESS =
    'UPDATE_REPORT_CONTENTS_SUCCESS';

export const SINGLE_PAGE_GENERAL_REPORT =
    'SINGLE_PAGE_GENERAL_REPORT';
export const SINGLE_PAGE_GENERAL_REPORT_ERROR =
    'SINGLE_PAGE_GENERAL_REPORT_ERROR';
export const SINGLE_PAGE_GENERAL_REPORT_SUCCESS =
    'SINGLE_PAGE_GENERAL_REPORT_SUCCESS';

export const SWITCH_REPORTS = 'SWITCH_REPORTS';
export const SWITCH_REPORTS_ERROR = 'SWITCH_REPORTS_ERROR';
export const SWITCH_REPORTS_SUCCESS = 'SWITCH_REPORTS_SUCCESS';

export const UPDATE_REPORT_CONTENT_FROM_WEB =
    'UPDATE_REPORT_CONTENT_FROM_WEB';
export const UPDATE_REPORT_CONTENT_FROM_WEB_ERROR =
    'UPDATE_REPORT_CONTENT_FROM_WEB_ERROR';
export const UPDATE_REPORT_CONTENT_FROM_WEB_SUCCESS =
    'UPDATE_REPORT_CONTENT_FROM_WEB_SUCCESS';

export const UPLOADED_DATA = 'UPLOADED_DATA';
export const SET_UPLOAD_FILE = 'SET_UPLOAD_FILE';
export const RETRY_UPLOAD_FILE = 'RETRY_UPLOAD_FILE';
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
export const SUCCESS_UPLOAD_FILE = 'SUCCESS_UPLOAD_FILE';
export const FAILURE_UPLOAD_FILE = 'FAILURE_UPLOAD_FILE';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';

export const VIEW_ALL_NOTIFICATIONS = 'VIEW_ALL_NOTIFICATIONS';
export const VIEW_ALL_NOTIFICATIONS_ERROR =
    'VIEW_ALL_NOTIFICATIONS_ERROR';
export const VIEW_ALL_NOTIFICATIONS_SUCCESS =
    'VIEW_ALL_NOTIFICATIONS_SUCCESS';

export const MARK_AS_READ_NOTIFICATION = 'MARK_AS_READ_NOTIFICATION';
export const MARK_AS_READ_NOTIFICATION_ERROR =
    'MARK_AS_READ_NOTIFICATION_ERROR';
export const MARK_AS_READ_NOTIFICATION_SUCCESS =
    'MARK_AS_READ_NOTIFICATION_SUCCESS';

export const IMPORT_SECTION_FROM_OTHER_TEMPLATE =
    'IMPORT_SECTION_FROM_OTHER_TEMPLATE';
export const IMPORT_SECTION_FROM_OTHER_TEMPLATE_ERROR =
    'IMPORT_SECTION_FROM_OTHER_TEMPLATE_ERROR';
export const IMPORT_SECTION_FROM_OTHER_TEMPLATE_SUCCESS =
    'IMPORT_SECTION_FROM_OTHER_TEMPLATE_SUCCESS';

export const UPLOADEDFILES_SUCCESS = 'UPLOADEDFILES_SUCCESS';
export const UPLOADEDFILES_PROGRESS = 'UPLOADEDFILES_PROGRESS';
export const SUBMIT_REVIEW_CLEAR = 'SUBMIT_REVIEW_CLEAR';

export const RESETON_SUCCESS = 'RESETON_SUCCESS';

export const UPDATE_CUR_USER = 'UPDATE_CUR_USER';
export const RESET_USER_DATA = 'RESET_USER_DATA';
export const REASSIGN_USER_NEW = 'REASSIGN_USER_NEW';

export const DELETEFROM_UPLOADED = 'DELETEFROM_UPLOADED';
export const DELETEFROM_UPRESPONSE = 'DELETEFROM_UPRESPONSE';
export const GETRESPONSEFROM_UPLOAD = 'GETRESPONSEFROM_UPLOAD';

export const ASSESSMENT_CONFIGURATION = 'ASSESSMENT_CONFIGURATION';
export const ASSESSMENT_CONFIGURATION_ERROR =
    'ASSESSMENT_CONFIGURATION_ERROR';
export const ASSESSMENT_CONFIGURATION_SUCCESS =
    'ASSESSMENT_CONFIGURATION_SUCCESS';

export const ASSESSMENT_TOGGLE_LOCK = 'ASSESSMENT_TOGGLE_LOCK';
export const ASSESSMENT_TOGGLE_LOCK_ERROR =
  'ASSESSMENT_TOGGLE_LOCK_ERROR';
export const ASSESSMENT_TOGGLE_LOCK_SUCCESS =
  'ASSESSMENT_TOGGLE_LOCK_SUCCESS';

/*ACTIVITIES*/
export const LOAD_ACTIVITIES = 'LOAD_ACTIVITIES';
export const LOAD_ACTIVITIES_ERROR = 'LOAD_ACTIVITIES_ERROR';
export const LOAD_ACTIVITIES_SUCCESS = 'LOAD_ACTIVITIES_SUCCESS';
export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const ADD_ACTIVITY_ERROR = 'ADD_ACTIVITY_ERROR';
export const ADD_ACTIVITY_SUCCESS = 'ADD_ACTIVITY_SUCCESS';
export const LOAD_ACTIVITY = 'LOAD_ACTIVITY';
export const LOAD_ACTIVITY_ERROR = 'LOAD_ACTIVITY_ERROR';
export const LOAD_ACTIVITY_SUCCESS = 'LOAD_ACTIVITY_SUCCESS';
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const DELETE_ACTIVITY_ERROR = 'DELETE_ACTIVITY_ERROR';
export const DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const UPDATE_ACTIVITY_ERROR = 'UPDATE_ACTIVITY_ERROR';
export const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS';
export const RESET_ACTIVITY_MESSAGES = 'RESET_ACTIVITY_MESSAGES';
export const SELECTED_ACTIVITIES_CHANGE = 'SELECTED_ACTIVITIES_CHANGE';
export const DELETE_SELECTED_ACTIVITIES = 'DELETE_SELECTED_ACTIVITIES';
export const NOTIFY_ACTIVITY = 'NOTIFY_ACTIVITY';
export const NOTIFY_ACTIVITY_SUCCESS = 'NOTIFY_ACTIVITY_SUCCESS';
export const NOTIFY_ACTIVITY_ERROR = 'NOTIFY_ACTIVITY_ERROR';
export const FILTER_ACTIVITIES = 'FILTER_ACTIVITIES';
export const FILTER_ACTIVITIES_SUCCESS = 'FILTER_ACTIVITIES_SUCCESS';
export const FILTER_ACTIVITIES_ERROR = 'FILTER_ACTIVITIES_ERROR';
export const RESPOND_ACTIVITY = 'RESPOND_ACTIVITY';
export const RESPOND_ACTIVITY_SUCCESS = 'RESPOND_ACTIVITY_SUCCESS';
export const RESPOND_ACTIVITY_ERROR = 'RESPOND_ACTIVITY_ERROR';
export const REVIEW_ACTIVITY = 'REVIEW_ACTIVITY';
export const REVIEW_ACTIVITY_SUCCESS = 'REVIEW_ACTIVITY_SUCCESS';
export const REVIEW_ACTIVITY_ERROR = 'REVIEW_ACTIVITY_ERROR';
export const LOAD_ACTIVITIES_FILTERS = 'LOAD_ACTIVITIES_FILTERS';
export const LOAD_ACTIVITIES_FILTERS_ERROR = 'LOAD_ACTIVITIES_FILTERS_ERROR';
export const LOAD_ACTIVITIES_FILTERS_SUCCESS = 'LOAD_ACTIVITIES_FILTERS_SUCCESS';

