import React from 'react';
import NotificationManager from './NotificationManager';
import Notifications from './Notifications';

interface Props {
  enterTimeout: number;
  leaveTimeout: number;
}

// const defaultProps: Props = {
//   enterTimeout: 400,
//   leaveTimeout: 400,
// };

interface State {
  notifications: Array<any>;
}

const initialState: State = {
  notifications: [],
};
class NotificationContainer extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    NotificationManager.addChangeListener(this.handleStoreChange);
    this.state = initialState;
  }

  componentWillUnmount = () => {
    NotificationManager.removeChangeListener(this.handleStoreChange);
  };

  handleStoreChange = (notifications) => {
    this.setState({
      notifications,
    });
  };

  handleRequestHide = (notification) => {
    NotificationManager.remove(notification);
  };

  render() {
    const { notifications } = this.state;
    const { enterTimeout, leaveTimeout } = this.props;
    return (
      <Notifications
        enterTimeout={enterTimeout}
        leaveTimeout={leaveTimeout}
        notifications={notifications}
        onRequestHide={this.handleRequestHide}
      />
    );
  }
}

export default NotificationContainer;
