import {
    LOAD_RISKS,
    LOAD_RISKS_ERROR,
    LOAD_RISKS_SUCCESS,
    ADD_RISK,
    ADD_RISK_ERROR,
    ADD_RISK_SUCCESS,
    REST_RISK_MESSAGES,
    LOAD_RISK,
    LOAD_RISK_ERROR,
    LOAD_RISK_SUCCESS,
    DELETE_RISK,
    DELETE_RISK_ERROR,
    DELETE_RISK_SUCCESS,
    UPDATE_RISK,
    UPDATE_RISK_ERROR,
    UPDATE_RISK_SUCCESS,
    FILTER_RISK,
    FILTER_RISK_ERROR,
    FILTER_RISK_SUCCESS,
    GET_RESOURCE_LIST_FOR_RISK_AND_THREATS,
    GET_RESOURCE_LIST_FOR_RISK_AND_THREATS_ERROR,
    GET_RESOURCE_LIST_FOR_RISK_AND_THREATS_SUCCESS,
} from '../types';

const INIT_STATE = {
    error: '',
    addError: '',
    riskData: {},
    risksList: [],
    adding: false,
    success: false,
    loading: false,
    deleteRiskError: '',
    deletedRiskSuccess: '',
    updateRiskError: '',
    updateRiskSuccess: false,
    resourcesRiskList: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_RISKS:
            return {
                ...state,
                risksList: [],
                error: '',
                loading: true,
            };
        case LOAD_RISKS_ERROR:
            return {
                ...state,
                risksList: [],
                loading: false,
                error: action.payload.message,
            };
        case LOAD_RISKS_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                risksList: action.payload,
            };
        case ADD_RISK:
            return {
                ...state,
                adding: true,
                addError: {},
                success: false,
            };
        case ADD_RISK_ERROR:
            return {
                ...state,
                adding: false,
                success: false,
                addError: action.payload.message,
            };
        case ADD_RISK_SUCCESS:
            return {
                ...state,
                adding: false,
                success: action.payload,
                addError: '',
                risksList: [
                    action.payload,
                    ...state.risksList,
                ],
            };
        case LOAD_RISK:
            return {
                ...state,
                error: '',
                loading: true,
                riskData: {},
            };
        case LOAD_RISK_ERROR:
            return {
                ...state,
                loading: false,
                riskData: {},
                error: action.payload,
            };
        case LOAD_RISK_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                riskData: action.payload,
            };
        case DELETE_RISK:
            return {
                ...state,
                loading: true,
                deleteRiskError: '',
                deletedRiskSuccess: '',
            };
        case DELETE_RISK_ERROR:
            return {
                ...state,
                loading: false,
                deletedRiskSuccess: '',
                deleteRiskError: action.payload,
            };
        case DELETE_RISK_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteRiskError: '',
                deletedRiskSuccess: action.payload,
            };
        case UPDATE_RISK:
            return {
                ...state,
                loading: true,
                updateRiskError: '',
                updateRiskSuccess: false,
            };
        case UPDATE_RISK_ERROR:
            return {
                ...state,
                loading: false,
                updateRiskSuccess: false,
                updateRiskError: action.payload.message,
            };
        case UPDATE_RISK_SUCCESS:
            return {
                ...state,
                loading: false,
                updateRiskError: '',
                updateRiskSuccess: action.payload,
                risksList: [
                    action.payload,
                    ...state.risksList,
                ],
            };
        case REST_RISK_MESSAGES:
            return {
                ...state,
                addError: '',
                adding: false,
                success: false,
                loading: false,
                updateRiskError: '',
                deleteRiskError: '',
                updateRiskSuccess: false,
                deletedRiskSuccess: '',
            };
        case FILTER_RISK:
            return {
                ...state,
                error: '',
                loading: true,
                risksList: [],
            };
        case FILTER_RISK_ERROR:
            return {
                ...state,
                risksList: [],
                loading: false,
                error: action.payload,
            };
        case FILTER_RISK_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                risksList: action.payload,
            };
        case GET_RESOURCE_LIST_FOR_RISK_AND_THREATS:
            return {
                ...state,
                error: '',
                loading: true,
                resourcesRiskList: [],
            };
        case GET_RESOURCE_LIST_FOR_RISK_AND_THREATS_ERROR:
            return {
                ...state,
                loading: false,
                resourcesRiskList: [],
                error: action.payload.message,
            };
        case GET_RESOURCE_LIST_FOR_RISK_AND_THREATS_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                resourcesRiskList: action.payload,
            };
        default:
            return state;
    }
};
