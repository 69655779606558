import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import configureStore from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import LazyRetry from './helpers/suspenseRetry';
// import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';

const getApp: any = () => LazyRetry(() => import(/* webpackChunkName: "App" */ './App'));

const App = React.lazy(getApp);

const Store = configureStore();

const loading = () => <div className="loading" />

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={Store.store}>
    <PersistGate loading={loading()} persistor={Store.persistor}>
      <Suspense fallback={loading()}>
        <App />
      </Suspense>
    </PersistGate>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
