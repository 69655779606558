import {
    USERS_LOADING,
    USERS_LOADING_SUCCESS,
    USERS_LOADING_ERROR,
    INVITE,
    INVITE_SUCCESS,
    INVITE_ERROR,
    UPDATE_PROFILE,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,
    INVITATIONS_LOADING,
    INVITATIONS_LOADING_SUCCESS,
    INVITATIONS_LOADING_ERROR,
    RESET_USER_INVITATION_ERROR_VALUES,
    RESET_USER_INVITATION_SUCCESS_VALUES,
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_ERROR,
    GET_NOTIFICATIONS_SUCCESS,
    VIEW_ALL_NOTIFICATIONS,
    VIEW_ALL_NOTIFICATIONS_ERROR,
    VIEW_ALL_NOTIFICATIONS_SUCCESS,
    MARK_AS_READ_NOTIFICATION,
    MARK_AS_READ_NOTIFICATION_ERROR,
    MARK_AS_READ_NOTIFICATION_SUCCESS,
    UPDATE_CUR_USER,
    RESET_USER_DATA,
} from '../types';

const INIT_STATE = {
    list: [],
    loading: false,
    error: '',
    savingProfile: false,
    savingProfileError: '',
    savingProfileSuccess: false,
    invitations: [],
    loadingInvitations: false,
    invitationError: '',
    updatingProfile: false,
    updateSuccess: false,
    deletedUser: '',
    notificationError: '',
    notificationSuccess: [],
    allNotificationError: '',
    allNotificationsSuccess: [],
    notificationStatus: '',
    curUser: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case USERS_LOADING:
            return { ...state, loading: true, error: '' };
        case USERS_LOADING_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.payload,
                error: '',
            };
        case USERS_LOADING_ERROR:
            return {
                ...state,
                loading: false,
                list: [],
                error: action.payload.message,
            };
        case INVITE:
            return { ...state, inviting: true, invitationError: '' };
        case INVITE_SUCCESS:
            return {
                ...state,
                inviting: false,
                invitationError: '',
                invitationSuccess: action.payload,
            };
        case INVITE_ERROR:
            return {
                ...state,
                inviting: false,
                invitationError: action.payload,
                invitationSuccess: '',
            };
        case UPDATE_PROFILE:
            return {
                ...state,
                savingProfile: true,
                savingProfileError: '',
                savingProfileSuccess: false,
            };
        case UPDATE_PROFILE_ERROR:
            return {
                ...state,
                savingProfile: false,
                savingProfileError: action.payload,
            };
        case UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                savingProfile: false,
                savingProfileSuccess: true,
            };
        case UPDATE_USER:
            return {
                ...state,
                updatingProfile: true,
                updateSuccess: false,
            };
        case UPDATE_USER_ERROR:
            return {
                ...state,
                updatingProfile: false,
                updateSuccess: false,
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                updatingProfile: false,
                updateSuccess: true,
                list: [...action.payload],
            };
        case INVITATIONS_LOADING:
            return {
                ...state,
                loadingInvitations: true,
                invitationError: '',
                invitations: [],
            };
        case INVITATIONS_LOADING_SUCCESS:
            return {
                ...state,
                loadingInvitations: false,
                invitations: action.payload,
            };
        case INVITATIONS_LOADING_ERROR:
            return {
                ...state,
                loadingInvitations: false,
                invitationError: action.payload,
            };
        case DELETE_USER:
            return {
                ...state,
                deleting: true,
                deleteSuccess: false,
            };
        case DELETE_USER_ERROR:
            return {
                ...state,
                deleting: false,
                deleteSuccess: false,
            };
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                deleting: false,
                deleteSuccess: true,
                list: [...action.payload.users],
                deletedUser: action.payload.currentUser,
            };
        case RESET_USER_INVITATION_ERROR_VALUES:
            return {
                ...state,
                invitationError: '',
            };
        case RESET_USER_INVITATION_SUCCESS_VALUES:
            return {
                ...state,
                invitationSuccess: '',
            };
        case GET_NOTIFICATIONS:
            return {
                ...state,
                loading: true,
                notificationError: '',
                notificationSuccess: [],
            };
        case GET_NOTIFICATIONS_ERROR:
            return {
                ...state,
                loading: false,
                notificationSuccess: [],
                notificationError: action.payload,
            };
        case GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                notificationError: '',
                notificationSuccess: action.payload,
            };
        case VIEW_ALL_NOTIFICATIONS:
            return {
                ...state,
                loading: true,
                allNotificationError: '',
                allNotificationsSuccess: [],
            };
        case VIEW_ALL_NOTIFICATIONS_ERROR:
            return {
                ...state,
                loading: false,
                allNotificationsSuccess: [],
                allNotificationError: action.payload,
            };
        case VIEW_ALL_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                allNotificationError: '',
                allNotificationsSuccess: action.payload,
            };
        case MARK_AS_READ_NOTIFICATION:
            return {
                ...state,
                notificationStatus: '',
            };
        case MARK_AS_READ_NOTIFICATION_ERROR:
            return {
                ...state,
                notificationStatus: action.payload,
            };
        case MARK_AS_READ_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notificationStatus: action.payload,
            };
        case UPDATE_CUR_USER:
            return {
                ...state,
                curUser: action.payload,
            };
        case RESET_USER_DATA:
            return {
                ...state,
                curUser: {},
            };
        default:
            return state;
    }
};
