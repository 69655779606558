import { SET_IS_READY } from '../types';

const INIT_STATE = {
  isReady: false,
};

export default (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case SET_IS_READY:
      return { ...state, isReady: action.payload };

    default:
      return state;
  }
};
