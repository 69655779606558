import {
    LOAD_STANDARDS,
    LOAD_STANDARDS_SUCCESS,
    LOAD_STANDARDS_ERROR,
    LOAD_CHILD_STANDARDS,
    LOAD_CHILD_STANDARDS_SUCCESS,
    LOAD_CHILD_STANDARDS_ERROR,
} from '../types';

const INIT_STATE = {
    list: [],
    loading: false,
    error: '',
    nextChildList: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        //LOADS LIST OF STANDARDS AVAILABLE IN OUR SYSTEM
        case LOAD_STANDARDS:
            return {
                ...state,
                loading: true,
                error: '',
                list: [],
                listChild: [],
            };
        case LOAD_STANDARDS_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.payload,
                error: '',
            };
        case LOAD_STANDARDS_ERROR:
            return {
                ...state,
                loading: false,
                list: [],
                error: action.payload.message,
            };
        //LOADS LIST OF CHILD STANDARDS OF GIVEN PARENT STANDARD ID
        case LOAD_CHILD_STANDARDS:
            return {
                ...state,
                // loading: true,
                error: '',
                listChild: [],
            };
        case LOAD_CHILD_STANDARDS_SUCCESS:
            return {
                ...state,
                loading: false,
                listChild: action.payload,
                error: '',
            };
        case LOAD_CHILD_STANDARDS_ERROR:
            return {
                ...state,
                loading: false,
                listChild: [],
                error: action.payload.message,
            };
        default:
            return state;
    }
};
